import {useParams} from 'react-router-dom';
import {useGetFullCustomPageById} from '../../../api/customPage/CustomPage';
import CreateCustomPageEditor from '../../../components/admin/adminCustomPage/adminCustomPageEditor/CreateCustomPageEditor';
import LoadingEditorBlock from '../../../components/editor/elements/common/LoadingBlock';

const EditCustomPage: React.FC = () => {
  const {customPageId} = useParams<{customPageId: string}>();
  const fullBlock = useGetFullCustomPageById(customPageId || '');

  if (!fullBlock.isSuccess || !customPageId) return <LoadingEditorBlock />;
  return (
    <CreateCustomPageEditor
      customPage={fullBlock.data}
      customPageId={customPageId}
    />
  );
};

export default EditCustomPage;
