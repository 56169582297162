import React from 'react';

export interface StandartHeaderProps {
  className?: string;
  children?: React.ReactNode;
}

const StandartHeader: React.FC<StandartHeaderProps> = ({
  children,
  className,
}) => {
  return (
    <h4 className={`text-2xl font-medium mb-2 ${className}`}>{children}</h4>
  );
};

export default StandartHeader;
