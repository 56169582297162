import React, {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaMinusCircle,
} from 'react-icons/fa';
import Input from '../../../../form/input/Input';
import {EditorElementProps} from '../../../types/EditorBlock';
import {useUpdateEditorBlock} from '../../../types/UseEditor';
import LoadingEditorBlock from '../../common/LoadingBlock';
import {DataMultiAnswers, EditorMultiAnswersData} from './MultiAnswersTypes';
import {v4 as uuidv4} from 'uuid';
import FormButton from '../../../../form/button/FormButton';
import HiddenSettings from '../../../common/HiddenSettings';
import BasicAdjustmentsEditor from '../../../common/BasicAdjustmentsEditor';

const MultiAnswersEditorBlock: React.FC<
  EditorElementProps<EditorMultiAnswersData>
> = (originBlock) => {
  const setBlockMutation = useUpdateEditorBlock<EditorMultiAnswersData>();

  const {
    register,
    setValue,
    watch,
    getValues,
    formState: {errors},
  } = useForm<EditorMultiAnswersData>({
    defaultValues: {...originBlock.data},
  });

  useEffect(() => {
    const subscription = watch((updatedData) => {
      setBlockMutation.mutate({
        ...originBlock,
        data: {
          ...originBlock.data,
          ...(updatedData as EditorMultiAnswersData),
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [originBlock, setBlockMutation, watch]);

  // ф-ия вызывается когда мы добавляем ответ
  const addNewAnswer = useCallback(
    (isCorrect: boolean) => {
      setValue('answers', [
        ...originBlock.data.answers,
        {answer: 'Answer', id: uuidv4(), correct: isCorrect},
      ]);
    },
    [originBlock.data.answers, setValue],
  );

  // ф-ия вызывается когда мы удаляем ответ
  const deleteAnswer = useCallback(
    (answer: DataMultiAnswers) => {
      if (originBlock.data.answers.length < 2) return;
      const newData = [...originBlock.data.answers];
      newData.splice(
        newData.findIndex((data) => {
          return data.id === answer.id;
        }),
        1,
      );

      setValue('answers', newData);
    },
    [setValue, originBlock.data.answers],
  );

  // меняем флаг верно неверно у ответа
  const changeCorrect = useCallback(
    (answer: DataMultiAnswers, correct: boolean) => {
      const newData = [...originBlock.data.answers];
      newData[
        newData.findIndex((data) => {
          return data.id === answer.id;
        })
      ].correct = correct;

      setValue('answers', newData);
    },
    [setValue, originBlock.data.answers],
  );

  if (!originBlock) return <LoadingEditorBlock />;
  return (
    <div className="text-2xl p-2 sm:p-5">
      <HiddenSettings>
        <div className="grid gap-2">
          <BasicAdjustmentsEditor
            getValues={getValues}
            setValue={setValue}
            registration={{register, errors}}
          />
          <FormButton
            onClick={() => {
              setBlockMutation.mutate({
                ...originBlock,
              });
            }}
          >
            Refresh live view
          </FormButton>
        </div>
      </HiddenSettings>
      <div className="flex">
        <div className="flex-1">
          <div className="pl-4 mb-4 list-decimal text-base grid gap-2">
            {originBlock.data.answers
              .filter((answer) => answer.correct)
              .map((answer, index) => {
                return (
                  <div key={index} className="flex items-center gap-x-2">
                    <div>
                      <FaMinusCircle
                        className="w-5 h-5 p-0.5 cursor-pointer bg-red-400 text-white hover:bg-red-600 rounded-full"
                        onClick={() => deleteAnswer(answer)}
                      />
                    </div>
                    <Input
                      name={`answers.${originBlock.data.answers.findIndex(
                        (q) => q.id === answer.id,
                      )}.answer`}
                      label={`${index + 1}. Correct`}
                      className="w-full"
                      registration={{register, errors}}
                      type="text"
                      options={{
                        required: 'Required',
                      }}
                    />
                    <div>
                      <FaArrowAltCircleRight
                        className="w-5 h-5 p-0.5 cursor-pointer bg-blue-400 text-white hover:bg-blue-600 rounded-full"
                        onClick={() => changeCorrect(answer, false)}
                      />
                    </div>
                  </div>
                );
              })}
            <FormButton onClick={() => addNewAnswer(true)}>
              Add Correct answer
            </FormButton>
          </div>
        </div>
        <div className="flex-1">
          <div className="pl-4 mb-4 list-decimal text-base grid gap-2">
            {originBlock.data.answers
              .filter((answer) => !answer.correct)
              .map((answer, index) => {
                return (
                  <div key={index} className="flex items-center gap-x-2">
                    <div>
                      <FaArrowAltCircleLeft
                        className="w-5 h-5 p-0.5 cursor-pointer bg-blue-400 text-white hover:bg-blue-600 rounded-full"
                        onClick={() => changeCorrect(answer, true)}
                      />
                    </div>
                    <Input
                      name={`answers.${originBlock.data.answers.findIndex(
                        (q) => q.id === answer.id,
                      )}.answer`}
                      label="Wrong"
                      className="w-full"
                      registration={{register, errors}}
                      type="text"
                      options={{
                        required: 'Required',
                      }}
                    />
                    <div>
                      <FaMinusCircle
                        className="w-5 h-5 p-0.5 cursor-pointer bg-red-400 text-white hover:bg-red-600 rounded-full"
                        onClick={() => deleteAnswer(answer)}
                      />
                    </div>
                  </div>
                );
              })}
            <FormButton onClick={() => addNewAnswer(false)}>
              Add Wrong answer
            </FormButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiAnswersEditorBlock;
