import React from 'react';
import {ViewerElementProps} from '../../types/EditorBlock';
import BasicAdjustmentsContainer from '../common/BasicAdjustmentsContainer';
import {EditorGameData} from './GameTypes';

const GameViewerBlock: React.FC<ViewerElementProps<EditorGameData>> = (
  originBlock,
) => (
  <BasicAdjustmentsContainer
    data={originBlock.data}
    className="flex justify-center"
    disableAutoBlockControl
  >
    <div className="aspect-video relative w-full lg:w-4/6">
      <iframe
        src={originBlock.data.url}
        title="Video"
        className="w-full h-full absolute"
      />
    </div>
  </BasicAdjustmentsContainer>
);

export default GameViewerBlock;
