/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import React, {useEffect, useState} from 'react';
import {RadioGroup} from '@headlessui/react';
import classNames from 'classnames';
import {
  FieldValues,
  FieldPath,
  UseFormSetValue,
  FieldPathValue,
  UseFormGetValues,
} from 'react-hook-form';
import RadioButtonElement from './RadioButton';

export type RadioButtonArrayOption<T> = {
  label: string;
  guid: string;
  value: T;
};

type RadioButtonArrayParameters<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  options: RadioButtonArrayOption<FieldPathValue<TFieldValues, TFieldName>>[];
  name: TFieldName;
  label?: string;
  setValue: UseFormSetValue<TFieldValues>;
  getValues: UseFormGetValues<TFieldValues>;
  onChange?: (value: FieldPathValue<TFieldValues, TFieldName>) => void;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const RadioButtonArray: <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(
  p: RadioButtonArrayParameters<T, TName>,
) => React.ReactElement<RadioButtonArrayParameters<T, TName>> = ({
  options,
  name,
  label,
  setValue,
  getValues,
  onChange,
  className,
  disabled,
  ...rest
}) => {
  const [selected, setSelected] = useState(options[0]);

  useEffect(() => {
    const value = getValues(name);
    const option = options.find((x) => x.value === value) || options[0];

    setSelected(option);
  }, [getValues, name, options]);

  return (
    <div
      className={classNames('relative input-component', className)}
      {...rest}
    >
      <RadioGroup
        value={selected}
        onChange={(value: RadioButtonArrayOption<any>) => {
          setSelected(value);
          setValue(name, value.value);
          if (onChange) onChange(value.value);
        }}
        className="text-grey-200"
        disabled={disabled}
      >
        <div className="space-y-1">
          {options.map((option) => (
            <RadioButtonElement
              option={option}
              key={option.guid}
              disabled={disabled}
            />
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};

export default RadioButtonArray;
