import React from 'react';
import {EditorBlock, EditorBlockData} from '../../types/EditorBlock';
import ViewerBlockWrapper from '../viewerBlockWrappers/ViewerBlockWrapper';

interface ContentEditorViewerProps {
  blocks: EditorBlock<EditorBlockData>[];
  isUseLocalVersion?: boolean;
}
const ViewerAutoblock: React.FC<ContentEditorViewerProps> = ({
  blocks,
  isUseLocalVersion = false,
}) => {
  return (
    <>
      {blocks.map((element, index) => (
        <ViewerBlockWrapper
          key={index}
          originBlock={element}
          isUseLocalVersion={isUseLocalVersion}
        />
      ))}
    </>
  );
};

export default ViewerAutoblock;
