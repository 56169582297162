import classNames from 'classnames';
import {BasicElement} from '../../helpers/BasicElement';

const NarrowContent: React.FC<
  BasicElement & React.HTMLAttributes<HTMLDivElement>
> = ({children, className, ...rest}) => (
  <div
    className={classNames('max-w-screen-xl mx-auto px-4 py-2', className)}
    {...rest}
  >
    {children}
  </div>
);

export default NarrowContent;
