import {Routes, Route, BrowserRouter} from 'react-router-dom';
import FormSample from '../../pages/dev/FormSample';
import LiveEditorViewer from '../../pages/home/LiveEditorViewer';
import TestEditor from '../../pages/home/TestEditor';
import TestViewer from '../../pages/home/TestViewer';
import BasicLayout from '../layouts/basicLayout/BasicLayout';
import CourseAdminPreview from '../../pages/admin/adminCourse/CourseAdminPreview';
import AdminCoursesPage from '../../pages/admin/adminCourses/AdminCoursesPage';
import AdminMainPage from '../../pages/admin/adminMainPage/AdminMainPage';
import CreateCourse from '../../pages/admin/createCourse/CreateCourse';
import AuthTest from '../../pages/authTest/AuthTest';
import CoursePage from '../../pages/coursePage/CoursePage';
import DnaAppPolicy from '../../pages/dnaAppPolicyPage/DnaAppPolicyPage';
import DolcevitaPage from '../../pages/dolcevitaPage/DolcevitaPage';
import HomePageCOM from '../../pages/homePage/HomePageEN';
import HomePageRU from '../../pages/homePage/HomePageRU';
import LoginPage from '../../pages/login/LoginPage';
import PaymentinfoPage from '../../pages/paymentinfoPage/PaymentinfoPage';
import PlantCellAppPolicy from '../../pages/plantCellPage/PlantCellPage';
import PolicyPage from '../../pages/policyPage/PolicyPage';
import PrivacyPolicyPage from '../../pages/privacyPolicyPage/PrivacyPolicyPage';
import WebsiteTermsPage from '../../pages/websiteTermsPage/WebsiteTermsPage';
import MainPageLayout from '../layouts/mainPageLayout/MainPageLayout';
import EditCourse from '../../pages/admin/createCourse/EditCourse';
import CoursesEditLivePreview from '../../pages/admin/adminCourse/CoursesEditLivePreview';
import ContentLayout from '../layouts/contentLayout/ContentLayout';
import CoursesPage from '../../pages/coursesPage/CoursesPage';
import FlagPage from '../../components/flagPage/flagPage';
import LocaleRoute from '../routerMiddlewares/LocaleRoute';
import PrivateRoute from '../routerMiddlewares/PrivateRoute';
import CustomPage from '../../pages/customPage/CustomPage';
import AdminCustomPages from '../../pages/admin/adminCustomPages/AdminCustomPages';
import CustomPageAdminPreview from '../../pages/admin/adminCustomPage/CustomPageAdminPreview';
import CreateCustomPage from '../../pages/admin/adminCreateCustomPage/CreateCustomPage';
import EditCustomPage from '../../pages/admin/adminCreateCustomPage/EditCustomPage';

const AppRouter: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/">
        <Route element={<MainPageLayout />}>
          <Route
            index
            element={<LocaleRoute ru={<HomePageRU />} en={<HomePageCOM />} />}
          />
        </Route>
      </Route>

      <Route path="/">
        <Route element={<BasicLayout />}>
          <Route path="/region" element={<FlagPage />} />
          <Route path="/simulations" element={<CoursesPage />} />
          <Route path="/test" element={<FormSample />} />
          <Route path="/editor" element={<TestEditor />} />
          <Route path="/viewer" element={<TestViewer />} />
          <Route path="/live/:blockId" element={<LiveEditorViewer />} />
          <Route path="/live" element={<LiveEditorViewer />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/website_terms" element={<WebsiteTermsPage />} />
          <Route path="/paymentinfo" element={<PaymentinfoPage />} />
        </Route>
        <Route element={<ContentLayout />}>
          <Route path="/simulations/:courseId" element={<CoursePage />} />
        </Route>
      </Route>

      <Route path="/p">
        <Route element={<BasicLayout />}>
          <Route path="/p/:page" element={<CustomPage />} />
          <Route path="/p/:subsection/:page" element={<CustomPage />} />
          <Route
            path="/p/:section/:subsection/:page"
            element={<CustomPage />}
          />
        </Route>
      </Route>

      <Route path="/policies" element={<BasicLayout />}>
        <Route
          path="/policies/privacy_policy"
          element={<PrivacyPolicyPage />}
        />
        <Route path="/policies/policy" element={<PolicyPage />} />

        <Route path="/policies/ios/dolcevita" element={<DolcevitaPage />} />
        <Route path="/policies/oculus/xreadydna" element={<DnaAppPolicy />} />
        <Route
          path="/policies/oculus/xreadyplantcell"
          element={<PlantCellAppPolicy />}
        />
      </Route>

      <Route path="/admin" element={<PrivateRoute />}>
        <Route path="/admin" element={<ContentLayout />}>
          <Route
            path="/admin/simulations/live/:courseId"
            element={<CoursesEditLivePreview />}
          />
          <Route
            path="/admin/simulations/:courseId"
            element={<CourseAdminPreview />}
          />
        </Route>
        <Route path="/admin" element={<BasicLayout />}>
          <Route path="/admin/simulations/" element={<AdminCoursesPage />} />
          <Route path="/admin/test" element={<AuthTest />} />
          <Route
            path="/admin/simulations/add-simulation"
            element={<CreateCourse />}
          />
          <Route
            path="/admin/simulations/edit/:courseId"
            element={<EditCourse />}
          />
          <Route index element={<AdminMainPage />} />
        </Route>

        <Route path="/admin" element={<BasicLayout />}>
          <Route
            path="/admin/custom_pages/:customPageId"
            element={<CustomPageAdminPreview />}
          />
        </Route>
        <Route path="/admin" element={<BasicLayout />}>
          <Route path="/admin/custom_pages/" element={<AdminCustomPages />} />
          <Route
            path="/admin/custom_pages/add"
            element={<CreateCustomPage />}
          />
          <Route
            path="/admin/custom_pages/edit/:customPageId"
            element={<EditCustomPage />}
          />
          <Route index element={<AdminMainPage />} />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
);
export default AppRouter;
