import {useMutation, useQuery, UseQueryResult} from '@tanstack/react-query';
import {queryClient} from '../../../app/App';
import {UseShortQueryOptions} from '../../../helpers/ReactQuesyOptionBuilder';
import {BlockMargin, EditorBlock, EditorBlockData} from './EditorBlock';
import {broadcastQueryClient} from '@tanstack/query-broadcast-client-experimental';

const editorBlockKey = 'editor/block';

export function SubscribeToPersistStorage() {
  broadcastQueryClient({
    queryClient,
    broadcastChannel: 'my-app',
  });
}

export function useEditorBlock<T extends EditorBlockData>(
  blockId: string,
  options?: UseShortQueryOptions<EditorBlock<T>, Error>,
): UseQueryResult<EditorBlock<T>, Error> {
  return useQuery(
    [editorBlockKey, {blockId}],
    (): EditorBlock<T> => {
      const data = queryClient.getQueryData<EditorBlock<T>>([
        editorBlockKey,
        {blockId},
      ]);

      if (data) return data;
      throw new Error(`Block ${blockId} is not found`);
    },
    {
      //staleTime: Infinity,
      // onError: GlobalNotificationErrorHandler,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export const useUpdateEditorBlock = <T extends EditorBlockData>() => {
  return useMutation<void, Error, EditorBlock<T>>(
    ['setter'],
    async (data) => {
      queryClient.setQueryData([editorBlockKey, {blockId: data.id}], data);
    },
    {
      // onError: GlobalEditorErrorNotificationHandler,
    },
  );
};

export const useUpdateEditorBlockPosition = () => {
  return useMutation<void, Error, {id: string; order: number}>(
    ['setter/body'],
    async ({id, order}) => {
      const element = queryClient.getQueryData<EditorBlock<any>>([
        editorBlockKey,
        {blockId: id},
      ]);

      if (!element) throw new Error('Element is not fond');
      queryClient.setQueryData([editorBlockKey, {blockId: id}], {
        ...element,
        order,
      });
    },
    {
      // onError: GlobalEditorErrorNotificationHandler,
    },
  );
};

export const useUpdateEditorBlockMargin = () => {
  return useMutation<void, Error, {id: string; margin: BlockMargin}>(
    ['setter/margin'],
    async ({id, margin}) => {
      const element = queryClient.getQueryData<EditorBlock<any>>([
        editorBlockKey,
        {blockId: id},
      ]);

      if (!element) throw new Error('Element is not fond');
      queryClient.setQueryData([editorBlockKey, {blockId: id}], {
        ...element,
        margin,
      });
    },
    {
      // onError: GlobalEditorErrorNotificationHandler,
    },
  );
};

export const useDeleteEditorBlock = () => {
  return useMutation<EditorBlock<EditorBlockData>, Error>({
    onSuccess: (data) => {
      queryClient.removeQueries([editorBlockKey, {blockId: data.id}]);
    },
    // onError: GlobalEditorErrorNotificationHandler,
  });
};
