import classNames from 'classnames';
import React, {useCallback, useEffect, useState} from 'react';
import {
  NotificationType,
  UniversalNotificationHandler,
} from '../../../../../services/globalNotification/universalNotificationHandler';
import {allENAlphabet} from '../../../../../services/utils/alphabet';
import Button from '../../../../form/button/Button';
import {ViewerElementProps} from '../../../types/EditorBlock';
import LoadingEditorBlock from '../../common/LoadingBlock';
import {EditorMatchingAnswerData} from './MatchingAnswerTypes';
import BasicAdjustmentsContainer from '../../common/BasicAdjustmentsContainer';

const MatchingAnswerViewer: React.FC<
  ViewerElementProps<EditorMatchingAnswerData>
> = (originBlock) => {
  // выбранные ответы
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);

  // дан ли ответ
  const [isAnswerDone, setIsAnswerDone] = useState(false);

  useEffect(() => {
    setSelectedAnswers([
      ...originBlock.data.correctAnswersId.map(() => 'NotAnswer'),
    ]);
  }, [originBlock.data.correctAnswersId]);

  useEffect(() => {
    setIsAnswerDone(false);
  }, [originBlock.data]);

  // проверка соответствуют ли выбранные ответы правильным
  const isCorrectAnswers = useCallback(() => {
    return (
      selectedAnswers.toString() ===
      originBlock.data.correctAnswersId.toString()
    );
  }, [selectedAnswers, originBlock.data.correctAnswersId]);

  // ф-ия срабатывает при отправке ответа
  const onSendAnswer = useCallback(() => {
    if (selectedAnswers.filter((answer) => answer === 'NotAnswer').length > 0) {
      UniversalNotificationHandler(
        'Canceled',
        'Some options are missing',
        NotificationType.danger,
      );
    } else {
      setIsAnswerDone(true);
      UniversalNotificationHandler(
        isCorrectAnswers() ? 'Correct' : 'Wrong',
        `Correct answer: ${originBlock.data.correctAnswersId.map(
          (correctAnswerId, index) =>
            `${allENAlphabet[index]}-${
              originBlock.data.columns[1].answers.filter((answer) => {
                return answer.id === correctAnswerId;
              })[0].answer
            } `,
        )}`,
        isCorrectAnswers() ? NotificationType.success : NotificationType.danger,
      );
    }
  }, [
    isCorrectAnswers,
    originBlock.data.columns,
    originBlock.data.correctAnswersId,
    selectedAnswers,
  ]);

  const changeSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
      const newData = [...selectedAnswers];
      newData[index] = e.target.value;
      setSelectedAnswers(newData);
    },
    [selectedAnswers],
  );

  if (!originBlock) return <LoadingEditorBlock />;
  return (
    <BasicAdjustmentsContainer data={originBlock.data} disableAutoBlockControl>
      <div className="text-2xl p-2 sm:p-5 flex justify-center max-w-7xl mx-auto">
        {originBlock.data.columns.map((column, indexColumn) => {
          return (
            <div key={indexColumn} className="p-2">
              <h2>{column.title}</h2>
              {originBlock.data.columns[0].answers.map((answer, index) => {
                return (
                  <div
                    key={answer.id}
                    className="border border-solid border-blue-400 p-2 text-lg mb-1 hover:bg-blue-50 flex justify-between items-center h-14"
                  >
                    <div>
                      {allENAlphabet[index]}){' '}
                      <span className="py-1 px-2">
                        {indexColumn === 0 ? (
                          answer.answer
                        ) : (
                          <select
                            name="select"
                            id="select"
                            disabled={isAnswerDone}
                            className={classNames(
                              'py-1 bg-transparent px-2 text-center border hover:bg-blue-100',
                              selectedAnswers[index] === 'NotAnswer' &&
                                'bg-red-50',
                              isAnswerDone &&
                                selectedAnswers[index] ===
                                  originBlock.data.correctAnswersId[index] &&
                                'bg-green-50',
                              isAnswerDone &&
                                selectedAnswers[index] !==
                                  originBlock.data.correctAnswersId[index] &&
                                'bg-red-50',
                            )}
                            onChange={(e) => changeSelect(e, index)}
                            value={selectedAnswers[index]}
                          >
                            {[
                              {value: 'NotAnswer', label: 'Choose your answer'},
                              ...originBlock.data.columns[1].answers.map(
                                (listanswer) => {
                                  return {
                                    label: listanswer.answer,
                                    value: listanswer.id,
                                  };
                                },
                              ),
                            ].map((list) => (
                              <option value={list.value} key={list.value}>
                                {list.label}
                              </option>
                            ))}
                          </select>
                        )}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="text-center">
        {!isAnswerDone ? (
          <Button
            onClick={onSendAnswer}
            disabled={
              isAnswerDone ||
              selectedAnswers.filter((answer) => answer === 'NotAnswer')
                .length > 0
            }
            className="mx-auto"
          >
            Answer
          </Button>
        ) : isCorrectAnswers() ? (
          <Button className="bg-green-600" type="submit">
            Correct
          </Button>
        ) : (
          <Button className="bg-red-600" type="submit">
            Wrong
          </Button>
        )}
      </div>
    </BasicAdjustmentsContainer>
  );
};

export default MatchingAnswerViewer;
