/* eslint-disable react/button-has-type */
import classNames from 'classnames';
import React from 'react';
import './SimpleButton.scoped.css';

type SimpleButtonProps = {
  disableBorders?: boolean;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const SimpleButton: React.FC<SimpleButtonProps> = ({
  className,
  type = 'button',
  children,
  disableBorders = false,
  disabled = false,
  ...rest
}) => {
  return (
    <button
      type={type}
      className={classNames(
        'px-4 py-2 text-xs font-semibold tracking-wider focus:outline-none focus:ring-1 focus:ring-gray-300 hover:bg-ffocusbackground text-gray-600',
        !disableBorders ? 'border border-gray-300 rounded' : '',
        className,
      )}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};
export default SimpleButton;
