import React, {useCallback} from 'react';
import {EditorBlocks} from '../../AllBlocks';
import LoadingEditorBlock from '../../elements/common/LoadingBlock';
import {EditorGridViewerData} from '../../elements/grid/GridType';
import {
  BlockContainer,
  BlockMargin,
  EditorBlock,
  EditorBlockData,
} from '../../types/EditorBlock';
import {
  useEditorBlock,
  useUpdateEditorBlockMargin,
} from '../../types/UseEditor';
import ContentEditorContainer from './ContentEditorContainer';
import PresentationEditorContainer from './PresentationEditorContainer';

interface NavigationAutoblockProps {
  originBlock: EditorBlock<EditorBlockData>;

  index: number;
  currentOrder: number;
  maxIndex: number;
  onPositionChanged: (oldPosition: number, newPosition: number) => void;
  onDeleteAtIndex: (index: number) => void;
  isMasterBlock?: boolean;
}

const NavigationAutoblock: React.FC<NavigationAutoblockProps> = ({
  originBlock,
  index,
  currentOrder,
  maxIndex,
  onPositionChanged,
  onDeleteAtIndex,
  isMasterBlock = false,
}) => {
  const {data: block} = useEditorBlock<EditorGridViewerData>(originBlock.id);
  const setBlockMargin = useUpdateEditorBlockMargin();

  const OnMarginChanged = useCallback(
    (margin: BlockMargin) => {
      setBlockMargin.mutate({
        id: originBlock.id,
        margin,
      });
    },
    [originBlock, setBlockMargin],
  );

  if (!block) return <LoadingEditorBlock />;

  switch (block.container) {
    case BlockContainer.Presentation:
      return (
        <PresentationEditorContainer
          elementIndex={index}
          currentOrder={currentOrder}
          maxIndex={maxIndex}
          onPositionChanged={onPositionChanged}
          onDeleteAtIndex={onDeleteAtIndex}
        >
          <div>{EditorBlocks[block.type].editor(block)}</div>
        </PresentationEditorContainer>
      );

    default:
      return (
        <ContentEditorContainer
          elementIndex={index}
          currentOrder={currentOrder}
          margin={block.margin}
          maxIndex={maxIndex}
          onPositionChanged={onPositionChanged}
          onDeleteAtIndex={onDeleteAtIndex}
          onMarginChanged={OnMarginChanged}
          isMasterBlock={isMasterBlock}
        >
          <div
            style={{
              marginTop: block?.margin?.top || '0px',
              marginBottom: block?.margin?.bottom || '0px',
              marginLeft: block?.margin?.left || '0px',
              marginRight: block?.margin?.right || '0px',
            }}
          >
            <div>{EditorBlocks[block.type].editor(block)}</div>
          </div>
        </ContentEditorContainer>
      );
  }
};

export default NavigationAutoblock;
