import React, {useEffect, useState} from 'react';
import {
  DeepMap,
  DeepPartial,
  FieldError,
  FieldPath,
  FieldValues,
  RegisterOptions,
  UseFormGetValues,
  UseFormRegister,
} from 'react-hook-form';
import PublicImagesPopup from '../../editor/elements/image/imagePopup/ImagesGridPopup';
import FormButton from '../button/FormButton';
import {PublicImageDTO} from '../../../api/media/dto/PublicImageDTO';
import classNames from 'classnames';

type InputParameters<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  label?: string;

  onChange: (name: TFieldName, value: PublicImageDTO[]) => void;
  getValues: UseFormGetValues<TFieldValues>;

  maxFiles?: number;
  maxSize?: number;

  name: TFieldName;
  registration: {
    register: UseFormRegister<TFieldValues>;
    errors: DeepMap<DeepPartial<FieldValues>, FieldError>;
  };
  options?: RegisterOptions<TFieldValues, TFieldName>;
} & Omit<React.HTMLAttributes<HTMLElement>, 'onChange'>;

const ImageSelectorInput: <T extends FieldValues = FieldValues>(
  p: InputParameters<T>,
) => React.ReactElement<InputParameters<T>> = ({
  label,
  maxFiles = 16,
  maxSize,
  getValues,
  onChange,
  name,
  registration,
  options,
  ...rest
}) => {
  const [anyImagesSelected, setAnyImagesSelected] = useState<boolean>();
  const [defaultImages, setDefaultImages] = useState<
    PublicImageDTO[] | undefined
  >();

  useEffect(() => {
    const vals = getValues(name);
    console.log(vals);
    setDefaultImages(vals);
    if (vals) setAnyImagesSelected(true);
  }, [getValues, name]);

  registration.register(name, options);
  return (
    <div {...rest}>
      <PublicImagesPopup
        onImagesSelected={(data) => {
          setAnyImagesSelected(data.length > 0);
          onChange(name, data);
        }}
        defaultValues={defaultImages}
        maxImages={maxFiles}
      >
        <FormButton
          className={classNames(
            anyImagesSelected && 'bg-green-200',
            registration.errors[name] && 'bg-red-200',
          )}
        >
          {label}
        </FormButton>
      </PublicImagesPopup>
    </div>
  );
};

export default ImageSelectorInput;
