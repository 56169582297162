import {useCallback} from 'react';
import Button from '../../../form/button/Button';
import {ViewerElementProps} from '../../types/EditorBlock';
import BasicAdjustmentsContainer from '../common/BasicAdjustmentsContainer';
import {ButtonEditorStyleType, EditorButtonData} from './ButtonTypes';

const ButtonViewerBlock: React.FC<ViewerElementProps<EditorButtonData>> = ({
  data,
}) => {
  const onClick = useCallback(() => {
    window.open(data.href, data.openInNewTab ? '_blank' : '_self');
  }, [data.href, data.openInNewTab]);

  return (
    <BasicAdjustmentsContainer data={data}>
      <Button
        isShadow={data.buttonStyle === ButtonEditorStyleType.OUTLINE}
        onClick={() => onClick()}
      >
        {data.label}
      </Button>
    </BasicAdjustmentsContainer>
  );
};

export default ButtonViewerBlock;
