import React, {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {PublicImageDTO} from '../../../../api/media/dto/PublicImageDTO';
import Carousel from '../../../carousel/Carousel';
import FormButton from '../../../form/button/FormButton';
import ListBox, {ListBoxOption} from '../../../form/listbox/Listbox';
import ServerImage from '../../../serverImage/ServerImage';
import HiddenSettings from '../../common/HiddenSettings';
import {EditorElementProps} from '../../types/EditorBlock';
import {useUpdateEditorBlock} from '../../types/UseEditor';
import PublicImagesPopup from '../image/imagePopup/ImagesGridPopup';
import {ImageQuality} from '../image/ImageTypes';
import {EditorCarouselData} from './CarouselTypes';
import {SwiperSlide} from 'swiper/react';
import Input from '../../../form/input/Input';

const imageSizeOptions: ListBoxOption<ImageQuality>[] = [
  {label: 'Mid', value: ImageQuality.MID},
  {label: 'High', value: ImageQuality.HIGH},
  {label: 'Low', value: ImageQuality.LOW},
];

const CarouselEditorBlock: React.FC<EditorElementProps<EditorCarouselData>> = (
  originBlock,
) => {
  const setBlockMutation = useUpdateEditorBlock<EditorCarouselData>();

  const {
    getValues,
    setValue,
    register,
    formState: {errors},
    watch,
  } = useForm<EditorCarouselData>({
    mode: 'onSubmit',
    defaultValues: {
      ...originBlock.data,
    },
  });

  const OnImageUpdated = useCallback(
    (images: PublicImageDTO[]) => {
      setValue(
        'images',
        images.map((x) => x.id),
      );
    },
    [setValue],
  );

  useEffect(() => {
    const subscription = watch(() => {
      const updatedData = getValues();

      setBlockMutation.mutate({
        ...originBlock,
        data: {
          ...originBlock.data,
          ...updatedData,
          images: updatedData.images || [],
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [getValues, originBlock, setBlockMutation, watch]);

  return (
    <div className="text-2xl p-2 sm:p-5">
      <HiddenSettings opened={originBlock.data.images.length > 0}>
        <div className="flex gap-2 mb-3">
          <PublicImagesPopup onImagesSelected={OnImageUpdated} maxImages={10}>
            <FormButton>Select images</FormButton>
          </PublicImagesPopup>
          <ListBox
            name="quality"
            label="Quality"
            className="w-32"
            options={imageSizeOptions}
            setValue={setValue}
            getValues={getValues}
          />
          <Input
            label="Max width"
            name="maxWidth"
            registration={{register, errors}}
            className="flex-1"
          />
        </div>
      </HiddenSettings>
      {!originBlock.data.images ||
        (originBlock.data.images.length == 0 && (
          <PublicImagesPopup onImagesSelected={OnImageUpdated} maxImages={10}>
            <FormButton className="w-full">Select images</FormButton>
          </PublicImagesPopup>
        ))}
      {originBlock.data.images.length > 0 && (
        <Carousel isPagination isArrow>
          {originBlock.data.images.map((imageId) => (
            <SwiperSlide key={imageId} className="">
              <div key={imageId} className="w-full h-full">
                <div className="w-full mx-auto">
                  <ServerImage
                    imageId={imageId}
                    alt={`Carousel image ${imageId}`}
                    className="w-full h-full"
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default CarouselEditorBlock;
