import React, {useState, useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineClose,
  AiOutlinePlus,
} from 'react-icons/ai';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {PublicImageDTO} from '../../../../../api/media/dto/PublicImageDTO';
import {useGetAllPublicImages} from '../../../../../api/media/Media';
import {BasicElement} from '../../../../../helpers/BasicElement';
import Button from '../../../../form/button/Button';
import FormButton from '../../../../form/button/FormButton';
import Input from '../../../../form/input/Input';
import AboutImagePopup from './AboutImagePopup';
import ImageGridElement from './ImageGridElement';
import './imagePopup.scoped.css';
import UploadImagesPopup from './UploadImagesPopup';

interface ImagesFilter {
  search: string;
}

interface PublicImagesPopupProps extends BasicElement {
  onImagesSelected: (image: PublicImageDTO[]) => void;
  minImages?: number;
  maxImages?: number;
  defaultValues?: PublicImageDTO[];
}

const PublicImagesPopup: React.FC<PublicImagesPopupProps> = ({
  children,
  onImagesSelected,
  defaultValues,
  minImages = 1,
  maxImages = 1,
}) => {
  const {
    register,
    getValues,
    formState: {errors},
  } = useForm<ImagesFilter>();

  const [openPopup, setOpenPopup] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectBtnEnabled, setSelectBtnEnabled] = useState(false);

  const [currentImages, setCurrentImage] = useState<PublicImageDTO[]>(
    defaultValues || [],
  );
  const [infoImage, setInfoImage] = useState<PublicImageDTO | undefined>();
  const [isInfoOpened, setIsInfoOpened] = useState(false);

  const [dateOrderMultiplyer, setDateOrderMultiplyer] = useState(1);

  const allPublicImages = useGetAllPublicImages();

  const setImage = useCallback(
    (image: PublicImageDTO) => {
      setCurrentImage((old) => {
        const existingElement = old.find((x) => x.id === image.id);
        if (existingElement)
          return old.filter((x) => x.id !== existingElement.id);

        if (maxImages === 1) return [image];

        if (old.length < maxImages) return [...old, image];

        return old;
      });
    },
    [maxImages],
  );

  useEffect(() => {
    setSelectBtnEnabled(
      currentImages.length >= minImages && currentImages.length <= maxImages,
    );
  }, [currentImages, maxImages, minImages]);

  const onImageFinish = useCallback(() => {
    if (selectBtnEnabled) {
      onImagesSelected(currentImages);
      setOpenPopup(false);
    }
  }, [currentImages, onImagesSelected, selectBtnEnabled]);

  return (
    <Popup
      trigger={<div className="flex justify-center">{children}</div>}
      contentStyle={{padding: '15px', borderRadius: '0.375rem'}}
      modal
      nested
      onClose={() => setOpenPopup(false)}
      onOpen={() => setOpenPopup(true)}
      open={openPopup}
    >
      <AboutImagePopup
        isInfoOpened={isInfoOpened}
        onClosed={() => setIsInfoOpened(false)}
        image={infoImage}
      />

      <div className="absolute right-1 top-1 cursor-pointer">
        <AiOutlineClose onClick={() => setOpenPopup(false)} />
      </div>
      <div className="mt-6">
        <div className="flex mb-3 gap-3">
          <Input<ImagesFilter>
            name="search"
            label="Search"
            className="flex-grow"
            onChange={() => setSearchText(getValues('search'))}
            registration={{register, errors}}
          />
          <FormButton
            className="text-xs"
            onClick={() => setDateOrderMultiplyer((old) => old * -1)}
          >
            <div className="flex">
              <span className="flex-grow">Order by date</span>
              <div className="mx-2 mt-1">
                {dateOrderMultiplyer === 1 ? (
                  <AiOutlineArrowDown />
                ) : (
                  <AiOutlineArrowUp />
                )}
              </div>
            </div>
          </FormButton>
        </div>
        <div className="grid lg:grid-cols-5 md:grid-cols-3 gap-2 overflow-auto images-popup-container">
          <UploadImagesPopup>
            <div className="w-full relative cursor-pointer pt-full">
              <div className="absolute top-0 left-0 w-full border border-gray-200 hover:bg-gray-100 p-10">
                <AiOutlinePlus className="block w-full h-full" />
              </div>
            </div>
          </UploadImagesPopup>

          {!allPublicImages.isSuccess
            ? 'Loading...'
            : allPublicImages.data
                .filter((image) =>
                  image.originalName
                    .toLowerCase()
                    .includes(searchText.toLowerCase()),
                )
                .sort((a, b) => {
                  return (
                    (b.creationDateTime - a.creationDateTime) *
                    dateOrderMultiplyer
                  );
                })
                .map((image) => (
                  <ImageGridElement
                    key={image.id}
                    image={image}
                    isActive={!!currentImages.find((x) => x.id === image.id)}
                    onClick={() => setImage(image)}
                    onFullscreenClicked={() => {
                      setInfoImage(image);
                      setIsInfoOpened(true);
                    }}
                  />
                ))}
        </div>
        <Button
          className="mt-3 w-full rounded-md"
          size="base"
          disabled={!selectBtnEnabled}
          onClick={() => onImageFinish()}
          noRadius
        >
          Select
        </Button>
      </div>
    </Popup>
  );
};

export default PublicImagesPopup;
