export interface EditorBlockData {
  majorVersion: number;
}

export enum BlockContainer {
  Default,
  Presentation,
}

export interface BlockMargin {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

export interface EditorBlock<T extends EditorBlockData> {
  id: string;
  container: BlockContainer;
  type: string;
  order: number;
  margin: BlockMargin;
  data: T;
}

export interface ViewerElementProps<T extends EditorBlockData>
  extends EditorBlock<T> {
  isUseSharedLiveVersion: boolean;
}

export interface EditorElementProps<T extends EditorBlockData>
  extends EditorBlock<T> {
  onSaveData?: (serializedData: string) => void;
}

export interface EditorBlockRefHandler {
  saveBlock(): EditorBlock<EditorBlockData>;
}
