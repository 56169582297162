import React from 'react';
import ReactQuill from 'react-quill';
import {ViewerElementProps} from '../../types/EditorBlock';
import BasicAdjustmentsContainer from '../common/BasicAdjustmentsContainer';
import {EditorParagraphData} from './ParagraphType';
import './ParagraphViewer.css';

const EditorParagraphBlock: React.FC<
  ViewerElementProps<EditorParagraphData>
> = (originBlock) => (
  <BasicAdjustmentsContainer data={originBlock.data}>
    <div className="mt-3 paragraph-viewer">
      <ReactQuill
        theme="snow"
        readOnly
        modules={{
          formula: true,
          toolbar: false,
        }}
        value={originBlock.data.paragraph}
      />
    </div>
  </BasicAdjustmentsContainer>
);

export default EditorParagraphBlock;
