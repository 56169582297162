import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {
  vimeoParser,
  youtubeParser,
} from '../../../../services/utils/youtubeParser';
import Input from '../../../form/input/Input';
import BasicAdjustmentsEditor from '../../common/BasicAdjustmentsEditor';
import HiddenSettings from '../../common/HiddenSettings';
import {EditorElementProps} from '../../types/EditorBlock';
import {useUpdateEditorBlock} from '../../types/UseEditor';
import {EditorVideoData} from './VideoTypes';

const VideoEditorBlock: React.FC<EditorElementProps<EditorVideoData>> = (
  originBlock,
) => {
  const setBlockMutation = useUpdateEditorBlock<EditorVideoData>();

  const {
    register,
    watch,
    getValues,
    setValue,
    formState: {errors},
  } = useForm<EditorVideoData>({
    mode: 'onSubmit',
    defaultValues: {
      ...originBlock.data,
    },
  });

  useEffect(() => {
    const subscription = watch((updatedData) => {
      const youtubeParsed = youtubeParser(updatedData.url || '');
      const vimeoParsed = vimeoParser(updatedData.url || '');

      setBlockMutation.mutate({
        ...originBlock,
        data: {
          ...originBlock.data,
          ...updatedData,
          url: youtubeParsed || vimeoParsed || updatedData.url || '',
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [originBlock, setBlockMutation, watch]);

  return (
    <div className="p-2 sm:p-5">
      <HiddenSettings>
        <BasicAdjustmentsEditor
          getValues={getValues}
          setValue={setValue}
          registration={{register, errors}}
        />
      </HiddenSettings>
      <div className="">
        <Input
          label="Video"
          name="url"
          className="mb-3"
          registration={{register, errors}}
        />
        <div className="flex justify-center">
          <div className="aspect-video relative w-full lg:w-4/6">
            <iframe
              src={originBlock.data.url}
              title="Video"
              className="w-full h-full absolute"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoEditorBlock;
