import classNames from 'classnames';
import React, {useState} from 'react';
import Collapsible from 'react-collapsible';
import {BsArrowRight} from 'react-icons/bs';

interface QuestionLineProps extends React.HTMLProps<HTMLDivElement> {
  question: string;
  number: string;
}

const QuestionLine: React.FC<QuestionLineProps> = ({
  question,
  children,
  number,
  ...rest
}) => {
  const [isColapsed, setIsColapsed] = useState(true);

  return (
    <div
      className="mb-2 w-full px-3 py-2 border-b border-solid border-gray-200"
      {...rest}
    >
      <Collapsible
        onOpening={() => setIsColapsed(false)}
        onClosing={() => setIsColapsed(true)}
        trigger={
          <div className="flex text-xl">
            <p className="font-bold text-xl md:text-xl">
              <span className="font-montserrat text-pink-600 mr-4 font-normal">
                {number}
              </span>
              {question}
            </p>
            <div className="flex-grow" />
            <div
              className={classNames(
                'transform transition ease-in-out duration-200 mt-1 flex items-center',
                isColapsed ? 'rotate-0' : '-rotate-90',
              )}
            >
              <BsArrowRight className="text-pink-600" size={24} />
            </div>
          </div>
        }
        transitionTime={200}
      >
        <div className="mt-4 text-lg">
          <div className="text-xl ml-0 lg:ml-9">{children}</div>
        </div>
      </Collapsible>
    </div>
  );
};

export default QuestionLine;
