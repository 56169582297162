import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import Input from '../../../form/input/Input';
import BasicAdjustmentsEditor from '../../common/BasicAdjustmentsEditor';
import HiddenSettings from '../../common/HiddenSettings';
import {EditorElementProps} from '../../types/EditorBlock';
import {useUpdateEditorBlock} from '../../types/UseEditor';
import {EditorGameData} from './GameTypes';

const GameEditorBlock: React.FC<EditorElementProps<EditorGameData>> = (
  originBlock,
) => {
  const setBlockMutation = useUpdateEditorBlock<EditorGameData>();

  const {
    register,
    watch,
    getValues,
    setValue,
    formState: {errors},
  } = useForm<EditorGameData>({
    mode: 'onSubmit',
    defaultValues: {
      ...originBlock.data,
    },
  });

  useEffect(() => {
    const subscription = watch((updatedData) => {
      setBlockMutation.mutate({
        ...originBlock,
        data: {
          ...originBlock.data,
          ...updatedData,
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [originBlock, setBlockMutation, watch]);

  return (
    <div className="p-2 sm:p-5">
      <HiddenSettings>
        <div>
          <Input
            label="Video"
            name="url"
            className="mb-3"
            registration={{register, errors}}
          />
        </div>
        <BasicAdjustmentsEditor
          getValues={getValues}
          setValue={setValue}
          registration={{register, errors}}
        />
      </HiddenSettings>
      <div className="flex justify-center">
        <div className="aspect-video relative w-full lg:w-4/6">
          <iframe
            src={originBlock.data.url}
            title="Video"
            className="w-full h-full absolute"
          />
        </div>
      </div>
    </div>
  );
};

export default GameEditorBlock;
