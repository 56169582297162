import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AiOutlineRollback} from 'react-icons/ai';
import {Link, useNavigate} from 'react-router-dom';
import {useGetAdminCourses} from '../../../api/courses/Courses';
import {useGetAllLanguages} from '../../../api/localization/Localization';
import AdminCourseCard from '../../../components/admin/adminCourses/adminCourseCard/AdminCourseCard';
import LoadingEditorBlock from '../../../components/editor/elements/common/LoadingBlock';
import FormButton from '../../../components/form/button/FormButton';
import './AdminCoursesPage.css';

const AdminCoursesPage: React.FC = () => {
  const {t} = useTranslation('simulationsPage');
  const allSimulations = useGetAdminCourses();

  const [currentLanguage, setCurrentLanguage] = useState<string>();
  const languages = useGetAllLanguages({
    onSuccess: (data) => {
      setCurrentLanguage(data[0].id);
    },
  });

  const history = useNavigate();

  if (!allSimulations.isSuccess || !languages.isSuccess)
    return <LoadingEditorBlock />;
  return (
    <>
      <section>
        <h1 className="my-10 font-bold text-6xl text-center uppercase text-indigo-900">
          {t('katalog-simulyacii')}
        </h1>
        <div className="flex gap-2 mb-2">
          <Link to="/admin" className="bg-white w-fit">
            <FormButton>
              <div className="flex justify-between items-center gap-2">
                <AiOutlineRollback /> Back
              </div>
            </FormButton>
          </Link>
          <div className="flex gap-2">
            {languages.data.map((el) => (
              <div className="bg-white" key={el.id}>
                <FormButton
                  onClick={() => setCurrentLanguage(el.id)}
                  selected={el.id == currentLanguage}
                >
                  {el.name}
                </FormButton>
              </div>
            ))}
          </div>
          <FormButton
            className="flex-1"
            highlight
            onClick={() => history('/admin/simulations/add-simulation')}
          >
            Add new simulation
          </FormButton>
        </div>
      </section>
      <section className="grid grid-cols-1 lg:grid-cols-2 max-w-screen-sm lg:max-w-screen-xl mx-auto gap-8 my-6">
        {allSimulations.isSuccess &&
          allSimulations.data.map((element) => (
            <AdminCourseCard
              data={{
                ...element,
                translation:
                  element.translations.find(
                    (x) => x.languageId == currentLanguage,
                  ) || element.translations[0],
              }}
              key={element.id}
            />
          ))}
      </section>
    </>
  );
};

export default AdminCoursesPage;
