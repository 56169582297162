import classNames from 'classnames';
import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {AddCourseDTO} from '../../../../api/courses/dao/CreateCourseRequestDAO';
import {
  CourseSection,
  CourseSubject,
  CourseTranslationDTO,
} from '../../../../api/courses/dto/CourseDTO';
import {useGetAllLanguages} from '../../../../api/localization/Localization';
import TranslationEditor, {
  HollowCourseTranslationDTO,
} from './TranslationEditor';
import LoadingEditorBlock from '../../../editor/elements/common/LoadingBlock';
import {SubscribeToPersistStorage} from '../../../editor/types/UseEditor';
import FormButton from '../../../form/button/FormButton';
import ImageSelectorInput from '../../../form/imageSelector/ImageSelectorInput';
import Input from '../../../form/input/Input';
import ListBox, {ListBoxOption} from '../../../form/listbox/Listbox';
import {
  useAddNewCourse,
  useEditCourse,
} from '../../../../api/courses/CoursesMutations';
import {SaveBlocks} from '../../../editor/service/ContentEditor/Helpers';
import {
  NotificationType,
  UniversalNotificationHandler,
} from '../../../../services/globalNotification/universalNotificationHandler';
import {Link, useNavigate} from 'react-router-dom';
import {AiOutlineRollback} from 'react-icons/ai';
import {initLocalSave} from '../../../../services/utils/localSaver';

interface CourseEditorProps {
  courseData?: AddCourseDTO;
  courseId?: string;
}

const CourseEditor: React.FC<CourseEditorProps> = ({courseData, courseId}) => {
  const navigation = useNavigate();
  const createCourse = useAddNewCourse({
    onSuccess: () => {
      UniversalNotificationHandler(
        'Created',
        'Created',
        NotificationType.success,
      );
      navigation('/admin/simulations');
    },
  });
  const updateCourse = useEditCourse(courseId || '', {
    onSuccess: () => {
      UniversalNotificationHandler('Saved', 'saved', NotificationType.success);
    },
  });

  const [courseCategories] = useState<ListBoxOption<CourseSection>[]>(
    (Object.keys(CourseSection) as (keyof typeof CourseSection)[]).map(
      (key) => {
        return {
          label: CourseSection[key],
          value: CourseSection[key],
        };
      },
    ),
  );

  const [courseSubjects] = useState<ListBoxOption<CourseSubject>[]>(
    (Object.keys(CourseSubject) as (keyof typeof CourseSubject)[]).map(
      (key) => {
        return {
          label: CourseSubject[key],
          value: CourseSubject[key],
        };
      },
    ),
  );

  const [translationsData, setTranslationsData] = useState<{
    [key: string]: HollowCourseTranslationDTO;
  }>({});

  const [currentLanguage, setCurrentLanguage] = useState(0);
  const languages = useGetAllLanguages();

  useEffect(() => {
    SubscribeToPersistStorage();
  }, []);

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: {errors},
  } = useForm<Omit<AddCourseDTO, 'translations'>>({
    defaultValues: {
      section: CourseSection.VR,
      subject: CourseSubject.Biology,
      duration: 15,
      ...courseData,
    },
  });

  const getSaveData = useCallback(
    (mainFormData: Omit<AddCourseDTO, 'translations'>): AddCourseDTO => {
      const savedBlocks: CourseTranslationDTO[] = [];

      Object.keys(translationsData).forEach((key) => {
        const body = JSON.stringify(SaveBlocks(translationsData[key].bodyId));
        savedBlocks.push({...translationsData[key], body});
      });

      const data: AddCourseDTO = {
        ...mainFormData,
        translations: savedBlocks,
      };

      return data;
    },
    [translationsData],
  );

  const OnSave = useCallback(
    (mainFormData: Omit<AddCourseDTO, 'translations'>) => {
      const data = getSaveData(mainFormData);

      if (!courseId) createCourse.mutate(data);
      else updateCourse.mutate(data);

      console.log(data);
    },
    [courseId, createCourse, getSaveData, updateCourse],
  );

  if (!languages.isSuccess) return <LoadingEditorBlock />;
  return (
    <form className="my-4" onSubmit={handleSubmit(OnSave)}>
      <div className="gap-2 flex mb-3">
        <Link to="/admin/simulations" className="bg-white w-fit">
          <FormButton>
            <div className="flex justify-between items-center gap-2">
              <AiOutlineRollback /> Back
            </div>
          </FormButton>
        </Link>
        {courseId && (
          <Link to={`/admin/simulations/${courseId}`} target="_blank">
            <FormButton>Open</FormButton>
          </Link>
        )}
      </div>
      <div className="gap-2 grid">
        <div className="flex gap-2">
          <ListBox
            name="section"
            className="flex-1"
            options={courseCategories}
            label="Category"
            setValue={setValue}
            getValues={getValues}
          />
          <ListBox
            name="subject"
            className="flex-1"
            options={courseSubjects}
            label="Subject"
            setValue={setValue}
            getValues={getValues}
          />
        </div>
        <div className="flex gap-2">
          <ImageSelectorInput
            label="Thumbnail image"
            name="thumbnailImageId"
            registration={{register, errors}}
            getValues={getValues}
            onChange={(name, value) => {
              setValue(name, value[0].id);
            }}
            options={{required: 'Required'}}
            maxFiles={1}
          />
          <ImageSelectorInput
            label="Background image"
            name="backgroundImageId"
            registration={{register, errors}}
            getValues={getValues}
            onChange={(name, value) => {
              setValue(name, value[0].id);
            }}
            options={{required: 'Required'}}
            maxFiles={1}
          />

          <Input
            label="Duration"
            name="duration"
            type="number"
            registration={{register, errors}}
            options={{required: 'Required'}}
          />
        </div>
      </div>
      <hr className="my-4" />
      <div className="grid gap-2">
        <div className="flex gap-2 mb-2">
          {languages.data.map((el, index) => (
            <FormButton
              key={el.id}
              onClick={() => setCurrentLanguage(index)}
              selected={index == currentLanguage}
            >
              {el.name}
            </FormButton>
          ))}
        </div>
        {languages.data.map((el, index) => (
          <TranslationEditor
            key={el.id}
            language={el}
            className={classNames(currentLanguage !== index && 'hidden')}
            onFormUpdated={(data) =>
              setTranslationsData((old) => ({...old, [data.languageId]: data}))
            }
            originalTranslation={courseData?.translations.find(
              (x) => x.languageId == el.id,
            )}
          />
        ))}
        <div className="flex gap-2">
          <FormButton type="submit" className="bg-pink-500 text-white flex-1">
            Save
          </FormButton>
          <FormButton
            onClick={() => {
              const data = getSaveData(getValues());
              initLocalSave(data, `simulation-${courseId || 'new'}`);
            }}
            className="flex-shrink"
          >
            Save local
          </FormButton>
          {/* {courseId && (
            <>
              <FormButton
                onClick={() =>
                  window.open(`/admin/simulations/live/${courseId}`, '_blank')
                }
              >
                Live preview
              </FormButton>
              <FormButton
                onClick={() =>
                  window.open(`/simulations/${courseId}`, '_blank')
                }
              >
                Open page
              </FormButton>
            </>
          )} */}
        </div>
      </div>
    </form>
  );
};

export default CourseEditor;
