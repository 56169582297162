import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {TranslationsCreateCustomPageDAO} from '../../../../api/customPage/dao/CustomPageDAO';
import {LocalizationDTO} from '../../../../api/localization/dto/LocalizationDTO';
import {GetGridTemplate} from '../../../editor/BlocksTemplates';
import {EditorGridViewerData} from '../../../editor/elements/grid/GridType';
import ContentEditor from '../../../editor/service/ContentEditor';
import {
  CopyEditorBlocks,
  PasteEditorBlocks,
} from '../../../editor/service/copyPaste/CopyEditorBlocks';
import {EditorBlock} from '../../../editor/types/EditorBlock';
import FormButton from '../../../form/button/FormButton';

interface CustomPageTranslationEditorProps
  extends React.HTMLProps<HTMLDivElement> {
  language: LocalizationDTO;
  onFormUpdated: (data: CustomPageTranslation) => void;
  originalTranslation?: TranslationsCreateCustomPageDAO;
}

export interface CustomPageTranslation {
  languageId: string;
  blockId: string;
}

const CustomPageTranslationEditor: React.FC<
  CustomPageTranslationEditorProps
> = ({language, onFormUpdated, originalTranslation, className, ...rest}) => {
  const {watch} = useForm<CustomPageTranslation>({
    defaultValues: {
      ...originalTranslation,
    },
  });

  const [baseBlock, setBaseBlock] = useState<EditorBlock<EditorGridViewerData>>(
    originalTranslation
      ? JSON.parse(originalTranslation.block.rawData)
      : GetGridTemplate(),
  );

  const SendUpdate = useCallback(
    (data: CustomPageTranslation) => {
      onFormUpdated({...data});
    },
    [onFormUpdated],
  );

  useEffect(() => {
    const subscription = watch(() => {
      SendUpdate({
        languageId: language.id,
        blockId: baseBlock.id,
      });
    });
    return () => subscription.unsubscribe();
  }, [SendUpdate, baseBlock.id, language.id, watch]);

  useEffect(() => {
    SendUpdate({languageId: language.id, blockId: baseBlock.id});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className} {...rest}>
      <div className="flex gap-2 mb-2">
        {/* кнопки Copy и Paste: они работают с буфером обмена, а значит можно на одной вкладке нажать copy и вставить на другой.  Вставить можно в другое место на другой странице или другом переводе. При paste он заново всем объектам задает id. Соответственно при paste на старнице у нас может изменится baseBlock.Id . Идея в том что можно откуда угодно куда угодно перенести*/}
        <FormButton onClick={() => CopyEditorBlocks(baseBlock.id)}>
          Copy
        </FormButton>
        <FormButton
          onClick={() => {
            PasteEditorBlocks((data: EditorBlock<EditorGridViewerData>) => {
              setBaseBlock(data);
              SendUpdate({
                languageId: language.id,
                blockId: data.id,
              });
            });
          }}
        >
          Paste
        </FormButton>
      </div>
      <ContentEditor coreBlock={baseBlock} />
    </div>
  );
};

export default CustomPageTranslationEditor;
