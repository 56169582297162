/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react';
import {EditorBlocks} from '../../AllBlocks';
import LoadingEditorBlock from '../../elements/common/LoadingBlock';
import {EditorBlock, EditorBlockData} from '../../types/EditorBlock';
import {useEditorBlock, useUpdateEditorBlock} from '../../types/UseEditor';

interface ViewerBlockWrapperProps {
  originBlock: EditorBlock<EditorBlockData>;
  isUseLocalVersion?: boolean;
}
const ViewerBlockWrapper: React.FC<ViewerBlockWrapperProps> = ({
  originBlock,
  isUseLocalVersion = false,
}) => {
  const block = useEditorBlock(originBlock.id);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setBlockMutation = useUpdateEditorBlock<any>();
  useEffect(() => {
    if (!isUseLocalVersion) {
      setBlockMutation.mutate(originBlock);
    }
  }, []);

  return block.isSuccess ? (
    <div
      style={{
        marginTop: block.data.margin?.top || '0px',
        marginBottom: block.data.margin?.bottom || '0px',
        marginLeft: block.data.margin?.left || '0px',
        marginRight: block.data.margin?.right || '0px',
        order: block.data.order,
      }}
    >
      {EditorBlocks[block.data.type].viewer(block.data, isUseLocalVersion)}
    </div>
  ) : (
    <LoadingEditorBlock />
  );
};

export default ViewerBlockWrapper;
