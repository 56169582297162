import classNames from 'classnames';
import React from 'react';
import CollapsibleArea from '../../collapsibleArea/CollapsibleArea';
import './ReviewElement.css';

interface ReviewElementProps extends React.HTMLAttributes<HTMLDivElement> {
  image?: string;
  header: string;
  subheader: React.ReactNode;
  text?: string;
}

const ReviewElement: React.FC<ReviewElementProps> = ({
  image,
  header,
  subheader,
  text,
  className,
  children,
  ...others
}) => {
  return (
    <div
      className={classNames(
        className,
        'w-full h-full flex flex-col lg:flex-row items-center lg:items-start gap-x-7',
      )}
      {...others}
    >
      {image && (
        <img
          src={image}
          className="w-60 h-60 lg:my-auto rounded-full"
          alt="Parent"
        />
      )}
      <div className={classNames(!image && 'w-full')}>
        <div className="mt-3 font-bold text-lg lg:text-3xl uppercase text-indigo-900">
          {header}
        </div>
        <div className="text-xs lg:text-sm text-black font-light lg:font-normal my-2">
          {subheader}
        </div>
        {text && (
          <div className="mt-6 text-xs md:text-sm lg:text-xl font-light lg:font-normal text-black">
            <CollapsibleArea text={text}>
              <p>{text}</p>
            </CollapsibleArea>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default ReviewElement;
