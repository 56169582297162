import classNames from 'classnames';
import i18next from 'i18next';
import React, {useState, useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {AiOutlineClose} from 'react-icons/ai';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {LocalizationDTO} from '../../../../api/localization/dto/LocalizationDTO';
import {useGetAllLanguages} from '../../../../api/localization/Localization';
import {SketchfabModelDTO} from '../../../../api/sketchfab/dto/SketchfabModelDTO';
import {useGetAllSketchfabModels} from '../../../../api/sketchfab/sketchfab';
import {BasicElement} from '../../../../helpers/BasicElement';
import Button from '../../../form/button/Button';
import Input from '../../../form/input/Input';
import ListBox, {ListBoxOption} from '../../../form/listbox/Listbox';
import ServerImage from '../../../serverImage/ServerImage';
import LoadingEditorBlock from '../../elements/common/LoadingBlock';
import {ImageQuality} from '../../elements/image/ImageTypes';
import {
  SketchfabModelAnimationData,
  SketchfabModelData,
} from '../../elements/sketchfab/SketchfabType';
import './AllSketchfabPopap.scoped.css';

interface SketchfabsFilter {
  search: string;
  language: LocalizationDTO;
}

interface AllSketchfabPopupProps extends BasicElement {
  onModelSelected: (sketchfab: SketchfabModelData) => void;
}

const AllSketchfabPopup: React.FC<AllSketchfabPopupProps> = ({
  children,
  onModelSelected,
}) => {
  const {
    register,
    setValue,
    getValues,
    watch,
    formState: {errors},
  } = useForm<SketchfabsFilter>();

  const [openPopup, setOpenPopup] = useState(false);
  const [currentSketchfab, setCurrentSketchfab] =
    useState<SketchfabModelDTO | null>(null);
  // const [searchText, setSearchText] = useState('');
  const [languageOptions, setLanguageOptions] = useState<
    ListBoxOption<LocalizationDTO>[]
  >([]);

  const [searchText, setSearchText] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState<LocalizationDTO>();

  const Languages = useGetAllLanguages({
    onSuccess: (data) => {
      const elements = data.map((x) => {
        return {value: x, label: x.name} as ListBoxOption<LocalizationDTO>;
      });
      if (elements && elements.length > 0)
        setSelectedLanguage(elements[0].value);
      setLanguageOptions(elements);
    },
  });

  useEffect(() => {
    const subscription = watch((updatedData) => {
      if (updatedData.search) setSearchText(updatedData.search);
      else setSearchText('');

      if (updatedData.language)
        setSelectedLanguage(updatedData.language as LocalizationDTO);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const AllSketchfabModels = useGetAllSketchfabModels(i18next.language);

  const onSketchfabCreate = useCallback(() => {
    if (!currentSketchfab || !selectedLanguage?.id) return;

    const modelTranslation =
      currentSketchfab.translations.find(
        (x) => x.languageId == selectedLanguage?.id,
      ) || currentSketchfab.translations[0];

    const modelAnimations = currentSketchfab.animations.map((el) => {
      return {
        ...el,
        translation:
          el.translations.find((x) => x.languageId === selectedLanguage.id) ||
          el.translations[0],
      } as SketchfabModelAnimationData;
    });

    const sketchfabModel: SketchfabModelData = {
      ...currentSketchfab,
      animations: modelAnimations,
      translation: modelTranslation,
      defaultAnimation: currentSketchfab.animations[0]?.animationId || '',
      autoplay: false,
      isShowBackground: false,
    };

    onModelSelected(sketchfabModel);
    setOpenPopup(false);
  }, [currentSketchfab, onModelSelected, selectedLanguage]);

  const changeCurrentSketchfab = (sketchfab: SketchfabModelDTO) => {
    if (currentSketchfab?.id !== sketchfab.id) {
      setCurrentSketchfab(sketchfab);
    } else {
      setCurrentSketchfab(null);
    }
  };

  if (!Languages.isSuccess || !AllSketchfabModels.isSuccess)
    return <LoadingEditorBlock />;

  return (
    <Popup
      trigger={<div className="flex justify-center">{children}</div>}
      contentStyle={{padding: '15px', borderRadius: '0.375rem'}}
      modal
      nested
      onClose={() => setOpenPopup(false)}
      onOpen={() => setOpenPopup(true)}
      open={openPopup}
    >
      <div className="absolute right-1 top-1 cursor-pointer">
        <AiOutlineClose onClick={() => setOpenPopup(false)} />
      </div>
      <div className="mt-6">
        <div className="flex mb-3 gap-3">
          <ListBox<SketchfabsFilter>
            name="language"
            options={languageOptions}
            label="Language"
            setValue={setValue}
            getValues={getValues}
          />
          <Input<SketchfabsFilter>
            name="search"
            label="Search"
            className="flex-grow"
            registration={{register, errors}}
          />
        </div>
        <div className="overflow-auto sketchfab-popup-container flex flex-col">
          {searchText}
          {!selectedLanguage || !AllSketchfabModels.isSuccess
            ? 'Loading...'
            : AllSketchfabModels.data
                .filter((sketchfab) =>
                  sketchfab.translations.some(
                    (x) =>
                      x.title
                        .toLocaleLowerCase()
                        .includes(searchText.toLowerCase()) &&
                      x.languageId === selectedLanguage.id,
                  ),
                )
                .sort()
                .map((sketchfab) => (
                  <div
                    key={sketchfab.id}
                    className={classNames(
                      'flex justify-between mb-2 cursor-pointer',
                      currentSketchfab &&
                        sketchfab.id === currentSketchfab.id &&
                        'bg-blue-100',
                      currentSketchfab &&
                        sketchfab.id !== currentSketchfab.id &&
                        'hover:bg-blue-50',
                    )}
                    onClick={() => changeCurrentSketchfab(sketchfab)}
                  >
                    <ServerImage
                      imageId={sketchfab.thumbnailImageId}
                      quality={ImageQuality.LOW}
                      className="object-cover w-1/6"
                      alt="Public image"
                    />
                    <div className="w-1/6 flex justify-center items-center">
                      {
                        sketchfab.translations.find(
                          (x) => x.languageId === selectedLanguage?.id,
                        )?.title
                      }
                    </div>
                    <div className="w-1/3 flex justify-center items-center">
                      {
                        sketchfab.translations.find(
                          (x) => x.languageId === selectedLanguage?.id,
                        )?.description
                      }
                    </div>
                    <div className="w-1/3 flex justify-center items-center">
                      [Биология, структура человека]
                    </div>
                  </div>
                ))}
        </div>
        <div className="flex justify-center">
          <Button
            className="mt-3 rounded-md"
            size="base"
            noRadius
            disabled={!currentSketchfab}
            onClick={() => onSketchfabCreate()}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default AllSketchfabPopup;
