import {useParams} from 'react-router-dom';
import {useGetCustomPage} from '../../api/customPage/CustomPage';
import LoadingEditorBlock from '../../components/editor/elements/common/LoadingBlock';
import ViewerAutoblock from '../../components/editor/service/autoBlocks/ViewerAutoblock';
import {useLocalization} from '../../services/flagProvider/FlagProvider';

const CustomPage: React.FC = () => {
  const {section, subsection, page} = useParams<{
    section?: string;
    subsection?: string;
    page: string;
  }>();
  const localization = useLocalization();

  const customPage = useGetCustomPage(
    page || '',
    localization.getCurrentLocale(),
    section,
    subsection,
  );

  if (customPage.isLoading) return <LoadingEditorBlock />;
  if (!customPage.isSuccess) return <div>Cant find this page</div>;

  return (
    <div>
      {customPage.data.body && (
        <ViewerAutoblock blocks={[JSON.parse(customPage.data.body)]} />
      )}
    </div>
  );
};

export default CustomPage;
