import React, {useCallback} from 'react';
import {AiOutlineFileImage, AiOutlineLoading} from 'react-icons/ai';
import ServerImage from '../../../serverImage/ServerImage';
import {ViewerElementProps} from '../../types/EditorBlock';
import BasicAdjustmentsContainer from '../common/BasicAdjustmentsContainer';
import {EditorImageData, ImageType} from './ImageTypes';

const ImageViewerBlock: React.FC<ViewerElementProps<EditorImageData>> = (
  originBlock,
) => {
  const getImageFormatClass = useCallback((): string => {
    switch (originBlock.data.sizeType) {
      case ImageType.Format169:
        return 'aspect-w-16 aspect-h-9';

      case ImageType.Format43:
        return 'aspect-w-4 aspect-h-3';

      case ImageType.FormatFull:
      default:
        return 'w-full';
    }
  }, [originBlock]);

  return (
    <BasicAdjustmentsContainer
      data={originBlock.data}
      className="flex justify-center"
      disableAutoBlockControl
    >
      <div className="text-2xl p-2 sm:p-5">
        <div className={getImageFormatClass()}>
          {originBlock.data.imageId ? (
            <ServerImage
              imageId={originBlock.data.imageId}
              alt={originBlock.data.imageId}
              imageFit={originBlock.data.imageFit}
            />
          ) : (
            <div className="flex gap-4 text-xl">
              <AiOutlineFileImage />
              <span>Image ID not found</span>
              <AiOutlineLoading className="animate-spin" />
            </div>
          )}
        </div>
      </div>
    </BasicAdjustmentsContainer>
  );
};

export default ImageViewerBlock;
