import {useLocalization} from '../../services/flagProvider/FlagProvider';

export interface LocaleRouteProps {
  ru: React.ReactElement;
  en: React.ReactElement;
}

export const LocaleRoute: React.FC<LocaleRouteProps> = ({ru, en}) => {
  const localization = useLocalization();

  if (localization.getCurrentLocale() === 'ru') return ru;
  return en;
};

export default LocaleRoute;
