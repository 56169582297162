/* eslint-disable react/no-array-index-key */
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {AiOutlineClose} from 'react-icons/ai';
import {FaTextHeight} from 'react-icons/fa';
import {
  BsCameraVideo,
  BsFillBriefcaseFill,
  BsGrid1X2,
  BsImage,
  BsImages,
  BsJoystick,
  BsQuestionOctagonFill,
  BsTextLeft,
} from 'react-icons/bs';
import {MdOutlineSmartButton} from 'react-icons/md';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {EditorBlock, EditorBlockData} from './types/EditorBlock';
import BlockTemplate from './service/blockTemplate/BlockTemplate';
import {BasicElement} from '../../helpers/BasicElement';
import ListBox, {ListBoxOption} from '../form/listbox/Listbox';
import Input from '../form/input/Input';
import {
  GetAbstractFieldAnswerBlockTemplate,
  GetButtonBlockTemplate,
  GetCarouselBlockTemplate,
  GetCorrectOrderBlockTemplate,
  GetCourseTableTemplate,
  GetFullCourseTemplate,
  GetGameBlockTemplate,
  GetGridTemplate,
  GetHeaderBlockTemplate,
  GetImageBlockTemplate,
  GetMatchingAnswerBlockTemplate,
  GetMultiAnswersBlockTemplate,
  GetParagraphBlockTemplate,
  GetSingleAnswerBlockTemplate,
  GetTableAnswerBlockTemplate,
  GetVideoBlockTemplate,
} from './BlocksTemplates';

enum BlockCategory {
  Any,
  Common,
  Pack3D,
  Premade,
  Question,
}

const BlockCategories: ListBoxOption<BlockCategory>[] = [
  {label: 'Any', value: BlockCategory.Any},
  {label: 'Common', value: BlockCategory.Common},
  {label: '3D pack', value: BlockCategory.Pack3D},
  {label: 'Premade', value: BlockCategory.Premade},
  {label: 'Question', value: BlockCategory.Question},
];

interface NewBlockFilter {
  search: string;
  category: BlockCategory;
}

interface EditorBlockTemplate {
  image: React.ReactNode;
  label: string;
  category: BlockCategory;
  priority?: number;
  template: () => EditorBlock<EditorBlockData>;
}

const defaultPriority = 1000;
const EditorBlocks: EditorBlockTemplate[] = [
  {
    image: <FaTextHeight />,
    label: 'Header',
    category: BlockCategory.Common,
    template: GetHeaderBlockTemplate,
    priority: 10,
  },
  {
    image: <BsTextLeft />,
    label: 'Paragraph',
    category: BlockCategory.Common,
    template: GetParagraphBlockTemplate,
    priority: 5,
  },
  {
    image: <BsImage />,
    label: 'Image',
    category: BlockCategory.Common,
    template: GetImageBlockTemplate,
    priority: 5,
  },
  {
    image: <BsImages />,
    label: 'Carousel',
    category: BlockCategory.Common,
    template: GetCarouselBlockTemplate,
    priority: 5,
  },
  {
    image: <BsCameraVideo />,
    label: 'Video',
    category: BlockCategory.Common,
    template: GetVideoBlockTemplate,
    priority: 5,
  },
  {
    image: <BsJoystick />,
    label: 'Game',
    category: BlockCategory.Common,
    template: GetGameBlockTemplate,
    priority: 5,
  },
  {
    image: <MdOutlineSmartButton />,
    label: 'Button',
    category: BlockCategory.Common,
    template: GetButtonBlockTemplate,
    priority: 10,
  },
  // {
  //   image: <BsCode />,
  //   label: 'Game',
  //   category: BlockCategory.Common,
  //   template: GetIframeBlockTemplate,
  //   priority: 5,
  // },
  {
    image: <BsGrid1X2 />,
    label: 'Grid',
    category: BlockCategory.Common,
    template: GetGridTemplate,
    priority: 10,
  },
  {
    image: <BsGrid1X2 style={{fill: '#007aff'}} />,
    label: 'Course blue grid',
    category: BlockCategory.Premade,
    template: GetCourseTableTemplate,
    priority: 100,
  },
  {
    image: <BsFillBriefcaseFill style={{fill: '#007aff'}} />,
    label: 'Full simulation ',
    category: BlockCategory.Premade,
    template: GetFullCourseTemplate,
    priority: 100,
  },
  {
    image: <BsQuestionOctagonFill />,
    label: 'Multiple answer question',
    category: BlockCategory.Question,
    template: GetMultiAnswersBlockTemplate,
    priority: 106,
  },
  {
    image: <BsQuestionOctagonFill />,
    label: 'Correct order question',
    category: BlockCategory.Question,
    template: GetCorrectOrderBlockTemplate,
    priority: 107,
  },
  {
    image: <BsQuestionOctagonFill />,
    label: 'Listbox question',
    category: BlockCategory.Question,
    template: GetSingleAnswerBlockTemplate,
    priority: 105,
  },
  // {
  //   image: <BsQuestionOctagonFill />,
  //   label: 'Multi answers question',
  //   category: BlockCategory.Common,
  //   template: GetMultiAnswersBlockTemplate,
  //   priority: 106,
  // },
  // {
  //   image: <ImQuestion />,
  //   label: 'Correct Order question',
  //   category: BlockCategory.Common,
  //   template: GetCorrectOrderBlockTemplate,
  //   priority: 107,
  // },
  {
    image: <BsQuestionOctagonFill />,
    label: 'Matching answer question',
    category: BlockCategory.Question,
    template: GetMatchingAnswerBlockTemplate,
    priority: 108,
  },
  {
    image: <BsQuestionOctagonFill />,
    label: 'Input question',
    category: BlockCategory.Question,
    template: GetAbstractFieldAnswerBlockTemplate,
    priority: 109,
  },
  {
    image: <BsQuestionOctagonFill />,
    label: 'Table Question',
    category: BlockCategory.Question,
    template: GetTableAnswerBlockTemplate,
    priority: 110,
  },
  // {
  //   image: <BsQuestionOctagonFill />,
  //   label: 'Rich text question',
  //   category: BlockCategory.Question,
  //   template: GetTextFieldAnswerBlockTemplate,
  //   priority: 111,
  // },
];

interface NewBlockPopupProps extends BasicElement {
  onSelected: (data: EditorBlock<EditorBlockData>) => void;
}

const NewBlockPopup: React.FC<NewBlockPopupProps> = ({
  children,
  onSelected,
}) => {
  const [openPopup, setOpenPopup] = useState(false);

  const {
    register,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<NewBlockFilter>();

  const [searchText, setSearchText] = useState('');
  const [currectCategory, setCurrentCategory] = useState(BlockCategory.Any);

  return (
    <Popup
      trigger={<div>{children}</div>}
      contentStyle={{padding: '15px', borderRadius: '0.375rem'}}
      modal
      nested
      onClose={() => setOpenPopup(false)}
      onOpen={() => setOpenPopup(true)}
      open={openPopup}
    >
      <div className="absolute right-1 top-1">
        <AiOutlineClose
          className="cursor-pointer"
          onClick={() => setOpenPopup(false)}
        />
      </div>

      <div className="mt-6">
        <div className="grid grid-cols-2 gap-2 mb-3">
          <Input<NewBlockFilter>
            name="search"
            label="Search"
            onChange={() => setSearchText(getValues('search'))}
            registration={{register, errors}}
          />
          <ListBox<NewBlockFilter>
            name="category"
            options={BlockCategories}
            label="Category"
            setValue={setValue}
            getValues={getValues}
            onChange={() => setCurrentCategory(getValues('category'))}
          />
        </div>
        <div className="grid lg:grid-cols-5 md:grid-cols-3 gap-2">
          {EditorBlocks.filter(
            (x) =>
              currectCategory === BlockCategory.Any ||
              x.category === currectCategory,
          )
            .filter((x) =>
              x.label.toLowerCase().includes(searchText.toLowerCase()),
            )
            .sort((x, y) =>
              (x.priority || defaultPriority) < (y.priority || defaultPriority)
                ? -1
                : 1,
            )
            .map((x, index) => (
              <BlockTemplate
                key={index}
                label={x.label}
                image={x.image}
                onClick={() => {
                  setOpenPopup(false);
                  onSelected(x.template());
                }}
              />
            ))}
        </div>
      </div>
    </Popup>
  );
};

export default NewBlockPopup;
