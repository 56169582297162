import {useTranslation} from 'react-i18next';
import {useGetCourses} from '../../api/courses/Courses';
import CourseCard from '../../components/courses/CourseCard';

const CoursesPage: React.FC = () => {
  const {t, i18n} = useTranslation('simulationsPage');
  const simulations = useGetCourses(i18n.language);

  return (
    <>
      <section>
        <h1 className="my-10 font-bold text-6xl text-center uppercase text-indigo-900">
          {t('katalog-simulyacii')}
        </h1>
      </section>
      <section className="grid grid-cols-1 lg:grid-cols-2 px-6 max-w-screen-sm lg:max-w-screen-xl mx-auto gap-8 my-6">
        {simulations.isSuccess &&
          simulations.data.map((element) => (
            <CourseCard data={element} key={element.id} />
          ))}
      </section>
    </>
  );
};

export default CoursesPage;
