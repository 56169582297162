import {useParams} from 'react-router-dom';
import {useGetAdminCourse} from '../../../api/courses/Courses';
import CourseEditor from '../../../components/admin/adminCourses/courseEditor/CourseEditor';
import LoadingEditorBlock from '../../../components/editor/elements/common/LoadingBlock';

const EditCourse: React.FC = () => {
  const {courseId} = useParams<{courseId: string}>();
  const fullBlock = useGetAdminCourse(courseId || '');

  if (!fullBlock.isSuccess || !courseId) return <LoadingEditorBlock />;
  return <CourseEditor courseData={fullBlock.data} courseId={courseId} />;
};

export default EditCourse;
