import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import Input from '../../../form/input/Input';
import ListBox, {ListBoxOption} from '../../../form/listbox/Listbox';
import BasicAdjustmentsEditor from '../../common/BasicAdjustmentsEditor';
import HiddenSettings from '../../common/HiddenSettings';
import {EditorElementProps} from '../../types/EditorBlock';
import {useUpdateEditorBlock} from '../../types/UseEditor';
import {HeaderType, EditorHeaderData} from './HeaderTypes';

const headerStyleOptions: ListBoxOption<HeaderType>[] = [
  {label: 'Normal', value: HeaderType.NORMAL},
  {label: 'Course header', value: HeaderType.COURSEHEADER},
];

const HeaderEditor: React.FC<EditorElementProps<EditorHeaderData>> = (
  originBlock,
) => {
  const setBlockMutation = useUpdateEditorBlock<EditorHeaderData>();

  const {
    register,
    getValues,
    setValue,
    watch,
    formState: {errors},
  } = useForm<EditorHeaderData>({
    mode: 'onSubmit',
    defaultValues: {
      ...originBlock.data,
    },
  });

  useEffect(() => {
    const subscription = watch((updatedData) => {
      setBlockMutation.mutate({
        ...originBlock,
        data: {
          ...originBlock.data,
          ...updatedData,
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [originBlock, setBlockMutation, watch]);

  return (
    <div className="text-2xl p-2 sm:p-5">
      <HiddenSettings>
        <div className="grid gap-2">
          <div>
            <ListBox
              name="style"
              options={headerStyleOptions}
              label="Heading size"
              setValue={setValue}
              getValues={getValues}
            />
          </div>
          <BasicAdjustmentsEditor
            getValues={getValues}
            setValue={setValue}
            registration={{register, errors}}
          />
        </div>
      </HiddenSettings>
      <Input
        label="Header"
        name="text"
        registration={{register, errors}}
        className="mt-3"
      />
    </div>
  );
};

export default HeaderEditor;
