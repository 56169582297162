import {v4 as uuidv4} from 'uuid';
import {
  ButtonEditorStyleType,
  EditorButtonData,
} from './elements/button/ButtonTypes';
import {EditorCarouselData} from './elements/carousel/CarouselTypes';
import {ElementAlignment} from './elements/common/CommonTypes';
import {BreakOnSize, EditorGridViewerData} from './elements/grid/GridType';
import {EditorHeaderData, HeaderType} from './elements/header/HeaderTypes';
import {
  EditorImageData,
  ImageFit,
  ImageQuality,
  ImageType,
} from './elements/image/ImageTypes';
import {EditorParagraphData} from './elements/paragraph/ParagraphType';
import {AbstractFieldAnswerData} from './elements/questions/abstractFieldAnswer/AbstractFieldAnswerTypes';
import {EditorCorrectOrderAnswerData} from './elements/questions/correctOrder/CorrectOrderAnswerTypes';
import {EditorMatchingAnswerData} from './elements/questions/matchingAnswer/MatchingAnswerTypes';
import {EditorMultiAnswersData} from './elements/questions/multiAnswers/MultiAnswersTypes';
import {EditorSingleAnswersData} from './elements/questions/singleAnswer/SingleAnswerTypes';
import {EditorSketchfabData} from './elements/sketchfab/SketchfabType';
import {EditorTableAnswersData} from './elements/questions/tableAnswer/TableAnswerTypes';
import {EditorTextAnswersData} from './elements/questions/textAnswer/TextAnswerTypes';
import {EditorVideoData} from './elements/video/VideoTypes';
import {
  BlockContainer,
  EditorBlock,
  EditorBlockData,
} from './types/EditorBlock';
import {EditorGameData} from './elements/game/GameTypes';

type TemplateBlockMeta<T extends EditorBlockData> = Partial<
  Omit<EditorBlock<T>, 'data'>
>;

type TemplateBlockData<T extends EditorBlockData> = Partial<T>;

export const GetHeaderBlockTemplate = (
  data?: TemplateBlockData<EditorHeaderData>,
  metadata?: TemplateBlockMeta<EditorHeaderData>,
): EditorBlock<EditorHeaderData> => {
  const HeaderBlockTemplate: EditorBlock<EditorHeaderData> = {
    id: uuidv4(),
    type: 'header',
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    container: BlockContainer.Default,
    order: 0,
    data: {
      majorVersion: 0,
      text: '',
      style: HeaderType.NORMAL,
      level: 1,
      elementAlignment: ElementAlignment.CENTER,
      ...data,
    },
    ...metadata,
  };

  return HeaderBlockTemplate;
};

export const GetParagraphBlockTemplate = (
  data?: TemplateBlockData<EditorParagraphData>,
  metadata?: TemplateBlockMeta<EditorParagraphData>,
): EditorBlock<EditorParagraphData> => {
  const block: EditorBlock<EditorParagraphData> = {
    id: uuidv4(),
    type: 'paragraph',
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    container: BlockContainer.Default,
    order: 0,
    data: {
      majorVersion: 0,
      paragraph: '',
      ...data,
    },
    ...metadata,
  };

  return block;
};

export const GetImageBlockTemplate = (
  data?: Partial<EditorBlock<EditorImageData>>,
): EditorBlock<EditorBlockData> => {
  const block: EditorBlock<EditorImageData> = {
    id: uuidv4(),
    type: 'image',
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    container: BlockContainer.Default,
    order: 0,
    data: {
      majorVersion: 0,
      imageId: '',
      quality: ImageQuality.MID,
      sizeType: ImageType.OriginalSize,
      imageFit: ImageFit.None,
    },
    ...data,
  };

  return block;
};

export const GetCarouselBlockTemplate = (
  data?: TemplateBlockData<EditorCarouselData>,
  metadata?: TemplateBlockMeta<EditorCarouselData>,
): EditorBlock<EditorCarouselData> => {
  const block: EditorBlock<EditorCarouselData> = {
    id: uuidv4(),
    type: 'carousel',
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    container: BlockContainer.Default,
    order: 0,
    data: {
      majorVersion: 0,
      images: [],
      quality: ImageQuality.MID,
      ...data,
    },
    ...metadata,
  };

  return block;
};

export const GetVideoBlockTemplate = (
  data?: TemplateBlockData<EditorVideoData>,
  metadata?: TemplateBlockMeta<EditorVideoData>,
): EditorBlock<EditorVideoData> => {
  const block: EditorBlock<EditorVideoData> = {
    id: uuidv4(),
    type: 'video',
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    container: BlockContainer.Default,
    order: 0,
    data: {
      majorVersion: 0,
      url: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
      ...data,
    },
    ...metadata,
  };

  return block;
};

export const GetButtonBlockTemplate = (): EditorBlock<EditorButtonData> => {
  const block: EditorBlock<EditorButtonData> = {
    id: uuidv4(),
    type: 'button',
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    container: BlockContainer.Default,
    order: 0,
    data: {
      majorVersion: 0,
      href: '',
      label: 'Button',
      buttonStyle: ButtonEditorStyleType.FILLED,
      openInNewTab: false,
    },
  };

  return block;
};

// export const GetIframeBlockTemplate = (): EditorBlock<EditorGameData> => {
//   const block: EditorBlock<EditorGameData> = {
//     id: uuidv4(),
//     type: 'game',
//     margin: {
//       top: '0',
//       right: '0',
//       bottom: '0',
//       left: '0',
//     },
//     container: BlockContainer.Default,
//     order: 0,
//     data: {
//       majorVersion: 0,
//       url: '',
//       format: GameFormat.FormatFull,
//     },
//   };

//   return block;
// };

export const GetGridTemplate = (
  id?: string,
  data?: TemplateBlockData<EditorGridViewerData>,
  metadata?: TemplateBlockMeta<EditorGridViewerData>,
): EditorBlock<EditorGridViewerData> => {
  const gridBlockTemplate: EditorBlock<EditorGridViewerData> = {
    id: id || uuidv4(),
    type: 'grid',
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    container: BlockContainer.Default,
    order: 2,
    data: {
      majorVersion: 0,
      columns: [
        {
          order: 0,
          inner: [],
        },
      ],
      theme: {
        color: '#000000',
        background: '#ffffff',
      },
      gap: '10px',
      breakOnSize: BreakOnSize.NEVER,
      padding: '0',
      ...data,
    },
    ...metadata,
  };

  return gridBlockTemplate;
};

// export const GetBlueGridTemplate = (): EditorBlock<EditorBlockData> => {
//   const gridBlockTemplate: EditorBlock<EditorGridViewerData> = {
//     id: uuidv4(),
//     type: 'grid',
//     margin: {
//       top: '0',
//       right: '0',
//       bottom: '0',
//       left: '0',
//     },
//     container: BlockContainer.Default,
//     order: 2,
//     data: {
//       majorVersion: 0,
//       columns: [
//         {
//           order: 0,
//           inner: [GetHeaderBlockTemplate(), GetParagraphBlockTemplate()],
//         },
//         {
//           order: 1,
//           inner: [GetHeaderBlockTemplate(), GetParagraphBlockTemplate()],
//         },
//       ],
//       theme: {
//         background: '#007aff',
//         color: '#ffffff',
//       },
//       padding: 10,
//     },
//   };

//   return gridBlockTemplate;
// };

export const GetSingleAnswerBlockTemplate =
  (): EditorBlock<EditorSingleAnswersData> => {
    const block: EditorBlock<EditorSingleAnswersData> = {
      id: uuidv4(),
      type: 'singleAnswer',
      container: BlockContainer.Default,
      margin: {
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
      },
      order: 1,
      data: {
        majorVersion: 0,
        answers: [
          {answer: 'Answer 1', id: '01'},
          {answer: 'Answer 2', id: '02'},
          {answer: 'Answer 3', id: '03'},
          {answer: 'Answer 4', id: '04'},
        ],
        correctAnswerId: '02',
      },
    };

    return block;
  };

export const GetMultiAnswersBlockTemplate =
  (): EditorBlock<EditorMultiAnswersData> => {
    const block: EditorBlock<EditorMultiAnswersData> = {
      id: uuidv4(),
      type: 'multiAnswers',
      container: BlockContainer.Default,
      margin: {
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
      },
      order: 1,
      data: {
        majorVersion: 0,
        answers: [
          {
            answer: 'Answer 1',
            id: '31asd4r',
            correct: true,
          },
          {answer: 'Answer 2', id: 'dfg52s', correct: true},
          {answer: 'Answer 3', id: 'zxctert', correct: false},
          {answer: 'Answer 4', id: 'c3w3cnhj', correct: false},
        ],
      },
    };

    return block;
  };

export const GetCorrectOrderBlockTemplate =
  (): EditorBlock<EditorCorrectOrderAnswerData> => {
    const block: EditorBlock<EditorCorrectOrderAnswerData> = {
      id: uuidv4(),
      type: 'correctOrderAnswer',
      container: BlockContainer.Default,
      margin: {
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
      },
      order: 1,
      data: {
        majorVersion: 0,
        answers: [
          {answer: 'Answer 1', id: '32d2dd3sa', order: 2, number: 1},
          {answer: 'Answer 2', id: '435fdf46', order: 5, number: 2},
          {answer: 'Answer 3', id: 'gdf64hyhf', order: 3, number: 3},
          {answer: 'Answer 4', id: 'sgd5jh889', order: 6, number: 4},
          {answer: 'Answer 5', id: '12dfght7', order: 4, number: 5},
          {answer: 'Answer 6', id: '768fghgfh6', order: 1, number: 6},
        ],
      },
    };

    return block;
  };

export const GetMatchingAnswerBlockTemplate =
  (): EditorBlock<EditorMatchingAnswerData> => {
    const block: EditorBlock<EditorMatchingAnswerData> = {
      id: uuidv4(),
      type: 'matchingAnswer',
      container: BlockContainer.Default,
      margin: {
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
      },
      order: 1,
      data: {
        majorVersion: 0,
        columns: [
          {
            title: 'Title',
            answers: [{answer: 'option', id: '34gdh'}],
          },
          {
            title: 'Title',
            answers: [{answer: 'answer', id: 'fsgdf324'}],
          },
        ],
        correctAnswersId: ['fsgdf324'],
      },
    };

    return block;
  };

export const GetAbstractFieldAnswerBlockTemplate =
  (): EditorBlock<AbstractFieldAnswerData> => {
    const block: EditorBlock<AbstractFieldAnswerData> = {
      id: uuidv4(),
      type: 'abstractFieldAnswer',
      container: BlockContainer.Default,
      margin: {
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
      },
      order: 1,
      data: {
        majorVersion: 0,
        question: {
          question: 'Question',
        },
        correctAnswer: {answer: 6, type: 'number'},
        isFullWidthAnswer: false,
      },
    };

    return block;
  };

export const GetTableAnswerBlockTemplate =
  (): EditorBlock<EditorTableAnswersData> => {
    const block: EditorBlock<EditorTableAnswersData> = {
      id: uuidv4(),
      type: 'tableAnswerFieldAnswer',
      container: BlockContainer.Default,
      margin: {
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
      },
      order: 1,
      data: {
        majorVersion: 0,
        table: [
          [
            {text: 'Title', type: 'text', id: '3f43f'},
            {text: 'Title', type: 'text', id: '6grh57'},
            {text: 'Title', type: 'text', id: '6grh57'},
          ],
          [
            {text: 'Select', type: 'dropDown', id: 'dgfvg43'},
            {text: 'Input', type: 'edit', id: '24ccsvsd'},
            {text: 'Text', type: 'text', id: '53srdfg7'},
          ],
          // [
          //   {text: 'Белок', type: 'text', id: 'vvesfghfh3'},
          //   {text: 'трипсин', type: 'dropDown', id: '53srdfg7'},
          //   {text: 'Аминокислоты', type: 'text', id: 'v5ybb67n3g'},
          // ],
          // [
          //   {text: 'трипсин', type: 'dropDown', id: 'vvesfghfh3'},
          //   {text: 'Амилаза и мальтаза', type: 'text', id: '53srdfg7'},
          //   {text: 'Глюкоза', type: 'text', id: 'v5ybb67n3g'},
          // ],
        ],
        listTerms: [
          {term: 'A', id: '4326dgy'},
          {term: 'B', id: '87fjcn7'},
        ],
      },
    };

    return block;
  };

export const GetTextFieldAnswerBlockTemplate =
  (): EditorBlock<EditorTextAnswersData> => {
    const block: EditorBlock<EditorTextAnswersData> = {
      id: uuidv4(),
      type: 'textAnswerFieldAnswer',
      container: BlockContainer.Default,
      margin: {
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
      },
      order: 1,
      data: {
        majorVersion: 0,
        content:
          'Ламаркизм - эволюционная концепция, основывающаяся на теории, выдвинутой в начале XIX века _ED_ в трактате "Философия зоологии". В широком смысле к ламаркистским относят различные эволюционные теории, возникшие в XIX - первой трети XX веков, в которых в качестве основной _DD_ силы эволюции рассматривается внутреннее стремление к _DD_. Как правило, большое значение в таких теориях придается и влиянию _DD_ органов на эволюционные судьбы организмов, посколько предполагается что последствия упражнения и неупражнения могут передаваться по _DD_.',
        listTerms: [
          {term: 'стабилизирующий', id: '1234326dgy'},
          {term: 'движущий', id: '8247fjcn7'},
          {term: 'наследство', id: '32sf27dfhg'},
          {term: 'упражнение', id: 'bc345vy757'},
          {term: 'прогресс', id: 'vxd6t457tv3'},
          {term: 'Ламарк', id: '234kfdg32jn87'},
          {term: 'Линней', id: 'd3gh52v123'},
          {term: 'Дарвин', id: '11xcgrvdfgh67584'},
        ],
        correctAnswers: {
          selects: ['', '', '', ''],
          divs: [''],
        },
      },
    };

    return block;
  };

export const GetCourseTableTemplate = (
  metadata?: TemplateBlockMeta<EditorGridViewerData>,
): EditorBlock<EditorBlockData> => {
  return GetGridTemplate(
    uuidv4(),
    {
      columns: [
        {
          order: 0,
          inner: [
            GetHeaderBlockTemplate(
              {
                text: 'Adjustable parameters and equipment',
                style: HeaderType.NORMAL,
                level: 1,
              },
              {order: 0},
            ),
            GetParagraphBlockTemplate(
              {
                paragraph:
                  '<ul><li>Oculus Quest 1\\2</li><li>Pico Neo 3</li></ul>',
              },
              {order: 1},
            ),
          ],
        },
        {
          order: 1,
          inner: [
            GetHeaderBlockTemplate({
              text: 'Learning objectives',
              style: HeaderType.NORMAL,
              level: 1,
            }),
            GetParagraphBlockTemplate({
              paragraph: '',
            }),
          ],
        },
      ],
      theme: {
        background: '#007aff',
        color: '#ffffff',
      },
      padding: '4rem',
      breakOnSize: BreakOnSize.MOBILE,
    },
    metadata,
  );
};

export const GetFullCourseTemplate = (): EditorBlock<EditorBlockData> => {
  return GetGridTemplate(uuidv4(), {
    columns: [
      {
        order: 0,
        inner: [
          GetHeaderBlockTemplate(
            {
              text: 'About the laboratory',
              style: HeaderType.COURSEHEADER,
              level: 1,
            },
            {order: 0},
          ),
          GetParagraphBlockTemplate(
            {
              paragraph: 'I am a description',
            },
            {order: 1},
          ),
          GetCourseTableTemplate({
            order: 2,
            margin: {
              top: '20px',
              bottom: '20px',
            },
          }),
          GetVideoBlockTemplate({}, {order: 3}),
          GetHeaderBlockTemplate(
            {
              text: 'Laboratory Screenshots',
              style: HeaderType.COURSEHEADER,
              level: 1,
            },
            {order: 4},
          ),
          GetCarouselBlockTemplate({}, {order: 5}),
        ],
      },
    ],
  });
};

export const GetSketchfabBlockTemplate =
  (): EditorBlock<EditorSketchfabData> => {
    const block: EditorBlock<EditorSketchfabData> = {
      id: uuidv4(),
      type: 'sketchfab',
      container: BlockContainer.Default,
      margin: {
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
      },
      order: 1,
      data: {
        sketchfabData: {
          defaultAnimation: '6fcfb72b7f954ef187862665a609d96c',
          autoplay: true,
          isShowBackground: true,
          id: '9fcbe10b-41f3-4d7f-86e5-68ab372ae3b1',
          modelId: '016a4616888e49da88f590ae675feda0',
          thumbnailImageId: 'f1ab756b-a84c-4a50-8c72-fb980b394c40',
          translation: {
            title: 'Глаз',
            description: 'Классный глаз',
            annotations: [
              {
                isShown: true,
                index: 0,
                label: 'Protein shell',
                body: 'This is me - extended info about the protein shell',
              },
              {
                isShown: true,
                index: 1,
                label: 'Сосудистая оболочка',
                body: null,
              },
              {
                isShown: true,
                index: 2,
                label: 'Сетчатка',
                body: null,
              },
              {
                isShown: true,
                index: 3,
                label: 'Хрусталик',
                body: null,
              },
              {
                isShown: false,
                index: 4,
                label: 'Стекловидное тело',
                body: null,
              },
              {
                isShown: true,
                index: 5,
                label: 'Глазодвигательные мышцы',
                body: null,
              },
              {
                isShown: true,
                index: 6,
                label: 'Зрительный нерв',
                body: null,
              },
            ],
            customAnnotations: [],
            languageId: '9d01a052-81ac-4419-adac-11baefc4aa5c',
          },
          animations: [
            {
              animationId: '6fcfb72b7f954ef187862665a609d96c',
              useCustomCameraPosition: false,
              cameraPosition: [
                -9.939911972655855, 2.225916144999892, 2.0572231877871934,
              ],
              cameraTarget: [
                0.21902575543994737, 1.4544311506292085, -0.2987974970687451,
              ],
              transitionDuration: 0,
              translation: {
                title: 'Части разлетаются',
                languageId: '9d01a052-81ac-4419-adac-11baefc4aa5c',
              },
            },
            {
              animationId: 'f73a9b67089a4e719fbcf80e2cf12282',
              useCustomCameraPosition: false,
              cameraPosition: [
                -9.939911972655855, 2.225916144999892, 2.0572231877871934,
              ],
              cameraTarget: [
                0.21902575543994737, 1.4544311506292085, -0.2987974970687451,
              ],
              transitionDuration: 0,
              translation: {
                title: 'Линза',
                languageId: '9d01a052-81ac-4419-adac-11baefc4aa5c',
              },
            },
          ],
        },
        majorVersion: 0,
      },
    };

    return block;
  };

export const GetGameBlockTemplate = (): EditorBlock<EditorGameData> => {
  const block: EditorBlock<EditorGameData> = {
    id: uuidv4(),
    type: 'game',
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    container: BlockContainer.Default,
    order: 0,
    data: {
      majorVersion: 0,
      url: 'https://phet.colorado.edu/sims/html/build-an-atom/1.6.0/build-an-atom_en.html',
    },
  };

  return block;
};
