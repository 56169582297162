import React, {useEffect, useState} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {useAuthGetToken} from '../../api/authorization/Authentification';
import AuthorizationPopup from '../../components/authorizationPopup/AuthorizationPopup';
import {useAuth} from '../../services/auth/AuthService';

const PrivateRoute: React.FC = () => {
  const navigate = useNavigate();

  const authContext = useAuth();

  const getToken = useAuthGetToken({
    onSuccess: (data) => {
      authContext.login(data);
    },
  });

  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    setIsOpened(!authContext.isAuthenticated);
  }, [authContext.isAuthenticated]);

  useEffect(() => {
    if (!authContext.isAuthenticated) navigate('/login?redirected');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isOpened && <AuthorizationPopup onAuth={getToken.mutate} />}
      <Outlet />
    </>
  );
};
export default PrivateRoute;
