import {useEffect} from 'react';
import {queryClient} from '../../app/App';
import ContentEditorWrapper from '../../components/editor/test/ContentEditorTestWrapper';
import {SubscribeToPersistStorage} from '../../components/editor/types/UseEditor';
import FormButton from '../../components/form/button/FormButton';

const TestEditor: React.FC = () => {
  useEffect(() => {
    SubscribeToPersistStorage();
  }, []);
  return (
    <div>
      <ContentEditorWrapper />
    </div>
  );
};

export default TestEditor;
