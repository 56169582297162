import React from 'react';
import {useForm} from 'react-hook-form';
import Button from '../../components/form/button/Button';
import FileInput from '../../components/form/fileInput/Fileinput';
import Input from '../../components/form/input/Input';
import ListBox, {ListBoxOption} from '../../components/form/listbox/Listbox';
import RadioButtonArray, {
  RadioButtonArrayOption,
} from '../../components/form/radioButton/RadioButtonArray';
import Checkbox from '../../components/form/switch/Checkbox';

type Inputs = {
  example: string;
  exampleRequired: string;
  password: string;
  fileTest: File[];
  category: BlockCategory;
  testToggle: boolean;
  testCheckbox: boolean;
  testAnswer: string;
};

enum BlockCategory {
  Any,
  Common,
  Pack3D,
  Premade,
}

const BlockCategories: ListBoxOption<BlockCategory>[] = [
  {label: 'Any', value: BlockCategory.Any},
  {label: 'Common', value: BlockCategory.Common},
  {label: '3D pack', value: BlockCategory.Pack3D},
  {label: 'Premade', value: BlockCategory.Premade},
];

const RadioButtonsCategories: RadioButtonArrayOption<string>[] = [
  {label: 'Answer 1', value: '1', guid: '3ec6c8ac-b593-4a7b-8a98-4cf9d43ba0a0'},
  {label: 'Answer 2', value: '2', guid: '8cec2e56-24ce-436d-8155-ea7085fb48cd'},
  {label: 'Answer 3', value: '3', guid: '504e0585-84e6-4729-90ac-4fc095d7d6d6'},
  {label: 'Answer 4', value: '4', guid: '2c1e694c-43ee-4185-9a43-e8c43305b679'},
];

const FormSample: React.FC = () => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<Inputs>({
    defaultValues: {
      testCheckbox: true,
      testAnswer: '2',
      category: BlockCategory.Pack3D,
      testToggle: true,
    },
  });

  const onSubmit = (data: Inputs) => console.log(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-3 w-full grid gap-3">
      <Input<Inputs>
        label="Email"
        registration={{register, errors}}
        name="example"
        options={{
          pattern: {
            value: /^\d+$/,
            message: 'Должно быть число',
          },
          maxLength: {
            value: 10,
            message: 'Максимум 10 знаков',
          },
          minLength: {
            value: 3,
            message: 'Минимум 1 знак',
          },
        }}
      />
      <Input<Inputs>
        label="Name"
        registration={{register, errors}}
        name="exampleRequired"
        options={{required: 'Test2 обязаателен'}}
      />
      <Input<Inputs>
        label="Password"
        type="password"
        registration={{register, errors}}
        name="password"
        options={{required: 'Password обязаателен'}}
      />
      <FileInput<Inputs>
        label="Image file"
        name="fileTest"
        registration={{register, errors}}
        onChange={(name, value) => setValue(name, value)}
        options={{required: 'Нужен хотя бы один файл'}}
        maxFiles={4}
      />
      <ListBox<Inputs>
        name="category"
        options={BlockCategories}
        label="Category"
        setValue={setValue}
        getValues={getValues}
      />
      <RadioButtonArray<Inputs>
        name="testAnswer"
        options={RadioButtonsCategories}
        label="Category"
        setValue={setValue}
        getValues={getValues}
      />
      <Checkbox
        name="testCheckbox"
        label="Согласен продать душу"
        setValue={setValue}
        getValues={getValues}
      />
      {/* <Toggle
        name="testToggle"
        label="Показывать комментарии"
        setValue={setValue}
        getValues={getValues}
      /> */}

      <Button type="submit">Submit</Button>
    </form>
  );
};

export default FormSample;
