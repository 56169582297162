import classNames from 'classnames';
import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {
  NotificationType,
  UniversalNotificationHandler,
} from '../../../../../services/globalNotification/universalNotificationHandler';
import Button from '../../../../form/button/Button';
import Input from '../../../../form/input/Input';
import {ViewerElementProps} from '../../../types/EditorBlock';
import BasicAdjustmentsContainer from '../../common/BasicAdjustmentsContainer';
import {AbstractFieldAnswerData} from './AbstractFieldAnswerTypes';

type SelectedAnswer = {selectedAnswer: string | number};

const AbstractFieldAnswerViewer: React.FC<
  ViewerElementProps<AbstractFieldAnswerData>
> = (originBlock) => {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<SelectedAnswer>();

  const [isAnswered, setIsAnswered] = useState(false);
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);

  useEffect(() => {
    setIsAnswered(false);
  }, [originBlock.data]);

  const onSubmit = useCallback(
    (data: SelectedAnswer) => {
      if (isAnswered) return;

      setIsAnswered(true);
      let isLocalCorrectAnswer = false;

      if (typeof data.selectedAnswer === 'number') {
        isLocalCorrectAnswer =
          data.selectedAnswer == originBlock.data.correctAnswer.answer;
      } else {
        isLocalCorrectAnswer =
          data.selectedAnswer.trim() == originBlock.data.correctAnswer.answer;
      }
      setIsCorrectAnswer(isLocalCorrectAnswer);

      UniversalNotificationHandler(
        isLocalCorrectAnswer ? 'Correct' : 'Wrong',
        `Correct answer: ${originBlock.data.correctAnswer.answer}`,
        isLocalCorrectAnswer
          ? NotificationType.success
          : NotificationType.danger,
      );
    },
    [isAnswered, originBlock.data.correctAnswer.answer],
  );

  return (
    <BasicAdjustmentsContainer
      data={originBlock.data}
      disableAutoBlockControl
      className="max-w-7xl mx-auto"
    >
      <div className="text-xl mb-3">{originBlock.data.question.question}</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="selectedAnswer"
          label="Ответ"
          className={classNames(
            originBlock.data.isFullWidthAnswer ? 'w-full' : 'w-fit',
          )}
          registration={{register, errors}}
          type={originBlock.data.correctAnswer.type}
          options={{
            required: 'Required',
            disabled: isAnswered,
          }}
        />

        <div className="mt-4">
          {!isAnswered ? (
            <Button type="submit">Answer</Button>
          ) : isCorrectAnswer ? (
            <Button className="bg-green-600" type="submit">
              Correct
            </Button>
          ) : (
            <Button className="bg-red-600" type="submit">
              Wrong
            </Button>
          )}
        </div>
      </form>
    </BasicAdjustmentsContainer>
  );
};

export default AbstractFieldAnswerViewer;
