import {EditorBlockData} from '../../types/EditorBlock';
import {DefaultStyleAdjustments} from '../common/CommonTypes';

export enum HeaderType {
  NORMAL,
  COURSEHEADER,
}

export interface EditorHeaderData
  extends EditorBlockData,
    DefaultStyleAdjustments {
  text: string;
  style: HeaderType;
  level: number;
}
