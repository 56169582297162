import classNames from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {BiTime} from 'react-icons/bi';
import {Link} from 'react-router-dom';
// import 'react-confirm-alert/src/react-confirm-alert.css';

import './CourseCard.css';
import {CourseDTO} from '../../api/courses/dto/CourseDTO';
import ServerImage from '../serverImage/ServerImage';

export interface CourseCardProps {
  data: CourseDTO;
  whiteTheme?: boolean;
}

const CourseCard: React.FC<CourseCardProps> = ({data, whiteTheme = false}) => {
  const commonTranslation = useTranslation(['common']);
  const {t} = useTranslation(['simulationsPage']);

  return (
    <Link to={`/simulations/${data.id}`} className="w-full relative">
      <div className="h-80 w-full relative">
        <ServerImage
          imageId={data.thumbnailImageId}
          className="w-full h-full object-cover"
          alt="simulation"
        />
        <div className="w-full h-full absolute filter-simulation top-0" />
        <div className="absolute px-4 w-full bottom-0 ">
          <div className="border-t border-solid border-white text-white py-2">
            <span className="mr-4 font-semibold text-lg">
              {commonTranslation.t(`CourseSubject.${data.subject}`)}
            </span>
            |
            <span className="ml-4 font-normal text-lg">
              {commonTranslation.t(`CourseSection.${data.section}`)}
            </span>
            <div className="flex items-center float-right">
              <BiTime />
              <span className="text-right ml-1.5">
                {data.duration} {t('minutes')}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <h2
          className={classNames(
            'mb-2 lg:mb-4 uppercase font-bold text-xl lg:text-3xl',
            whiteTheme ? 'text-white' : 'text-indigo-900',
          )}
        >
          {data.translation.name}
        </h2>
        <div
          className={classNames(
            'text-sm lg:text-lg mb-2 overflow-hidden simulations-description',
            whiteTheme ? 'text-white' : 'text-black',
          )}
        >
          {data.translation.description}
        </div>
        <span
          className={classNames(
            'font-bold text-lg',
            whiteTheme ? 'text-white' : 'text-blue-600',
          )}
        >
          {t('more')}...
        </span>
      </div>
    </Link>
  );
};

export default CourseCard;
