/* eslint-disable react/destructuring-assignment */
import React, {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {AiOutlineMinus} from 'react-icons/ai';
import ListBox from '../../../../form/listbox/Listbox';
import {EditorElementProps} from '../../../types/EditorBlock';
import {useUpdateEditorBlock} from '../../../types/UseEditor';
import LoadingEditorBlock from '../../common/LoadingBlock';
import {EditorSingleAnswersData} from './SingleAnswerTypes';
import {v4 as uuidv4} from 'uuid';
import HiddenSettings from '../../../common/HiddenSettings';
import BasicAdjustmentsEditor from '../../../common/BasicAdjustmentsEditor';
import Input from '../../../../form/input/Input';
import FormButton from '../../../../form/button/FormButton';

const SingleAnswerEditorBlock: React.FC<
  EditorElementProps<EditorSingleAnswersData>
> = (originBlock) => {
  const setBlockMutation = useUpdateEditorBlock<EditorSingleAnswersData>();

  const {
    register,
    setValue,
    watch,
    getValues,
    formState: {errors},
  } = useForm<EditorSingleAnswersData>({
    defaultValues: {...originBlock.data},
  });

  useEffect(() => {
    const subscription = watch((updatedData) => {
      setBlockMutation.mutate({
        ...originBlock,
        data: {
          ...originBlock.data,
          ...(updatedData as EditorSingleAnswersData),
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [originBlock, setBlockMutation, watch]);

  // ф-ия вызывается когда мы добавляем ответ
  const addNewAnswer = useCallback(() => {
    const newData = [...originBlock.data.answers];
    newData.push({answer: 'Ответ', id: uuidv4()});
    setValue('answers', newData);
  }, [originBlock.data.answers, setValue]);

  // ф-ия вызывается когда мы удаляем ответ
  const deleteAnswer = useCallback(
    (index: number) => {
      if (originBlock.data.answers.length < 2) return;
      const newData = [...originBlock.data.answers];
      newData.splice(index, 1);
      setValue('correctAnswerId', newData[0].id);
      setValue('answers', newData);
    },
    [setValue, originBlock.data.answers],
  );

  if (!originBlock) return <LoadingEditorBlock />;
  return (
    <div className="p-2 sm:p-5">
      <HiddenSettings>
        <div className="grid gap-2">
          <BasicAdjustmentsEditor
            getValues={getValues}
            setValue={setValue}
            registration={{register, errors}}
          />
          <FormButton
            onClick={() => {
              setBlockMutation.mutate({
                ...originBlock,
              });
            }}
          >
            Refresh live view
          </FormButton>
        </div>
      </HiddenSettings>
      <h2 className="text-2xl">Options:</h2>
      <div className="grid gap-2 mt-2">
        {originBlock.data.answers.map((answer, index) => {
          return (
            <div key={index} className="flex items-center gap-2">
              <Input
                name={`answers.${originBlock.data.answers.findIndex(
                  (q) => q.id === answer.id,
                )}.answer`}
                label="Answer"
                className=""
                registration={{register, errors}}
                type="text"
                options={{
                  required: 'Required',
                }}
              />
              <AiOutlineMinus
                className="w-5 h-5 p-0.5 cursor-pointer bg-red-400 text-white hover:bg-red-600 rounded-full"
                onClick={() => deleteAnswer(index)}
              />
            </div>
          );
        })}
      </div>

      <FormButton className="mt-2" onClick={addNewAnswer}>
        Add answer
      </FormButton>

      <div className="grid gap-2 items-center mt-4">
        <div className="text-xl">Correct answer:</div>
        {originBlock.data.answers.length > 0 && (
          <ListBox<EditorSingleAnswersData>
            name="correctAnswerId"
            options={originBlock.data.answers.map((answer) => {
              return {label: answer.answer, value: answer.id};
            })}
            getValues={getValues}
            setValue={setValue}
            style={{minWidth: '150px'}}
            isListUp
          />
        )}
      </div>
    </div>
  );
};

export default SingleAnswerEditorBlock;
