import classNames from 'classnames';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useParams} from 'react-router-dom';
import {useGetAdminCourse} from '../../../api/courses/Courses';
import {useGetAllLanguages} from '../../../api/localization/Localization';
import NarrowContent from '../../../components/commonStructure/NarrowContent';
import CoursePageHeader from '../../../components/coursePage/coursePageHeader/CoursePageHeader';
import LoadingEditorBlock from '../../../components/editor/elements/common/LoadingBlock';
import ViewerAutoblock from '../../../components/editor/service/autoBlocks/ViewerAutoblock';
import {SubscribeToPersistStorage} from '../../../components/editor/types/UseEditor';
import Button from '../../../components/form/button/Button';
import FormButton from '../../../components/form/button/FormButton';

const CoursesEditLivePreview: React.FC = () => {
  const {courseId} = useParams<{courseId: string}>();
  const course = useGetAdminCourse(courseId || '');
  const [currentLanguage, setCurrentLanguage] = useState<string>();
  const commonTranslation = useTranslation('common');

  const languages = useGetAllLanguages({
    onSuccess: (data) => {
      setCurrentLanguage(data[0].id);
    },
  });

  useEffect(() => {
    SubscribeToPersistStorage();
  }, []);

  if (!languages.isSuccess || !course.isSuccess) return <LoadingEditorBlock />;
  return (
    <>
      <div className="absolute top-20 left-5 flex gap-2 z-50">
        {languages.data.map((el) => (
          <div className="bg-white" key={el.id}>
            <FormButton
              onClick={() => setCurrentLanguage(el.id)}
              highlight={el.id == currentLanguage}
            >
              {el.name}
            </FormButton>
          </div>
        ))}
      </div>
      <CoursePageHeader
        subject={course.data.subject}
        device={course.data.section}
        duration={course.data.duration}
        title={
          course.data.translations.find((x) => x.languageId === currentLanguage)
            ?.name || ''
        }
        background={course.data.backgroundImageId}
      />
      <NarrowContent>
        {course.data.translations.map((el) => (
          <div
            className={classNames(el.languageId != currentLanguage && 'hidden')}
            key={el.bodyId}
          >
            <ViewerAutoblock blocks={[JSON.parse(el.body)]} />
            {el.bodyId}
          </div>
        ))}
      </NarrowContent>
      <div className="my-12">
        <Link to="/contacts">
          <Button className="mx-auto block mt-8">
            {commonTranslation.t('start')}
          </Button>
        </Link>
      </div>
    </>
  );
};

export default CoursesEditLivePreview;
