import React from 'react';
import classNames from 'classnames';

import {ViewerElementProps} from '../../types/EditorBlock';
import ViewerAutoblock from '../../service/autoBlocks/ViewerAutoblock';
import {BreakOnSize, EditorGridViewerData} from './GridType';

const GridViewer: React.FC<ViewerElementProps<EditorGridViewerData>> = (
  originBlock,
) => (
  <div
    className={classNames(
      originBlock.data.breakOnSize == BreakOnSize.NEVER && 'flex',
      originBlock.data.breakOnSize == BreakOnSize.TABLET && 'lg:flex',
      originBlock.data.breakOnSize == BreakOnSize.MOBILE && 'md:flex',
    )}
    style={{
      padding: originBlock.data.padding,
      color: originBlock.data.theme.color,
      background: originBlock.data.theme.background,
      gap: originBlock.data.gap,
    }}
  >
    {originBlock.data.columns.map((element, index) => (
      <div className="flex-1 grid" key={index} style={{order: element.order}}>
        <ViewerAutoblock blocks={element.inner} />
      </div>
    ))}
  </div>
);

export default GridViewer;
