import {RadioGroup} from '@headlessui/react';
import classNames from 'classnames';
import {IoIosRadioButtonOn, IoIosRadioButtonOff} from 'react-icons/io';
import {RadioButtonArrayOption} from './RadioButtonArray';

export interface RadioButtonElementProps<T> {
  option: RadioButtonArrayOption<T>;
  disabled?: boolean;
}
const RadioButtonElement: <T>(
  p: RadioButtonElementProps<T>,
) => React.ReactElement<RadioButtonElementProps<T>> = ({
  option,
  disabled = false,
}) => {
  return (
    <RadioGroup.Option key={option.guid} value={option}>
      {({checked}) => (
        <>
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center gap-2 cursor-pointer">
              <div
                className={classNames(
                  'shrink-0 h-5 w-5',
                  !disabled && 'hover:text-blue-600',
                  checked && disabled ? 'text-gray-600' : 'text-blue-600',
                )}
              >
                {checked ? (
                  <IoIosRadioButtonOn className="w-full h-full" />
                ) : (
                  <IoIosRadioButtonOff className="w-full h-full" />
                )}
              </div>
              <div>
                <RadioGroup.Label as="span">{option.label}</RadioGroup.Label>
              </div>
            </div>
          </div>
        </>
      )}
    </RadioGroup.Option>
  );
};

export default RadioButtonElement;
