import React from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as Logo} from '../../assets/logo/logo.svg';
import {FaFacebook, FaInstagram, FaYoutube} from 'react-icons/fa';
import {useTranslation} from 'react-i18next';

import './Footer.css';

const FooterCOM: React.FC = () => {
  const {t} = useTranslation('footer');

  return (
    <div className="w-full bg-gray-700 font-montserrat pt-10 lg:pt-20 pb-16 px-5 z-50 relative">
      <div className="max-w-screen-1120 w-full mx-auto px-2 lg:px-0">
        <Logo className="text-white h-8" />
        <hr className="mt-8 border border-solid border-white w-full" />
        <div className="flex justify-between mt-8 text-white flex-col md:flex-row">
          <div className="w-full md:w-4/6 flex flex-col justify-between">
            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/2">
                <p className="font-semibold">
                  <Link to="/contacts">{t('feedback')}</Link>
                </p>
                <p className="mt-4">
                  <a
                    href="mailto:support@xreadylab.com"
                    className="hover:underline text-lg"
                  >
                    support@xreadylab.com
                  </a>
                </p>
              </div>
              <div className="mt-4 md:mt-0 w-full md:w-1/2">
                <p className="font-semibold">
                  <Link to="/">{t('main')}</Link>
                </p>
                <p className="mt-4">
                  <Link to="/simulations">{t('simulations')}</Link>
                </p>
              </div>
            </div>
            <div className="mt-4 md:mt-8 flex justify-between w-2/3">
              <a
                href="https://www.facebook.com/Xready-Lab-102612148967240"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook size={30} className="cursor-pointer" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCdYX651n1KiGjHq_7W0CCeQ?view_as=subscriber"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube size={30} className="cursor-pointer" />
              </a>
              <a
                href="https://www.instagram.com/xreadylab/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram size={30} className="cursor-pointer" />
              </a>
            </div>
          </div>
          <div className="mt-4 md:mt-0 w-full md:w-2/6 hidden-on-mobile">
            <p className="mt-4 flex flex-col">
              <Link to="/policy">{t('confidentiality')}</Link>

              <Link to="/privacy_policy">Privacy Policy</Link>
              <Link to="/website_terms">Website Terms of Use</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterCOM;
