import React, {useCallback, useEffect} from 'react';
import {
  FaArrowAltCircleDown,
  FaArrowAltCircleUp,
  FaMinusCircle,
} from 'react-icons/fa';
import {EditorElementProps} from '../../../types/EditorBlock';
import {useUpdateEditorBlock} from '../../../types/UseEditor';
import LoadingEditorBlock from '../../common/LoadingBlock';
import {
  DataCorrectOrderAnswer,
  EditorCorrectOrderAnswerData,
} from './CorrectOrderAnswerTypes';
import {v4 as uuidv4} from 'uuid';
import {useForm} from 'react-hook-form';
import HiddenSettings from '../../../common/HiddenSettings';
import BasicAdjustmentsEditor from '../../../common/BasicAdjustmentsEditor';
import Input from '../../../../form/input/Input';
import FormButton from '../../../../form/button/FormButton';

const CorrectOrderAnswerEditorBlock: React.FC<
  EditorElementProps<EditorCorrectOrderAnswerData>
> = (originBlock) => {
  const setBlockMutation = useUpdateEditorBlock<EditorCorrectOrderAnswerData>();

  const {
    register,
    getValues,
    setValue,
    watch,
    formState: {errors},
  } = useForm<EditorCorrectOrderAnswerData>({
    defaultValues: {...originBlock.data},
  });

  useEffect(() => {
    const subscription = watch((updatedData) => {
      setBlockMutation.mutate({
        ...originBlock,
        data: {
          ...originBlock.data,
          ...(updatedData as EditorCorrectOrderAnswerData),
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [originBlock, setBlockMutation, watch]);

  // ф-ия вызывается когда мы добавляем ответ
  const addNewAnswer = useCallback(() => {
    const newData = [...originBlock.data.answers];
    newData.push({
      answer: 'Answer',
      id: uuidv4(),
      order: originBlock.data.answers.length + 1,
      number: originBlock.data.answers.length + 1,
    });
    setValue('answers', newData);
  }, [setValue, originBlock.data.answers]);

  // ф-ия вызывается когда мы удаляем ответ
  const deleteAnswer = useCallback(
    (answer: DataCorrectOrderAnswer) => {
      if (originBlock.data.answers.length < 2) return;
      const newData = [...originBlock.data.answers];
      const index = newData.findIndex((data) => data.id === answer.id);
      newData.splice(index, 1);
      // мы вырезали ответ, но также нужно не забыть всем ответам, которые имели order больше удаленного снизить его на 1, т.к. все они должны сместиться на один порядок вниз
      const correctNewData = newData.map((data) => {
        if (data.order > answer.order) {
          return {...data, order: data.order - 1};
        }
        return data;
      });
      setValue('answers', correctNewData);
    },
    [setValue, originBlock.data.answers],
  );

  // ф-ия вызывается когда мы поднимаем вопрос
  const answerToUp = useCallback(
    (answer: DataCorrectOrderAnswer) => {
      const newData = [...originBlock.data.answers];

      // ищем индекс нажатого блока в массиве answers
      const temp = newData.findIndex((data) => data.id === answer.id);
      // т.к. мы поднимаем его вверх, то делаем ему order - 1
      newData[temp].order -= 1;
      // а тот что был выше, мы опускаем, соответственно, делаем order + 1
      const temp2 = newData.findIndex(
        (data) => data.order === newData[temp].order && data.id !== answer.id,
      );
      newData[temp2].order += 1;
      setValue('answers', newData);
    },
    [setValue, originBlock.data.answers],
  );

  // ф-ия вызывается когда мы опускаем ответ
  const answerToDown = useCallback(
    (answer: DataCorrectOrderAnswer) => {
      const newData = [...originBlock.data.answers];

      // ищем индекс нажатого блока в массиве answers
      const temp = newData.findIndex((data) => data.id === answer.id);
      // т.к. мы поднимаем его вверх, то делаем ему order - 1
      newData[temp].order += 1;
      // а тот что был выше, мы опускаем, соответственно, делаем order + 1
      const temp2 = newData.findIndex(
        (data) => data.order === newData[temp].order && data.id !== answer.id,
      );
      newData[temp2].order -= 1;
      setValue('answers', newData);
    },
    [setValue, originBlock.data.answers],
  );

  if (!originBlock) return <LoadingEditorBlock />;
  return (
    <div className="text-2xl p-2 sm:p-5 max-w-7xl mx-auto">
      <HiddenSettings>
        <div className="grid gap-2">
          <BasicAdjustmentsEditor
            getValues={getValues}
            setValue={setValue}
            registration={{register, errors}}
          />
          <FormButton
            onClick={() => {
              setBlockMutation.mutate({
                ...originBlock,
              });
            }}
          >
            Refresh live view
          </FormButton>
        </div>
      </HiddenSettings>
      <div className="list-decimal text-base grid gap-2">
        {[...originBlock.data.answers]
          .sort((a, b) => {
            return a.order - b.order;
          })
          .map((answer, index) => {
            return (
              <div
                key={index}
                className="flex items-center gap-2 justify-between"
              >
                <Input
                  name={`answers.${originBlock.data.answers.findIndex(
                    (ans) => ans.id === answer.id,
                  )}.answer`}
                  label={`${index + 1})`}
                  className="flex-grow"
                  registration={{register, errors}}
                  type="text"
                />
                <div className="flex gap-2">
                  {index > 0 && (
                    <FaArrowAltCircleUp
                      className="w-5 h-5 p-0.5 cursor-pointer bg-blue-400 text-white hover:bg-blue-600 rounded-full"
                      onClick={() => answerToUp(answer)}
                    />
                  )}
                  {index < originBlock.data.answers.length - 1 && (
                    <FaArrowAltCircleDown
                      className="w-5 h-5 p-0.5 cursor-pointer bg-blue-400 text-white hover:bg-blue-600 rounded-full"
                      onClick={() => answerToDown(answer)}
                    />
                  )}
                  <FaMinusCircle
                    className="w-5 h-5 p-0.5 cursor-pointer bg-red-400 text-white hover:bg-red-600 rounded-full"
                    onClick={() => deleteAnswer(answer)}
                  />
                </div>
              </div>
            );
          })}
        <FormButton onClick={addNewAnswer} className="w-full">
          Add answer
        </FormButton>
      </div>
    </div>
  );
};

export default CorrectOrderAnswerEditorBlock;
