import React from 'react';
import {EditorCarouselData} from '../elements/carousel/CarouselTypes';
import {BreakOnSize, EditorGridViewerData} from '../elements/grid/GridType';
import {EditorHeaderData, HeaderType} from '../elements/header/HeaderTypes';
import {
  EditorImageData,
  ImageFit,
  ImageQuality,
  ImageType,
} from '../elements/image/ImageTypes';
import {EditorParagraphData} from '../elements/paragraph/ParagraphType';
import {EditorCorrectOrderAnswerData} from '../elements/questions/correctOrder/CorrectOrderAnswerTypes';
import {EditorMatchingAnswerData} from '../elements/questions/matchingAnswer/MatchingAnswerTypes';
import {AbstractFieldAnswerData} from '../elements/questions/abstractFieldAnswer/AbstractFieldAnswerTypes';
import {EditorMultiAnswersData} from '../elements/questions/multiAnswers/MultiAnswersTypes';
import {EditorSingleAnswersData} from '../elements/questions/singleAnswer/SingleAnswerTypes';
import {EditorSketchfabData} from '../elements/sketchfab/SketchfabType';
import ViewerAutoblock from '../service/autoBlocks/ViewerAutoblock';
import {EditorBlock, BlockContainer} from '../types/EditorBlock';
import {EditorTextAnswersData} from '../elements/questions/textAnswer/TextAnswerTypes';

const ContentEditorViewerWrapper: React.FC = () => {
  const block1: EditorBlock<EditorHeaderData> = {
    id: '1',
    container: BlockContainer.Default,
    type: 'header',
    margin: {},
    order: 0,
    data: {
      majorVersion: 0,
      text: 'Old text',
      level: 1,
      style: HeaderType.COURSEHEADER,
    },
  };

  const block2: EditorBlock<EditorCarouselData> = {
    id: 'carousel-qfqefqfqf-3excfvg',
    type: 'carousel',
    container: BlockContainer.Default,
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    order: 0,
    data: {
      majorVersion: 0,
      images: [
        'f1ab756b-a84c-4a50-8c72-fb980b394c40',
        '10e34896-1b26-4621-8c6a-f02fbd92ff26',
        '66330108-f43d-407b-a1b3-aa83b849b8a9',
      ],
      quality: ImageQuality.MID,
    },
  };
  const singleBlockHeader: EditorBlock<EditorHeaderData> = {
    id: 'singleBlockHeader',
    type: 'header',
    container: BlockContainer.Default,
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    order: 0,
    data: {
      majorVersion: 0,
      text: 'Одиночный выбор ответа из предложенных вариантов',
      style: HeaderType.COURSEHEADER,
      level: 3,
    },
  };

  const singleBlockParagraph: EditorBlock<EditorParagraphData> = {
    id: 'singleBlockParagraph',
    type: 'paragraph',
    container: BlockContainer.Default,
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    order: 0,
    data: {
      majorVersion: 0,
      paragraph:
        'Какая структура обозначена X на электронной микрофотографии клетки?',
    },
  };

  const singleAnswersBlock: EditorBlock<EditorSingleAnswersData> = {
    id: 'singleAnswersBlock',
    type: 'singleAnswer',
    container: BlockContainer.Default,
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    order: 0,
    data: {
      majorVersion: 0,
      answers: [
        {answer: 'Рибосома', id: '012442'},
        {answer: 'Лизосома', id: '0242435'},
        {answer: 'Митохондрия', id: '035345345'},
        {answer: 'Ядро', id: '04654654'},
      ],
      correctAnswerId: '0242435',
    },
  };

  const multiBlockHeader: EditorBlock<EditorHeaderData> = {
    id: 'multiBlockHeader',
    type: 'header',
    container: BlockContainer.Default,
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    order: 0,
    data: {
      majorVersion: 0,
      text: 'Множественный выбор ответа из предложенных вариантов',
      style: HeaderType.COURSEHEADER,
      level: 3,
    },
  };

  const multiBlockParagraph: EditorBlock<EditorParagraphData> = {
    id: 'multiBlockParagraph',
    type: 'paragraph',
    container: BlockContainer.Default,
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    order: 0,
    data: {
      majorVersion: 0,
      paragraph:
        'Выберите три верных ответа из шести и запишите цифры, под которыми они указаны. Для клеток грибов характерно наличие',
    },
  };

  const multiAnswersBlock: EditorBlock<EditorMultiAnswersData> = {
    id: 'multiAnswersBlock',
    type: 'multiAnswers',
    container: BlockContainer.Default,
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    order: 0,
    data: {
      majorVersion: 0,
      answers: [
        {answer: 'целлюлозной клеточной стенки', id: '31asd4r', correct: true},
        {answer: 'углевода - крахмала', id: 'dfg52s', correct: true},
        {answer: 'хлоропластов', id: 'zxctert', correct: false},
        {answer: 'хитиновой клеточной стенки', id: '7897ndv', correct: true},
        {answer: 'рибосом', id: '563vxge', correct: false},
        {answer: 'оформленного ядра', id: 'c3w3cnhj', correct: false},
      ],
    },
  };

  const correctOrderHeader: EditorBlock<EditorHeaderData> = {
    id: 'correctOrder',
    type: 'header',
    container: BlockContainer.Default,
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    order: 0,
    data: {
      majorVersion: 0,
      text: 'Расположить в правильной последовательности',
      style: HeaderType.COURSEHEADER,
      level: 3,
    },
  };

  const correcrOrderBlockParagraph: EditorBlock<EditorParagraphData> = {
    id: 'correcrOrderBlockParagraph',
    type: 'paragraph',
    container: BlockContainer.Default,
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    order: 1,
    data: {
      majorVersion: 0,
      paragraph:
        'Установите правильную последовательность стадий процесса эмбриогенеза человека. Запишите в таблицу соответствующую последовательность цифр.',
    },
  };

  const sketchfabFieldBlock: EditorBlock<EditorSketchfabData> = {
    id: 'sketchfabFieldBlockViewer',
    type: 'sketchfab',
    container: BlockContainer.Default,
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    order: 1,
    data: {
      sketchfabData: {
        defaultAnimation: '6fcfb72b7f954ef187862665a609d96c',
        autoplay: true,
        isShowBackground: false,
        id: '9fcbe10b-41f3-4d7f-86e5-68ab372ae3b1',
        modelId: '016a4616888e49da88f590ae675feda0',
        thumbnailImageId: 'f1ab756b-a84c-4a50-8c72-fb980b394c40',
        translation: {
          title: 'Глаз',
          description: 'Классный глаз',
          annotations: [
            {
              isShown: true,
              index: 0,
              label: 'Protein shell',
              body: 'This is me - extended info about the protein shell',
            },
            {
              isShown: true,
              index: 1,
              label: 'Сосудистая оболочка',
              body: null,
            },
            {
              isShown: true,
              index: 2,
              label: 'Сетчатка',
              body: null,
            },
            {
              isShown: true,
              index: 3,
              label: 'Хрусталик',
              body: null,
            },
            {
              isShown: false,
              index: 4,
              label: 'Стекловидное тело',
              body: null,
            },
            {
              isShown: true,
              index: 5,
              label: 'Глазодвигательные мышцы',
              body: null,
            },
            {
              isShown: true,
              index: 6,
              label: 'Зрительный нерв',
              body: null,
            },
          ],
          customAnnotations: [],
          languageId: '9d01a052-81ac-4419-adac-11baefc4aa5c',
        },
        animations: [
          {
            animationId: '6fcfb72b7f954ef187862665a609d96c',
            useCustomCameraPosition: false,
            cameraPosition: [
              -9.939911972655855, 2.225916144999892, 2.0572231877871934,
            ],
            cameraTarget: [
              0.21902575543994737, 1.4544311506292085, -0.2987974970687451,
            ],
            transitionDuration: 0,
            translation: {
              title: 'Части разлетаются',
              languageId: '9d01a052-81ac-4419-adac-11baefc4aa5c',
            },
          },
          {
            animationId: 'f73a9b67089a4e719fbcf80e2cf12282',
            useCustomCameraPosition: false,
            cameraPosition: [
              -9.939911972655855, 2.225916144999892, 2.0572231877871934,
            ],
            cameraTarget: [
              0.21902575543994737, 1.4544311506292085, -0.2987974970687451,
            ],
            transitionDuration: 0,
            translation: {
              title: 'Линза',
              languageId: '9d01a052-81ac-4419-adac-11baefc4aa5c',
            },
          },
        ],
      },
      majorVersion: 0,
    },
  };

  const matchingAnswersBlock: EditorBlock<EditorMatchingAnswerData> = {
    id: 'matchingAnswerBlock',
    type: 'matchingAnswer',
    container: BlockContainer.Default,
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    order: 0,
    data: {
      majorVersion: 0,
      columns: [
        {
          title: 'ПРИЗНАКИ ЭМБРИОГЕНЕЗА',
          answers: [
            {answer: 'начинается органогенез', id: '34gdh'},
            {answer: 'образуется в результате инвагинации', id: '57dghf'},
            {answer: 'однослойная стенка с полостью внутри', id: '32sdfx'},
            {answer: 'предшествует бластуле', id: 'f6j9k'},
            {answer: 'зародыш состоит из двух слоев клеток', id: 'bnv87'},
            {answer: 'образуется нервная пластинка', id: 'vb6333'},
          ],
        },
        {
          title: 'СТАДИИ ЭМБРИОГЕНЕЗА',
          answers: [
            {answer: '1', id: 'fsgdf324'},
            {answer: '2', id: 'gfh767'},
            {answer: '3', id: 'hjvt54'},
            {answer: '4', id: '6b6b8d'},
          ],
        },
      ],
      correctAnswersId: [
        'fsgdf324',
        'hjvt54',
        '6b6b8d',
        'gfh767',
        '6b6b8d',
        'hjvt54',
      ],
    },
  };

  const textAnswersBlock: EditorBlock<EditorTextAnswersData> = {
    id: 'textAnswerFieldAnswerBlock',
    type: 'textAnswerFieldAnswer',
    container: BlockContainer.Default,
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    order: 0,
    data: {
      majorVersion: 0,
      content:
        'Ламаркизм - эволюционная концепция, основывающаяся на теории, выдвинутой в начале XIX века _ED_ в трактате "Философия зоологии". В широком смысле к ламаркистским относят различные эволюционные теории, возникшие в XIX - первой трети XX веков, в которых в качестве основной _DD_ силы эволюции рассматривается внутреннее стремление к _DD_. Как правило, большое значение в таких теориях придается и влиянию _DD_ органов на эволюционные судьбы организмов, посколько предполагается что последствия упражнения и неупражнения могут передаваться по _DD_.',
      listTerms: [
        {term: 'стабилизирующий', id: '1234326dgy'},
        {term: 'движущий', id: '8247fjcn7'},
        {term: 'наследство', id: '32sf27dfhg'},
        {term: 'упражнение', id: 'bc345vy757'},
        {term: 'прогресс', id: 'vxd6t457tv3'},
        {term: 'Ламарк', id: '234kfdg32jn87'},
        {term: 'Линней', id: 'd3gh52v123'},
        {term: 'Дарвин', id: '11xcgrvdfgh67584'},
      ],
      correctAnswers: {
        selects: ['', '', '', ''],
        divs: [''],
      },
    },
  };

  const abstractFieldHeader: EditorBlock<EditorHeaderData> = {
    id: 'abstractFieldHeader',
    type: 'header',
    container: BlockContainer.Default,
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    order: 0,
    data: {
      majorVersion: 0,
      text: 'Написать короткий ответ текстом\\цифрами в какое-то абстрактное поле для ответа',
      style: HeaderType.COURSEHEADER,
      level: 3,
    },
  };

  const abstractFieldAnswerBlock: EditorBlock<AbstractFieldAnswerData> = {
    id: 'abstractFieldAnswerBlock',
    type: 'abstractFieldAnswer',
    container: BlockContainer.Default,
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    order: 1,
    data: {
      majorVersion: 0,
      question: {
        question:
          'Каким номером на рисунке обозначена часть эмбриона млекопитающего, через которую зародыш получает питание в процессе развития в матке?',
      },
      correctAnswer: {answer: 6, type: 'number'},
      isFullWidthAnswer: false,
    },
  };

  const AllBlocks: EditorBlock<EditorGridViewerData> = {
    id: 'AllBlock-base-orhwgbg4uiogb',
    type: 'grid',
    container: BlockContainer.Default,
    margin: {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
    order: 0,
    data: {
      majorVersion: 0,
      columns: [
        {
          order: 0,
          inner: [
            singleBlockHeader,
            singleBlockParagraph,
            singleAnswersBlock,
            // multiBlockHeader,
            // multiBlockParagraph,
            // multiAnswersBlock,
            // abstractFieldHeader,
            // abstractFieldAnswerBlock,
            // sketchfabFieldBlock,
            // matchingAnswersBlock,
            // textAnswersBlock,
            // abstractFieldHeader,
            // abstractFieldAnswerBlock,
          ],
        },
      ],
      theme: {
        color: '#000000',
        background: '#ffffff',
      },
      gap: '10px',
      breakOnSize: BreakOnSize.NEVER,
      padding: '0',
    },
  };

  return <ViewerAutoblock blocks={[AllBlocks]} />;
};

export default ContentEditorViewerWrapper;
