import React from 'react';
import {Outlet} from 'react-router-dom';
import FooterCOM from '../../../components/footer/Footer.com';
import FooterRU from '../../../components/footer/Footer.ru';
import HeaderCOM from '../../../components/header/Header.com';
import HeaderRU from '../../../components/header/Header.ru';
import {useLocalization} from '../../../services/flagProvider/FlagProvider';

const BasicLayout: React.FC = () => {
  const localization = useLocalization();
  return (
    <div className="flex flex-col justify-between h-screen font-montserrat">
      {localization.getCurrentLocale() === 'ru' ? <HeaderRU /> : <HeaderCOM />}
      <div className="w-full mt-24 pb-8 mb-auto max-w-screen-xl px-8 xl:px-0 mx-auto">
        <Outlet />
      </div>
      {localization.getCurrentLocale() === 'ru' ? <FooterRU /> : <FooterCOM />}
    </div>
  );
};

export default BasicLayout;
