import {useNavigate} from 'react-router-dom';
import FormButton from '../../../components/form/button/FormButton';
import {useAuth} from '../../../services/auth/AuthService';

const AdminMainPage: React.FC = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  return (
    <div className="grid gap-2 my-12">
      <div className="flex">
        <span className="text-2xl grow">Admin dashboard</span>
        <FormButton
          onClick={() => {
            auth.logout();
            navigate('/');
          }}
        >
          Logout
        </FormButton>
      </div>
      <hr className="mt-2 mb-5" />
      <FormButton
        className="w-full"
        onClick={() => navigate('/admin/simulations/')}
      >
        Simulations
      </FormButton>
      <FormButton
        className="w-full"
        onClick={() => navigate('/admin/custom_pages')}
      >
        Custom Pages
      </FormButton>
    </div>
  );
};

export default AdminMainPage;
