import React, {useCallback} from 'react';
import {AiFillDelete, AiFillEye, AiOutlineLoading} from 'react-icons/ai';
import {Link, useNavigate} from 'react-router-dom';
import {useGetFullCustomPageById} from '../../../../api/customPage/CustomPage';
import {useDeleteCustomPage} from '../../../../api/customPage/CustomPageMutations';
import {CustomPageDTO} from '../../../../api/customPage/dto/CustomPageDTO';
import {useConfirmationAlert} from '../../../../services/confirmationAlert/ConfirmationAlertService';

import './AdminCustomPageCard.scoped.css';

interface AdminCustomPageCardProps {
  data: CustomPageDTO;
  search?: string;
}

const AdminCustomPageCard: React.FC<AdminCustomPageCardProps> = ({
  data,
  search = '',
}) => {
  const navigation = useNavigate();

  const deleteCustomPage = useDeleteCustomPage();

  const confirmationAlert = useConfirmationAlert();

  const fullData = useGetFullCustomPageById(data.id);

  const onDeleteCustomPage = useCallback(
    (id: string) => {
      confirmationAlert.showAlert({
        message: `Are you sure you want to delete this custom page ${id}?`,
        onCancel: () => {},
        onConfirm: () => {
          deleteCustomPage.mutate(id);
        },
      });
    },
    [confirmationAlert, deleteCustomPage],
  );

  // ф-ия проверяет есть ли пересечения с поисковым словом в pageName, section или subsection
  const seaching = () => {
    const isPageName = fullData.data?.pageName
      .toLowerCase()
      .includes(search.toLowerCase());

    let isSection = false;
    let isSubsection = false;

    if (fullData.data?.section) {
      isSection = fullData.data?.section
        .toLowerCase()
        .includes(search.toLowerCase());
    }
    if (fullData.data?.subsection) {
      isSubsection = fullData.data?.subsection
        .toLowerCase()
        .includes(search.toLowerCase());
    }

    return isPageName || isSection || isSubsection;
  };

  return (
    <>
      {seaching() && (
        <div className="relative border shadow">
          <AiFillDelete
            size={25}
            className="absolute z-40 top-2 right-2 text-pink-600 hover:text-red-600 bg-white rounded-md hover:bg-blue-100 cursor-pointer"
            onClick={() => onDeleteCustomPage(data.id)}
          />
          <AiFillEye
            size={25}
            className="absolute z-40 top-2 left-2 text-pink-600 hover:text-red-600 bg-white rounded-md hover:bg-blue-100 cursor-pointer"
            onClick={() => navigation(`/admin/custom_pages/${data.id}`)}
          />
          <Link
            to={`/admin/custom_pages/edit/${data.id}`}
            className="w-full relative"
          >
            <div className="h-20 w-full relative bg-indigo-900 hover:bg-indigo-800 text-white flex justify-center items-center text-xl lg:text-3xl font-semibold">
              {deleteCustomPage.isLoading ? (
                <div className="flex w-full justify-center items-center">
                  <AiOutlineLoading
                    className="animate-spin font-semibold"
                    size={24}
                  />
                </div>
              ) : (
                fullData.data?.pageName
              )}
            </div>
            <div className="mt-4 px-4 min-h-14">
              <h3 className="mb-2 lg:mb-4 font-bold text-xl lg:text-3xl text-indigo-900 text-center">
                {fullData.data?.section && fullData.data?.section + '/'}
                {fullData.data?.subsection && fullData.data?.subsection + '/'}
                {fullData.data?.pageName && fullData.data?.pageName}
              </h3>
            </div>
          </Link>
        </div>
      )}
    </>
  );
};

export default AdminCustomPageCard;
