import React, {useState} from 'react';
import Collapsible from 'react-collapsible';
import {useTranslation} from 'react-i18next';

interface CollapsibleAreaProps extends React.HTMLProps<HTMLDivElement> {
  text: string;
  maxLength?: number;
}

const CollapsibleArea: React.FC<CollapsibleAreaProps> = ({
  text,
  children,
  maxLength = 300,
  ...rest
}) => {
  const [isColapsed, setIsColapsed] = useState(true);
  const {t} = useTranslation('common');

  return (
    <div className="pb-2 mb-2" {...rest}>
      <Collapsible
        trigger={
          <div className="text-lg">
            {isColapsed ? (
              <>
                <p className="text-lg w-full">{`${text.slice(
                  0,
                  maxLength,
                )} ...`}</p>
                <p className="w-full mt-3 font-semibold text-lg">
                  {t('more')}..
                </p>
                <div className="flex-grow" />
              </>
            ) : (
              <p className="font-semibold text-lg">{t('collapse')}</p>
            )}
          </div>
        }
        onOpen={() => setIsColapsed(false)}
        onClose={() => setIsColapsed(true)}
        transitionTime={200}
      >
        {children && <div className="mt-4 text-lg">{children}</div>}
      </Collapsible>
    </div>
  );
};

export default CollapsibleArea;
