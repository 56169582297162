import classNames from 'classnames';
import React, {useCallback, useEffect} from 'react';
import {AiOutlineMinus} from 'react-icons/ai';
import {allENAlphabet} from '../../../../../services/utils/alphabet';
import {EditorElementProps} from '../../../types/EditorBlock';
import {useUpdateEditorBlock} from '../../../types/UseEditor';
import LoadingEditorBlock from '../../common/LoadingBlock';
import {
  DataMatchingAnswersObj,
  EditorMatchingAnswerData,
} from './MatchingAnswerTypes';
import {v4 as uuidv4} from 'uuid';
import {useForm} from 'react-hook-form';
import HiddenSettings from '../../../common/HiddenSettings';
import BasicAdjustmentsEditor from '../../../common/BasicAdjustmentsEditor';
import Input from '../../../../form/input/Input';
import FormButton from '../../../../form/button/FormButton';

const MatchingAnswerEditorBlock: React.FC<
  EditorElementProps<EditorMatchingAnswerData>
> = (originBlock) => {
  const setBlockMutation = useUpdateEditorBlock<EditorMatchingAnswerData>();

  const {
    register,
    getValues,
    setValue,
    watch,
    formState: {errors},
  } = useForm<EditorMatchingAnswerData>({
    defaultValues: {...originBlock.data},
  });

  useEffect(() => {
    const subscription = watch((updatedData) => {
      setBlockMutation.mutate({
        ...originBlock,
        data: {
          ...originBlock.data,
          ...(updatedData as EditorMatchingAnswerData),
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [originBlock, setBlockMutation, watch]);

  // ф-ия вызывается когда мы меняем правильные ответ
  const onClickCorrectAnswers = useCallback(
    (answer: DataMatchingAnswersObj, indexColumn: number) => {
      const newCorrectAnswers = [...originBlock.data.correctAnswersId];
      newCorrectAnswers[indexColumn] = answer.id;
      setValue('correctAnswersId', newCorrectAnswers);
    },
    [setValue, originBlock.data.correctAnswersId],
  );

  // ф-ия вызывается когда мы добавляем вопрос
  const addNewAnswer = useCallback(
    (indexColumn: number) => {
      const newData = [...originBlock.data.columns];
      newData[indexColumn].answers.push({
        answer: 'Answer',
        id: uuidv4(),
      });
      setValue('columns', newData);
      if (indexColumn === 0) {
        const newCorrectAnswers = [...originBlock.data.correctAnswersId];
        newCorrectAnswers.push('');
        setValue('correctAnswersId', newCorrectAnswers);
      }
    },
    [setValue, originBlock.data.columns, originBlock.data.correctAnswersId],
  );

  // ф-ия вызывается когда мы удаляем вопрос
  const deleteAnswer = useCallback(
    (indexColumn: number, index: number) => {
      if (originBlock.data.columns[indexColumn].answers.length < 2) return;
      const newData = [...originBlock.data.columns];
      const newCorrectAnswers = [...originBlock.data.correctAnswersId];

      if (indexColumn === 0) {
        newData[indexColumn].answers.splice(index, 1);
        newCorrectAnswers.splice(index, 1);
        setValue('columns', newData);
        setValue('correctAnswersId', newCorrectAnswers);
      }

      if (indexColumn === 1) {
        newData[indexColumn].answers.splice(index, 1);
        setValue('columns', newData);
        setValue(
          'correctAnswersId',
          newCorrectAnswers.map(() => {
            return '';
          }),
        );
      }
    },
    [setValue, originBlock.data.columns, originBlock.data.correctAnswersId],
  );

  if (!originBlock) return <LoadingEditorBlock />;
  return (
    <div className="text-2xl p-2 sm:p-5">
      <HiddenSettings>
        <div className="grid gap-2">
          <BasicAdjustmentsEditor
            getValues={getValues}
            setValue={setValue}
            registration={{register, errors}}
          />
          <FormButton
            onClick={() => {
              setBlockMutation.mutate({
                ...originBlock,
              });
            }}
          >
            Refresh live view
          </FormButton>
        </div>
      </HiddenSettings>
      <div className="text-2xl flex justify-around max-w-7xl mx-auto">
        {originBlock.data.columns.map((column, indexColumn) => {
          return (
            <div key={indexColumn} className="p-2 w-full flex flex-col gap-2">
              <Input
                name={`columns.${indexColumn}.title`}
                label="Title"
                className="w-full"
                registration={{register, errors}}
                type="text"
              />
              {column.answers.map((answer, index) => {
                return (
                  <div
                    key={answer.id}
                    className="text-lg flex justify-between items-center gap-2"
                  >
                    <div className="w-full">
                      <Input
                        name={`columns.${indexColumn}.answers.${index}.answer`}
                        label={
                          indexColumn === 0
                            ? `${allENAlphabet[index]}) `
                            : `${index + 1}) `
                        }
                        className="w-full"
                        registration={{register, errors}}
                        type="text"
                      />
                    </div>
                    <div>
                      <AiOutlineMinus
                        className="w-5 h-5 p-0.5 cursor-pointer bg-red-400 text-white hover:bg-red-600 rounded-full"
                        onClick={() => deleteAnswer(indexColumn, index)}
                      />
                    </div>
                  </div>
                );
              })}
              <FormButton
                className="w-full"
                onClick={() => addNewAnswer(indexColumn)}
              >
                Add answer
              </FormButton>
            </div>
          );
        })}
      </div>
      <div className="text-2xl">
        <h2 className="my-2">Correct answers:</h2>
        <div className="flex gap-2 flex-col">
          {originBlock.data.columns.length > 0 &&
            originBlock.data.columns[0].answers.map(
              (answerLeftColumn, index) => {
                return (
                  <div className="flex gap-2 text-lg" key={answerLeftColumn.id}>
                    {allENAlphabet[index]}:
                    {originBlock.data.columns[1].answers.map(
                      (answerRightColumn, indexAnswer) => {
                        return (
                          <div
                            className={classNames(
                              'w-fit px-2 py-1 border border-solid border-blue-400 cursor-pointer',
                              originBlock?.data?.correctAnswersId[
                                index
                              ]?.includes(answerRightColumn.id)
                                ? 'bg-green-200'
                                : 'bg-red-200',
                            )}
                            key={indexAnswer}
                            onClick={() =>
                              onClickCorrectAnswers(answerRightColumn, index)
                            }
                            role="button"
                            tabIndex={0}
                            onKeyDown={() => {}}
                          >
                            {indexAnswer + 1}
                          </div>
                        );
                      },
                    )}
                  </div>
                );
              },
            )}
        </div>
      </div>
    </div>
  );
};

export default MatchingAnswerEditorBlock;
