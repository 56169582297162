/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import React, {useCallback, useEffect, useState} from 'react';
import {
  NotificationType,
  UniversalNotificationHandler,
} from '../../../../../services/globalNotification/universalNotificationHandler';
import {shuffleArray} from '../../../../../services/utils/shuffleArray';
import Button from '../../../../form/button/Button';
import {ViewerElementProps} from '../../../types/EditorBlock';
import LoadingEditorBlock from '../../common/LoadingBlock';
import {
  DataCorrectOrderAnswer,
  EditorCorrectOrderAnswerData,
} from './CorrectOrderAnswerTypes';
import {Reorder} from 'framer-motion';
import BasicAdjustmentsContainer from '../../common/BasicAdjustmentsContainer';

const CorrectOrderAnswerViewer: React.FC<
  ViewerElementProps<EditorCorrectOrderAnswerData>
> = (originBlock) => {
  const [localAnswers, setLocalAnswers] = useState<DataCorrectOrderAnswer[]>([
    {answer: 'Not Data', id: 'Not Data', order: 0, number: 0},
  ]);
  // дан ли ответ
  const [isAnswerDone, setIsAnswerDone] = useState(false);

  useEffect(() => {
    shuffleArray(originBlock.data.answers);

    setLocalAnswers([
      ...originBlock.data.answers.map((answer, index) => {
        return {...answer, number: index + 1};
      }),
    ]);
  }, [originBlock.data.answers]);

  useEffect(() => {
    setIsAnswerDone(false);
  }, [originBlock.data]);

  // Получаем строку из всех order'ов подряд в localAnswers, нужно для того, чтобы проверить идут ли они в порядке возрастания, если да, то мы в правильном порядке разместили все вопросы
  const getOrdersToString = useCallback(() => {
    return localAnswers.reduce((acc, answer) => acc + answer.order, '');
  }, [localAnswers]);

  // ф-ия срабатывает при отправке ответа
  const onSendAnswer = useCallback(() => {
    setIsAnswerDone(true);
    // сохраняем строкой последовательность order из locarAnswers, если она будет от меньшего к большему, то ответ верный
    const finalAnswer = getOrdersToString();

    UniversalNotificationHandler(
      finalAnswer === finalAnswer.split('').sort().join('')
        ? 'Correct'
        : 'Wrong',
      `Correct answer: ${[...localAnswers]
        .sort((a, b) => {
          return a.order - b.order;
        })
        .map((answer) => {
          return answer.answer;
        })
        .join(', ')}`,
      finalAnswer === finalAnswer.split('').sort().join('')
        ? NotificationType.success
        : NotificationType.danger,
    );
  }, [localAnswers]);

  if (!originBlock) return <LoadingEditorBlock />;
  return (
    <BasicAdjustmentsContainer data={originBlock.data} disableAutoBlockControl>
      <div className="text-center my-12 max-w-7xl mx-auto">
        <Reorder.Group
          as="ol"
          axis="y"
          className="pl-4 mb-4 list-none text-base"
          values={localAnswers}
          onReorder={(value) => {
            setLocalAnswers(value);
          }}
        >
          {localAnswers.map((item) => (
            <Reorder.Item
              key={item.id}
              value={item}
              dragListener={!isAnswerDone}
              className="flex items-center gap-2 mb-1 border cursor-pointer w-fit px-2"
              whileHover={{
                scale: 1.1,
                borderColor: 'rgb(96 165 250)',
                backgroundColor: 'rgb(239 246 255)',
                transition: {duration: 0.1},
              }}
              whileTap={{
                boxShadow: '2px 2px 4px rgba(0, 0, 0, .8)',
              }}
            >
              {item.number}
              {') '}
              <span className="w-fit px-2 py-1 cursor-pointer">
                {item.answer}
              </span>
            </Reorder.Item>
          ))}
        </Reorder.Group>
        {!isAnswerDone ? (
          <Button onClick={onSendAnswer} disabled={isAnswerDone}>
            Answer
          </Button>
        ) : getOrdersToString() ===
          getOrdersToString().split('').sort().join('') ? (
          <Button className="bg-green-600">Correct</Button>
        ) : (
          <Button className="bg-red-600">Wrong</Button>
        )}
      </div>
    </BasicAdjustmentsContainer>
  );
};

export default CorrectOrderAnswerViewer;
