import classNames from 'classnames';
import {useRef} from 'react';
import {BsArrowRight, BsArrowLeft} from 'react-icons/bs';

import SwiperCore, {Navigation, Pagination, Autoplay, A11y} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

interface ReviewBlockProps extends React.HTMLAttributes<HTMLDivElement> {
  slides: React.ReactNode[];
  hideNavigationOnMobile?: boolean;
}

SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

const ReviewBlock: React.FC<ReviewBlockProps> = ({
  className,
  slides,
  hideNavigationOnMobile = false,
  ...others
}) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <div
      className={classNames('w-full mx-auto relative flex gap-x-4', className)}
      {...others}
    >
      <div
        className={classNames(
          'w-11 h-11 rounded-full bg-gray-200 p-2 z-10 flex-none my-auto',
          hideNavigationOnMobile && 'hidden md:block',
        )}
        ref={navigationPrevRef}
        tabIndex={0}
        role="button"
        aria-label="Previous slide"
      >
        <BsArrowLeft className="text-pink-600" size={28} />
      </div>
      <Swiper
        autoHeight
        className="flex-grow"
        autoplay
        loop
        spaceBetween={20}
        slidesPerView={1}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
      >
        {slides.map((el, index) => (
          <SwiperSlide key={index}>{el}</SwiperSlide>
        ))}
      </Swiper>

      <div
        className={classNames(
          'w-11 h-11 rounded-full bg-gray-200 p-2 z-10 flex-none my-auto',
          hideNavigationOnMobile && 'hidden md:block',
        )}
        ref={navigationNextRef}
        tabIndex={0}
        role="button"
        aria-label="Next slide"
      >
        <BsArrowRight className="text-pink-600" size={28} />
      </div>
    </div>
  );
};

export default ReviewBlock;
