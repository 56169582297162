import React, {useCallback, useEffect, useState} from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import './jquery';

// KaTeX dependency
import 'katex/dist/katex.css';

// MathQuill dependency
// import './jquery';
// eslint-disable-next-line import/extensions
import 'mathquill/build/mathquill.js';
import 'mathquill/build/mathquill.css';

// mathquill4quill include
import 'mathquill4quill/mathquill4quill.css';
import {EditorElementProps} from '../../types/EditorBlock';
import {useUpdateEditorBlock} from '../../types/UseEditor';
import {EditorParagraphData} from './ParagraphType';
import HiddenSettings from '../../common/HiddenSettings';
import BasicAdjustmentsEditor from '../../common/BasicAdjustmentsEditor';
import {useForm} from 'react-hook-form';
import {DefaultStyleAdjustments} from '../common/CommonTypes';

const ParagrapEditor: React.FC<EditorElementProps<EditorParagraphData>> = (
  originBlock,
) => {
  const setBlockMutation = useUpdateEditorBlock<EditorParagraphData>();
  const [paragraphData, setParagraphData] = useState('');

  const {
    register,
    getValues,
    setValue,
    formState: {errors},
    watch,
  } = useForm<DefaultStyleAdjustments>({
    mode: 'onSubmit',
    defaultValues: {
      ...originBlock.data,
    },
  });

  useEffect(() => {
    const subscription = watch((updatedData) => {
      setBlockMutation.mutate({
        ...originBlock,
        data: {
          ...originBlock.data,
          ...updatedData,
          paragraph: paragraphData,
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [originBlock, paragraphData, setBlockMutation, watch]);

  const onChange = useCallback(
    (value: string) => {
      setBlockMutation.mutate({
        ...originBlock,
        data: {
          ...originBlock.data,
          paragraph: value,
        },
      });
    },
    [originBlock, setBlockMutation],
  );

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (isLoaded) return;
    setIsLoaded(true);
    setParagraphData(originBlock.data.paragraph);
  }, [originBlock, isLoaded]);

  return (
    <div className="text-2xl p-2 sm:p-5">
      <HiddenSettings>
        <div className="grid gap-2">
          <BasicAdjustmentsEditor
            getValues={getValues}
            setValue={setValue}
            registration={{register, errors}}
          />
        </div>
      </HiddenSettings>
      <ReactQuill
        id={originBlock.id}
        theme="snow"
        modules={{
          formula: true,
          toolbar: [
            ['video', 'bold', 'italic', 'underline', 'formula'],
            [{list: 'ordered'}, {list: 'bullet'}],
          ],
        }}
        value={paragraphData}
        onChange={(val) => {
          setParagraphData(val);
          onChange(val);
        }}
      />
    </div>
  );
};

export default ParagrapEditor;
