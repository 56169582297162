import classNames from 'classnames';
import _ from 'lodash';
import React, {useCallback, useEffect} from 'react';
import {FaEdit, FaEye, FaMinusCircle, FaPlusCircle} from 'react-icons/fa';
import {MdArrowDropDown} from 'react-icons/md';
import HiddenSettings from '../../../common/HiddenSettings';
import {EditorElementProps} from '../../../types/EditorBlock';
import {useUpdateEditorBlock} from '../../../types/UseEditor';
import LoadingEditorBlock from '../../common/LoadingBlock';
import {EditorTableAnswersData} from './TableAnswerTypes';
import {v4 as uuidv4} from 'uuid';
import {useForm} from 'react-hook-form';
import BasicAdjustmentsEditor from '../../../common/BasicAdjustmentsEditor';
import Input from '../../../../form/input/Input';
import ListBox from '../../../../form/listbox/Listbox';
import FormButton from '../../../../form/button/FormButton';

const TableAnswerEditorBlock: React.FC<
  EditorElementProps<EditorTableAnswersData>
> = (originBlock) => {
  const setBlockMutation = useUpdateEditorBlock<EditorTableAnswersData>();

  const {
    register,
    getValues,
    setValue,
    watch,
    formState: {errors},
  } = useForm<EditorTableAnswersData>({
    defaultValues: {...originBlock.data},
  });

  useEffect(() => {
    const subscription = watch((updatedData) => {
      setBlockMutation.mutate({
        ...originBlock,
        data: {
          ...originBlock.data,
          ...(updatedData as EditorTableAnswersData),
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [originBlock, setBlockMutation, watch]);

  // ф-ия вызывается когда мы удаляем строку в таблице
  const deleteRow = useCallback(
    (indexTR: number) => {
      if (originBlock.data.table.length < 3) return;
      const newData = [...originBlock.data.table];
      newData.splice(indexTR, 1);
      setValue('table', newData);
    },
    [setValue, originBlock.data.table],
  );

  // ф-ия вызывается когда мы добавляем строку в таблице
  const addRow = useCallback(
    (indexTR: number) => {
      const newData = [...originBlock.data.table];
      const newTR = _.cloneDeep(newData[indexTR]);
      newData.splice(
        indexTR + 1,
        0,
        newTR.map((td) => {
          return {...td, text: 'text', type: 'text'};
        }),
      );
      setValue('table', newData);
    },
    [setValue, originBlock.data.table],
  );

  // ф-ия вызывается когда мы удаляем термин
  const deleteTerm = useCallback(
    (index: number) => {
      if (originBlock.data.listTerms.length < 2) return;
      const newTerms = [...originBlock.data.listTerms];
      const removedTerm = newTerms.splice(index, 1);
      setValue('listTerms', newTerms);
      // в originBlock.data.table тоже нужно менять данные, т.к. даже уже удаленные термины все еще сохранены там
      const newDataTable = [...originBlock.data.table];
      // пробегаемся по всему массиву таблицы и ищем где выкидные списки и где текущее значение совпадает с удаленным (removedTerm), там значения перезаписываем на первое в списке терминов
      setValue(
        'table',
        newDataTable.map((tr) => {
          return tr.map((td) => {
            if (td.type === 'dropDown' && td.text === removedTerm[0].term) {
              return {...td, text: newTerms[0].term};
            }
            return td;
          });
        }),
      );
    },
    [setValue, originBlock.data.listTerms, originBlock.data.table],
  );

  // ф-ия вызывается когда мы добавляем термин
  const addTerm = useCallback(() => {
    const newTerms = [...originBlock.data.listTerms];
    newTerms.push({term: 'text', id: uuidv4()});
    setValue('listTerms', newTerms);
  }, [setValue, originBlock.data.listTerms]);

  // ф-ия вызывается когда мы добавляем колонку в таблице
  const addColumn = useCallback(
    (indexTD: number) => {
      const newData = [...originBlock.data.table];
      newData.forEach((data) => {
        data.splice(indexTD + 1, 0, {
          text: 'text',
          type: 'text',
          id: uuidv4(),
        });
      });
      setValue('table', newData);
    },
    [setValue, originBlock.data.table],
  );

  // ф-ия вызывается когда мы удаляем колонку в таблице
  const deleteColumn = useCallback(
    (indexTD: number) => {
      if (originBlock.data.table[0].length < 2) return;
      const newData = [...originBlock.data.table];
      newData.forEach((data) => {
        data.splice(indexTD, 1);
      });
      setValue('table', newData);
    },
    [setValue, originBlock.data.table],
  );

  // ф-ия вызывается когда мы делаем ячейку в таблице типом "выкидной список"
  const changeBlockToDropDown = useCallback(
    (indexTR: number, indexTD: number) => {
      const newData = [...originBlock.data.table];
      newData[indexTR][indexTD].text = originBlock.data.listTerms[0].term;
      newData[indexTR][indexTD].type = 'dropDown';
      setValue('table', newData);
    },
    [originBlock.data.table, originBlock.data.listTerms, setValue],
  );

  // ф-ия вызывается когда мы делаем ячейку в таблице типом "текст"
  const changeBlockToText = useCallback(
    (indexTR: number, indexTD: number) => {
      const newData = [...originBlock.data.table];
      newData[indexTR][indexTD].text = 'text';
      newData[indexTR][indexTD].type = 'text';
      setValue('table', newData);
    },
    [originBlock.data.table, setValue],
  );

  // ф-ия вызывается когда мы делаем ячейку в таблице типом "редактируемый текст"
  const changeBlockToEdit = useCallback(
    (indexTR: number, indexTD: number) => {
      const newData = [...originBlock.data.table];
      newData[indexTR][indexTD].text = 'Answer';
      newData[indexTR][indexTD].type = 'edit';
      setValue('table', newData);
    },
    [originBlock.data.table, setValue],
  );

  if (!originBlock) return <LoadingEditorBlock />;
  return (
    <div className="p-2 sm:p-5 w-full">
      <HiddenSettings>
        <div className="grid gap-2">
          <BasicAdjustmentsEditor
            getValues={getValues}
            setValue={setValue}
            registration={{register, errors}}
          />
          <FormButton
            onClick={() => {
              setBlockMutation.mutate({
                ...originBlock,
              });
            }}
          >
            Refresh live view
          </FormButton>
        </div>
        <div className="mt-4 grid gap-2">
          <div>
            <FaEye className="inline-block w-5 h-5 p-0.5 cursor-pointer bg-blue-400 text-white hover:bg-blue-600" />
            <p className="ml-2 inline">
              - Visual text mode, student will not be able to edit this field
            </p>
          </div>
          <div>
            <MdArrowDropDown className="inline-block w-5 h-5 p-0.5 cursor-pointer bg-blue-400 text-white hover:bg-blue-600" />
            <p className="ml-2 inline">
              - Drop-list mode, student chooses an option from the proposed
              terms and concepts, the correct answer is indicated here
            </p>
          </div>
          <div>
            <FaEdit className="inline-block w-5 h-5 p-0.5 cursor-pointer bg-blue-400 text-white hover:bg-blue-600 " />
            <p className="ml-2 inline">
              - Editable text mode, student will be able to change this field,
              the correct answer is indicated here, for the student this field
              will be empty
            </p>
          </div>
        </div>
      </HiddenSettings>
      <table className="w-full border text-center border-separate border-spacing-2">
        <tbody>
          {originBlock.data.table.map((tr, indexTr) => {
            return (
              <tr
                key={indexTr}
                className={classNames(
                  'border text-base',
                  indexTr === 0 && 'font-bold bg-gray-50',
                )}
              >
                {tr.map((td, indexTd) => {
                  return (
                    <td
                      key={td.id}
                      className={classNames(indexTd === 0 && 'py-1')}
                    >
                      <div
                        className={classNames(
                          indexTr > 0 &&
                            'flex justify-between items-center gap-1',
                        )}
                      >
                        {td.type === 'dropDown' ? (
                          <ListBox
                            name={`table.${indexTr}.${indexTd}.text`}
                            label="Dropdown"
                            className="w-full"
                            options={originBlock.data.listTerms.map((list) => ({
                              value: list.term,
                              label: list.term,
                            }))}
                            setValue={setValue}
                            getValues={getValues}
                          />
                        ) : (
                          <Input
                            name={`table.${indexTr}.${indexTd}.text`}
                            label={
                              indexTr === 0
                                ? 'Title'
                                : originBlock.data.table[indexTr][indexTd]
                                    .type === 'text'
                                ? 'Text block'
                                : 'Editable block'
                            }
                            className="w-full"
                            registration={{register, errors}}
                            type="text"
                          />
                        )}
                        {indexTr > 0 &&
                          originBlock.data.table[indexTr][indexTd].type ===
                            'text' && (
                            <FaEye
                              className="w-5 h-5 p-0.5 cursor-pointer bg-blue-400 text-white hover:bg-blue-600"
                              onClick={() =>
                                changeBlockToDropDown(indexTr, indexTd)
                              }
                            />
                          )}
                        {indexTr > 0 &&
                          originBlock.data.table[indexTr][indexTd].type ===
                            'dropDown' && (
                            <MdArrowDropDown
                              className="w-5 h-5 p-0.5 cursor-pointer bg-blue-400 text-white hover:bg-blue-600"
                              onClick={() =>
                                changeBlockToEdit(indexTr, indexTd)
                              }
                            />
                          )}
                        {indexTr > 0 &&
                          originBlock.data.table[indexTr][indexTd].type ===
                            'edit' && (
                            <FaEdit
                              className="w-5 h-5 p-0.5 cursor-pointer bg-blue-400 text-white hover:bg-blue-600"
                              onClick={() =>
                                changeBlockToText(indexTr, indexTd)
                              }
                            />
                          )}
                      </div>
                    </td>
                  );
                })}
                {indexTr > 0 && indexTr !== originBlock.data.table.length && (
                  <td className="w-4">
                    <FaPlusCircle
                      className="w-5 h-5 p-0.5 cursor-pointer bg-blue-400 text-white hover:bg-blue-600 rounded-full"
                      onClick={() => addRow(indexTr)}
                    />
                    {originBlock.data.table.length > 2 && (
                      <FaMinusCircle
                        className="w-5 h-5 p-0.5 cursor-pointer bg-red-400 text-white hover:bg-red-600 rounded-full"
                        onClick={() => deleteRow(indexTr)}
                      />
                    )}
                  </td>
                )}
              </tr>
            );
          })}
          <tr>
            {originBlock.data.table.length > 0 &&
              originBlock.data.table[0].map((td, indexTD) => {
                return (
                  <td className="border px-2 py-1" key={indexTD}>
                    <div className="flex justify-center gap-2">
                      <FaPlusCircle
                        className="w-5 h-5 p-0.5 cursor-pointer bg-blue-400 text-white hover:bg-blue-600 rounded-full"
                        onClick={() => addColumn(indexTD)}
                      />
                      {originBlock.data.table[0].length > 1 && (
                        <FaMinusCircle
                          className="w-5 h-5 p-0.5 cursor-pointer bg-red-400 text-white hover:bg-red-600 rounded-full"
                          onClick={() => deleteColumn(indexTD)}
                        />
                      )}
                    </div>
                  </td>
                );
              })}
          </tr>
        </tbody>
      </table>
      <h2 className="my-2 text-2xl">List of terms and concepts:</h2>
      <div className="grid gap-2">
        {originBlock.data.listTerms.map((term, index) => {
          return (
            <div
              key={term.id}
              className="w-full flex justify-between items-center gap-2"
            >
              <Input
                name={`listTerms.${index}.term`}
                className="w-full"
                registration={{register, errors}}
                type="text"
              />
              <FaMinusCircle
                className="w-5 h-5 p-0.5 cursor-pointer bg-red-400 text-white hover:bg-red-600 rounded-full"
                onClick={() => deleteTerm(index)}
              />
            </div>
          );
        })}
        <FormButton className="w-full" onClick={addTerm}>
          Add term
        </FormButton>
      </div>
    </div>
  );
};

export default TableAnswerEditorBlock;
