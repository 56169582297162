import classNames from 'classnames';
import React, {useCallback, useEffect, useState} from 'react';
import {BiCheckbox, BiCheckboxChecked} from 'react-icons/bi';
import {
  NotificationType,
  UniversalNotificationHandler,
} from '../../../../../services/globalNotification/universalNotificationHandler';
import {shuffleArray} from '../../../../../services/utils/shuffleArray';
import Button from '../../../../form/button/Button';
import {ViewerElementProps} from '../../../types/EditorBlock';
import LoadingEditorBlock from '../../common/LoadingBlock';
import {DataMultiAnswers, EditorMultiAnswersData} from './MultiAnswersTypes';
import BasicAdjustmentsContainer from '../../common/BasicAdjustmentsContainer';

const MultiAnswerViewer: React.FC<
  ViewerElementProps<EditorMultiAnswersData>
> = (originBlock) => {
  // выбранные ответы
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);
  // дан ли ответ
  const [isAnswerDone, setIsAnswerDone] = useState(false);

  useEffect(() => {
    shuffleArray(originBlock.data.answers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsAnswerDone(false);
  }, [originBlock.data]);

  // ф-ия вызывается когда мы меняем правильные ответ
  const onClickSelectedAnswers = useCallback(
    (answer: DataMultiAnswers) => {
      const newData = [...selectedAnswers];
      if (isAnswerDone) return;
      // логика простая: если в массиве правильных ответов еще нет такого, то добавляем, если уже есть, то ищем его индекс и с помощью splice удаляем из массива прав. ответов
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      newData.includes(answer.id)
        ? newData.splice(
            newData.findIndex((id) => {
              return id === answer.id;
            }),
            1,
          )
        : newData.push(answer.id);
      newData.sort();
      setSelectedAnswers(newData);
    },
    [isAnswerDone, selectedAnswers],
  );

  // ф-ия проверяет равен ли массив правильных id ответов из localAnswers массиву id ответов из selectedAnswers
  const isCorrectAnswers = useCallback(() => {
    const correctArr = originBlock.data.answers
      .filter((answer) => answer.correct)
      .map((answer) => {
        return answer.id;
      })
      .sort();
    return (
      selectedAnswers.toString().toLowerCase().trim() ===
      correctArr.toString().toLowerCase().trim()
    );
  }, [selectedAnswers, originBlock.data.answers]);

  // ф-ия срабатывает при отправке ответа
  const onSendAnswer = useCallback(() => {
    if (selectedAnswers.length === 0) return;
    setIsAnswerDone(true);

    UniversalNotificationHandler(
      isCorrectAnswers() ? 'Correct' : 'Wrong',
      `Correct answer: ${originBlock.data.answers
        .filter((answer) => answer.correct)
        .map((answer) => answer.answer)}`,
      isCorrectAnswers() ? NotificationType.success : NotificationType.danger,
    );
  }, [selectedAnswers, originBlock.data.answers, isCorrectAnswers]);

  if (!originBlock) return <LoadingEditorBlock />;
  return (
    <BasicAdjustmentsContainer data={originBlock.data} disableAutoBlockControl>
      <div className="text-center my-12 max-w-7xl mx-auto">
        <ol className="pl-4 mb-4 list-decimal text-base">
          {originBlock.data.answers.map((answer) => {
            return (
              <li key={answer.id} className="flex items-center gap-2 mb-1">
                {selectedAnswers.includes(answer.id) ? (
                  <BiCheckboxChecked
                    size={24}
                    className={classNames(
                      isAnswerDone && answer.correct && 'text-green-500',
                      isAnswerDone && !answer.correct && 'text-red-500',
                    )}
                  />
                ) : (
                  <BiCheckbox size={24} className="text-red-500" />
                )}
                <span
                  className="hover:bg-blue-50 w-fit px-2 py-1 border hover:border-solid hover:border-blue-400 cursor-pointer"
                  onClick={
                    isAnswerDone
                      ? () => {}
                      : () => onClickSelectedAnswers(answer)
                  }
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {}}
                >
                  {answer.answer}
                </span>
              </li>
            );
          })}
        </ol>
        <div className="mt-4">
          {!isAnswerDone ? (
            <Button
              onClick={onSendAnswer}
              disabled={selectedAnswers.length === 0 || isAnswerDone}
            >
              Answer
            </Button>
          ) : isCorrectAnswers() ? (
            <Button className="bg-green-600">Correct</Button>
          ) : (
            <Button className="bg-red-600">Wrong</Button>
          )}
        </div>
      </div>
    </BasicAdjustmentsContainer>
  );
};

export default MultiAnswerViewer;
