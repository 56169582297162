import React from 'react';

export interface StandartParagraphProps {
  className?: string;
  children?: React.ReactNode;
}

const StandartParagraph: React.FC<StandartParagraphProps> = ({
  children,
  className,
}) => {
  return <p className={`text-lg mb-4 ${className}`}>{children}</p>;
};

export default StandartParagraph;
