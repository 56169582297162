import React from 'react';
import {SwiperSlide} from 'swiper/react';
import Carousel from '../../../carousel/Carousel';
import ServerImage from '../../../serverImage/ServerImage';
import {ViewerElementProps} from '../../types/EditorBlock';
import {EditorCarouselData} from './CarouselTypes';

const CarouselViewerBlock: React.FC<ViewerElementProps<EditorCarouselData>> = ({
  data,
}) => {
  return (
    <section
      className="simulation-screenshots mx-auto mt-0 mb-2 lg:mb-2"
      style={{maxWidth: data.maxWidth}}
    >
      {data.images.length > 0 && (
        <Carousel isPagination isArrow>
          {data.images.map((imageId) => (
            <SwiperSlide key={imageId} className="">
              <div className="w-full h-full">
                <div className="w-full mx-auto">
                  <ServerImage
                    imageId={imageId}
                    alt={`Carousel image ${imageId}`}
                    className="w-full h-full"
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Carousel>
      )}
    </section>
  );
};

export default CarouselViewerBlock;
