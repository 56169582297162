import React, {useCallback, useEffect, useState} from 'react';
import {
  NotificationType,
  UniversalNotificationHandler,
} from '../../../../../services/globalNotification/universalNotificationHandler';
import Button from '../../../../form/button/Button';
import RadioButtonArray from '../../../../form/radioButton/RadioButtonArray';
import {ViewerElementProps} from '../../../types/EditorBlock';
import LoadingEditorBlock from '../../common/LoadingBlock';
import {v4 as uuidv4} from 'uuid';
import {useForm} from 'react-hook-form';
import {EditorSingleAnswersData} from './SingleAnswerTypes';
import BasicAdjustmentsContainer from '../../common/BasicAdjustmentsContainer';

const SingleAnswerViewer: React.FC<
  ViewerElementProps<EditorSingleAnswersData>
> = (originBlock) => {
  // дан ли ответ
  const [isAnswerDone, setIsAnswerDone] = useState(false);

  useEffect(() => {
    setIsAnswerDone(false);
  }, [originBlock.data]);

  const {setValue, getValues} = useForm<EditorSingleAnswersData>({
    defaultValues: {correctAnswerId: originBlock.data.answers[0].id},
  });

  const GetRadioButtons = useCallback(() => {
    return [
      ...originBlock.data.answers.map((answer) => {
        return {
          label: answer.answer,
          value: answer.id,
          guid: uuidv4(),
        };
      }),
    ];
  }, [originBlock]);

  // ф-ия срабатывает при отправке ответа
  const onSendAnswer = useCallback(() => {
    if (getValues().correctAnswerId === '') return;
    setIsAnswerDone(true);
    UniversalNotificationHandler(
      getValues().correctAnswerId === originBlock.data.correctAnswerId
        ? 'Correct'
        : 'Wrong',
      `Correct answer: ${
        originBlock?.data.answers.filter((answer) => {
          return answer.id === originBlock.data.correctAnswerId;
        })[0].answer
      }`,
      getValues().correctAnswerId === originBlock.data.correctAnswerId
        ? NotificationType.success
        : NotificationType.danger,
    );
  }, [getValues, originBlock]);

  if (!originBlock) return <LoadingEditorBlock />;
  return (
    <BasicAdjustmentsContainer data={originBlock.data} disableAutoBlockControl>
      <div className="text-center my-12 max-w-7xl mx-auto">
        <RadioButtonArray
          name="correctAnswerId"
          options={GetRadioButtons()}
          label="Answer"
          setValue={setValue}
          getValues={getValues}
          disabled={isAnswerDone}
        />
        {isAnswerDone &&
          (getValues().correctAnswerId === originBlock.data.correctAnswerId ? (
            <div className="text-green-700">Correct</div>
          ) : (
            <div className="text-red-700">Wrong</div>
          ))}
        <Button
          onClick={onSendAnswer}
          disabled={getValues().correctAnswerId === '' || isAnswerDone}
        >
          Answer
        </Button>
      </div>
    </BasicAdjustmentsContainer>
  );
};

export default SingleAnswerViewer;
