import React, {useEffect, useState} from 'react';
import _uniqueId from 'lodash/uniqueId';
import {Switch} from '@headlessui/react';
import classNames from 'classnames';
import {
  FieldValues,
  FieldPath,
  UseFormSetValue,
  UseFormGetValues,
} from 'react-hook-form';
import {MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank} from 'react-icons/md';

type CheckBoxOptions<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  name: TFieldName;
  label?: string;
  disabled?: boolean;
  setValue: UseFormSetValue<TFieldValues>;
  getValues: UseFormGetValues<TFieldValues>;
  onChange?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

const CheckBox: <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(
  p: CheckBoxOptions<T, TName>,
) => React.ReactElement<CheckBoxOptions<T, TName>> = ({
  name,
  label,
  setValue,
  getValues,
  disabled = false,
  className,
  onChange,
  ...rest
}) => {
  const [id] = useState(_uniqueId('toggle-'));
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    console.log('Fired');
    const value = getValues(name) as boolean;
    setEnabled(value);
  }, [getValues, name]);

  return (
    <div className={classNames('relative', className)} {...rest}>
      <Switch
        id={id}
        checked={enabled}
        onChange={(checked: boolean) => {
          setEnabled(checked);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setValue(name, checked as any);
          if (onChange) onChange();
        }}
        disabled={disabled}
      >
        <div className="flex w-full items-center justify-between">
          <div
            className={classNames(
              'flex items-center gap-2 cursor-pointer',
              disabled && 'text-gray-600',
            )}
          >
            <div className={classNames('h-5 w-5')}>
              {enabled ? (
                <MdOutlineCheckBox className="w-full h-full text-blue-600" />
              ) : (
                <MdOutlineCheckBoxOutlineBlank className="w-full h-full" />
              )}
            </div>
            <div>
              <span>{label}</span>
            </div>
          </div>
        </div>
      </Switch>
    </div>
  );
};

export default CheckBox;
