import {EditorBlock, EditorBlockData} from '../../types/EditorBlock';

export interface GridTheme {
  background: string;
  color: string;
}

export interface EditorGridViewerData extends EditorBlockData {
  columns: GridColumn[];
  theme: GridTheme;
  padding: string;

  breakOnSize: BreakOnSize;
  gap: string;
}

export interface GridColumn {
  order: number;
  inner: EditorBlock<EditorBlockData>[];
}

export enum BreakOnSize {
  NEVER,
  TABLET,
  MOBILE,
}
