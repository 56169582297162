import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {AiOutlineClose} from 'react-icons/ai';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {BasicElement} from '../../../../helpers/BasicElement';
import Button from '../../../form/button/Button';
import Input from '../../../form/input/Input';
import {BlockMargin} from '../../types/EditorBlock';

interface BlockMarginPopupProps extends BasicElement {
  margin: BlockMargin;
  onMarginSet: (margin: BlockMargin) => void;
}

const BlockMarginPopup: React.FC<BlockMarginPopupProps> = ({
  margin,
  onMarginSet,
  children,
}) => {
  const [openPopup, setOpenPopup] = useState(false);

  const onClosePopup = () => {
    setOpenPopup(false);
  };

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<BlockMargin>({
    mode: 'onSubmit',
    defaultValues: {
      top: margin.top,
      right: margin.right,
      bottom: margin.bottom,
      left: margin.left,
    },
  });

  const onSubmit = (data: BlockMargin) => {
    onMarginSet(data);
    onClosePopup();
  };

  return (
    <Popup
      trigger={<div className="flex justify-center">{children}</div>}
      contentStyle={{padding: '15px', borderRadius: '0.375rem', width: '40%'}}
      modal
      nested
      onClose={() => onClosePopup()}
      onOpen={() => setOpenPopup(true)}
      open={openPopup}
      closeOnDocumentClick={false}
    >
      <div className="absolute right-1 top-1 cursor-pointer">
        <AiOutlineClose onClick={() => onClosePopup()} />
      </div>

      <div className="grid gap-3 mt-3">
        <Input<BlockMargin>
          name="top"
          label="Top"
          registration={{register, errors}}
        />
        <Input<BlockMargin>
          name="right"
          label="Right"
          registration={{register, errors}}
        />
        <Input<BlockMargin>
          name="bottom"
          label="Bottom"
          registration={{register, errors}}
        />
        <Input<BlockMargin>
          name="left"
          label="Left"
          registration={{register, errors}}
        />
        <Button
          onClick={() => {
            handleSubmit(onSubmit)();
          }}
        >
          Ok
        </Button>
      </div>
    </Popup>
  );
};

export default BlockMarginPopup;
