import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import {
  AiOutlineAppstore,
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineSetting,
  AiOutlineStop,
} from 'react-icons/ai';
import {BasicElement} from '../../../../helpers/BasicElement';
import BlockMarginPopup from '../../common/blockMarginPopup/BlockMarginPopup';
import {BlockMargin} from '../../types/EditorBlock';

interface ContentEditorContainerProps extends BasicElement {
  elementIndex: number;
  currentOrder: number;
  margin: BlockMargin;
  maxIndex: number;
  isProhibitLastElementDelete?: boolean;
  onPositionChanged: (oldPosition: number, newPosition: number) => void;
  onDeleteAtIndex: (index: number) => void;
  onMarginChanged: (margin: BlockMargin) => void;
  isMasterBlock: boolean;
}
const ContentEditorContainer: React.FC<ContentEditorContainerProps> = ({
  elementIndex,
  currentOrder,
  margin,
  maxIndex,
  onPositionChanged,
  onDeleteAtIndex,
  onMarginChanged,
  children,
  isProhibitLastElementDelete = false,
  isMasterBlock = false,
}) => {
  const [isFirstElement, setFirstElement] = useState(currentOrder === 0);
  const [isLastElement, setLastElement] = useState(currentOrder === maxIndex);
  const [isProhibitDelete, setProhobitDelete] = useState(
    isProhibitLastElementDelete &&
      currentOrder === maxIndex &&
      currentOrder === 0,
  );
  useEffect(() => {
    setFirstElement(currentOrder === 0);
    setLastElement(currentOrder === maxIndex);
    setProhobitDelete(
      isProhibitLastElementDelete &&
        currentOrder === maxIndex &&
        currentOrder === 0,
    );
  }, [currentOrder, isProhibitLastElementDelete, maxIndex]);

  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const onMarginSet = (changedMargin: BlockMargin) => {
    onMarginChanged(changedMargin);
  };

  return (
    <div
      className={classNames(
        'flex relative',
        !isMasterBlock ? 'my-2 border border-gray-200' : '',
      )}
      // style={{order: element.order}}
    >
      <div className="flex-grow w-full z-10">{children}</div>
      <AiOutlineAppstore
        className="w-5 h-5 p-0.5 cursor-pointer absolute right-0 z-20"
        onClick={() => setIsMenuOpened((old) => !old)}
      />
      <div
        className={classNames(
          !isMenuOpened && 'hidden',
          'absolute right-0 top-5 grid grid-cols-2 auto-rows-min z-10',
        )}
      >
        <div className="block col-span-2">
          <BlockMarginPopup onMarginSet={onMarginSet} margin={margin}>
            <AiOutlineSetting
              className="w-10 h-7 p-1 block bg-gray-300 hover:bg-gray-400 cursor-pointer"
              onClick={() => setIsMenuOpened(false)}
            />
          </BlockMarginPopup>
        </div>
        <AiOutlineArrowDown
          className={classNames(
            'w-5 h-10 p-0.5',
            isLastElement
              ? 'bg-gray-100'
              : 'bg-gray-300 hover:bg-gray-400 cursor-pointer',
          )}
          onClick={() => {
            if (!isLastElement) {
              setIsMenuOpened(false);
              onPositionChanged(currentOrder, 1);
            }
          }}
        />
        <AiOutlineArrowUp
          className={classNames(
            'w-5 h-10 p-0.5',
            isFirstElement
              ? 'bg-gray-100'
              : 'bg-gray-300 hover:bg-gray-400 cursor-pointer',
          )}
          onClick={() => {
            if (!isFirstElement) {
              setIsMenuOpened(false);
              onPositionChanged(currentOrder, -1);
            }
          }}
        />
        <AiOutlineStop
          className={classNames(
            'col-span-2 w-10 h-5 p-0.5 block',
            isProhibitDelete
              ? 'bg-red-100 text-gray-300'
              : 'bg-red-300 cursor-pointer',
          )}
          onClick={() => {
            if (!isProhibitDelete) {
              onDeleteAtIndex(elementIndex);
            }
          }}
        />
        <div
          className={classNames('w-5 h-5 p-0.5', !isMenuOpened && 'hidden')}
        />
      </div>
    </div>
  );
};

export default ContentEditorContainer;
