import React, {useCallback, useEffect, useRef, useState} from 'react';
import Viewer, {Camera, ClickEvent, SketchfabApi} from '@sketchfab/viewer-api';
import classNames from 'classnames';
import {
  SketchfabAnnotationDTO,
  SketchfabCustomAnnotationDTO,
} from '../../api/sketchfab/dto/SketchfabModelDTO';
import './SketchfabViewer.scoped.css';

export type Vector3 = [number, number, number];
export interface SketchfabCameraPosition {
  postion: Vector3;
  target: Vector3;
  duration: number;
}

export interface SketchfabViewerPorps
  extends React.HTMLAttributes<HTMLIFrameElement> {
  modelId: string;
  customAnnotations?: SketchfabCustomAnnotationDTO[];
  annotations?: SketchfabAnnotationDTO[];
  autostart?: boolean;
  hideAllAnnotations?: boolean;
  showOnlyAnnotations?: number[];

  selectedAnnotation?: number;
  gotoAnnotation?: number;
  onAnnotationClicked?: (index: number) => void;
  currentAnimationUID?: string;
  cycleMode?: 'loopOne' | 'loopAll' | 'one' | 'all';
  currentCameraPosition?: SketchfabCameraPosition;
  onModelClicked?: (cameraData: Camera, clickData: ClickEvent) => void;
  isShowBackground: boolean;
}

const SketchfabViewerEditorVersion: React.FC<SketchfabViewerPorps> = ({
  modelId,
  onAnnotationClicked,
  onModelClicked,
  currentAnimationUID,
  cycleMode = 'loopOne',
  customAnnotations = [],
  annotations = [],
  className,
  hideAllAnnotations = false,
  showOnlyAnnotations = [],
  autostart = false,
  selectedAnnotation = 0,
  currentCameraPosition,
  isShowBackground = true,
  gotoAnnotation,

  ...others
}) => {
  const [api, setApi] = useState<SketchfabApi>();
  const [isViewerReady, setIsViewerReady] = useState(false);
  const [annotationsCount, setAnnotationsCount] = useState(-1);
  const viewerIframeRef = useRef(null);

  useEffect(() => {
    if ((!gotoAnnotation && gotoAnnotation !== 0) || !isViewerReady || !api)
      return;

    api.gotoAnnotation(gotoAnnotation, {
      preventCameraAnimation: false,
      preventCameraMove: false,
    });
  }, [gotoAnnotation, isViewerReady]);

  useEffect(() => {
    if (!isViewerReady || !api) return;

    // скетчфаб добавляет кастомные аннотации, но сам не перезаписывает их между перерендерами. И вот получается, что триггернётся пару раз перерендр у этого вьювера и кастомных аннотаций из массива customAnnotations накопируется одинаковых целая куча. А т.к. они имеют одинаковые координаты, то накладываются друг на друга, визуально замечаешь только что у кастомных аннотаций резко увеличивается порядковый номер. Поэтому в каждом перерендере нужно очищать список всех кастомных аннотаций и заново их добавлять. Для этого ниже пробегаемся по списку всех аннотаций, это массив, который, кстати, можно получить с помощью метода getAnnotationList, но его дополнительно вычислять не обязательно, т.к. он хранится в самом скетчфабе и просто нужно вызывать removeAnnotation с нужным индексом. В нём хранится массив с простыми аннотациями и кастомными, они идут по порядку. Первые обычные аннотации мы не трогаем, они заканчиваются на номере annotationsCount. Всё, что дальше индекса равного annotationsCount - мы удаляем.

    if (annotationsCount !== -1) {
      // без этого условия все ломается
      for (
        let i = annotationsCount + customAnnotations.length + 1;
        i > annotationsCount;
        i -= 1
      ) {
        api.removeAnnotation(i - 1);
      }
    }
    // а потом добавляем вручную все кастомные аннотации из массива customAnnotations
    customAnnotations.forEach((element) => {
      api.createAnnotationFromWorldPosition(
        element.position3D,
        element.cameraPosition,
        element.cameraTarget,
        element.label,
        element.body,
      );
    });
    // в зависимости ставим customAnnotations.length, потому что если оставить просто customAnnotations, то useEffect не будет срабатывать при изменении этого массива. Реакт видит массив, даже если содержимое поменялось, массив то остался тот же и реакт не реагировал.
  }, [customAnnotations.length, isViewerReady, customAnnotations]);

  useEffect(() => {
    if (!isViewerReady || !api) return;

    annotations.forEach((annotation, index) => {
      api.updateAnnotation(index, {
        title: annotation.label,
        content: annotation.body || '',
      });
    });
  }, [annotations, isViewerReady, api]);

  useEffect(() => {
    if (!isViewerReady || !api || annotationsCount === -1) return;

    if (hideAllAnnotations) {
      for (let i = 0; i < annotationsCount; i += 1) {
        api.hideAnnotation(i);
        api.hideAnnotationTooltip(i);
      }
    } else {
      for (let i = 0; i < annotationsCount; i += 1) {
        if (!showOnlyAnnotations.includes(i)) {
          api.hideAnnotation(i);
          api.hideAnnotationTooltip(i);
        } else {
          api.showAnnotation(i);
          api.showAnnotationTooltip(i);
        }
      }
    }
  }, [
    hideAllAnnotations,
    annotationsCount,
    isViewerReady,
    showOnlyAnnotations,
  ]);

  useEffect(() => {
    if (!onAnnotationClicked || !isViewerReady || !api) return;

    api.addEventListener('annotationSelect', onAnnotationClicked);
  }, [isViewerReady]);

  useEffect(() => {
    if (!currentAnimationUID || !isViewerReady || !api) return;

    api.setCurrentAnimationByUID(currentAnimationUID);
  }, [isViewerReady, currentAnimationUID]);

  useEffect(() => {
    if (!currentCameraPosition || !isViewerReady || !api) return;

    api.setCameraLookAt(
      currentCameraPosition.postion,
      currentCameraPosition.target,
      currentCameraPosition.duration,
    );
  }, [isViewerReady, currentCameraPosition]);

  useEffect(() => {
    if (!onModelClicked || !isViewerReady || !api) return;

    const func = (info: ClickEvent) => {
      api.getCameraLookAt((err, camera) => {
        onModelClicked(camera, info);
      });
    };

    api.addEventListener('click', func);

    return () => {
      api.removeEventListener('click', func);
    };
  }, [isViewerReady, onModelClicked]);

  useEffect(() => {
    const client = new Viewer(viewerIframeRef.current);
    setIsViewerReady(false);

    client.init(modelId, {
      ui_infos: 0,
      ui_controls: 1,
      ui_watermark: 0,
      transparent: isShowBackground ? 0 : 1,
      annotation: selectedAnnotation,
      success: (data: SketchfabApi) => {
        if (autostart) {
          data.start();
        } else {
          data.stop();
        }

        data.addEventListener('viewerready', () => {
          data.setCycleMode(cycleMode);
          data.getAnnotationList((err, annotations) => {
            if (!err) setAnnotationsCount(annotations.length);
          });

          setApi(data);
          setIsViewerReady(true);
        });
      },
    });
  }, [isShowBackground, autostart, cycleMode, modelId, selectedAnnotation]);

  const getLookAt = useCallback(() => {
    api?.getCameraLookAt(function (err: any, camera: any) {
      window.console.log(camera.position); // [x, y, z]
      window.console.log(camera.target); // [x, y, z]
    });
  }, [api]);
  return (
    <>
      <iframe
        ref={viewerIframeRef}
        title="sketchfab-viewer"
        className={classNames('viewer-element', className)}
        {...others}
      />
      {/* <Button onClick={getLookAt}>Get</Button> */}
    </>
  );
};

export default SketchfabViewerEditorVersion;
