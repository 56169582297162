import React from 'react';
import {ViewerElementProps} from '../../types/EditorBlock';
import {EditorHeaderData, HeaderType} from './HeaderTypes';
import classNames from 'classnames';
import BasicAdjustmentsContainer from '../common/BasicAdjustmentsContainer';

const HeaderViewer: React.FC<ViewerElementProps<EditorHeaderData>> = (
  originBlock,
) => (
  <BasicAdjustmentsContainer data={originBlock.data}>
    <div
      className={classNames(
        originBlock.data.style === HeaderType.NORMAL && 'font-bold text-2xl',
        originBlock.data.style === HeaderType.COURSEHEADER &&
          'font-bold text-2xl md:text-6xl text-indigo-900 text-center my-12',
      )}
    >
      {originBlock.data.text}
    </div>
  </BasicAdjustmentsContainer>
);

export default HeaderViewer;
