export enum ElementAlignment {
  LEFT,
  RIGHT,
  CENTER,
}

export interface MarginAdjustments {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export interface DefaultStyleAdjustments {
  elementAlignment?: ElementAlignment;
  marginTop?: string;
  marginRight?: string;
  marginBottom?: string;
  marginLeft?: string;
  maxWidth?: string;
}

export const DefaultStyleAdjustmentsData: DefaultStyleAdjustments = {
  elementAlignment: ElementAlignment.CENTER,

  marginTop: '',
  marginRight: '',
  marginBottom: '',
  marginLeft: '',
};
