import classNames from 'classnames';
import React, {FocusEvent, useCallback, useEffect, useState} from 'react';
import {
  NotificationType,
  UniversalNotificationHandler,
} from '../../../../../services/globalNotification/universalNotificationHandler';
import {shuffleArray} from '../../../../../services/utils/shuffleArray';
import Button from '../../../../form/button/Button';
import {ViewerElementProps} from '../../../types/EditorBlock';
import BasicAdjustmentsContainer from '../../common/BasicAdjustmentsContainer';
import LoadingEditorBlock from '../../common/LoadingBlock';
import {DataTable, EditorTableAnswersData} from './TableAnswerTypes';

const TableAnswerViewer: React.FC<
  ViewerElementProps<EditorTableAnswersData>
> = (originBlock) => {
  const [localTable, setLocalTable] = useState<DataTable[][]>();

  const [correctTable, setCorrectTable] = useState<DataTable[][]>([]);

  const [isAnswerDone, setIsAnswerDone] = useState(false);

  useEffect(() => {
    setCorrectTable([...originBlock.data.table]);
    setLocalTable([
      ...originBlock.data.table.map((tr) => {
        return [...tr].map((td) => {
          if (td.type === 'dropDown') {
            return {...td, text: 'Select'};
          }
          if (td.type === 'edit') {
            return {...td, text: ''};
          }
          return {...td};
        });
      }),
    ]);

    shuffleArray(originBlock.data.listTerms);
  }, [originBlock]);

  useEffect(() => {
    setIsAnswerDone(false);
  }, [originBlock.data]);

  // ф-ия вызывается когда мы меняем текст в таблице
  const OnTableUpdated = useCallback(
    (e: FocusEvent<HTMLSpanElement>, indexTR: number, indexTD: number) => {
      if (localTable) {
        const newData = [...localTable];
        newData[indexTR][indexTD].text = e.target.innerText;
        setLocalTable(newData);
      }
    },
    [localTable],
  );

  // ф-ия вызывается когда мы меняем значение в выкидном списке
  const changeSelect = useCallback(
    (
      e: React.ChangeEvent<HTMLSelectElement>,
      indexTR: number,
      indexTD: number,
    ) => {
      if (localTable) {
        const newData = [...localTable];
        newData[indexTR][indexTD].text = e.target.value;
        setLocalTable(newData);
      }
    },
    [localTable],
  );

  // ф-ия возвращает true если есть хоть один неотвеченный (пустой) вопрос, если на все даны ответы, то false
  const isEmptyAnswers = useCallback(() => {
    if (localTable) {
      return !!localTable
        .map((tr) => {
          return tr.filter((td) => {
            return td.text.trim() === '' || td.text === 'Select';
          });
        })
        .filter((arr) => {
          return arr.length !== 0;
        }).length;
    }
  }, [localTable]);

  // ф-ия проверяет соответствуют ли друг другу поля 'dropDown' и 'edit' в массива localTable и correctTable, если ошибок нет, то отправить false
  const isCorrectTable = useCallback(() => {
    if (localTable) {
      let isError = false;
      const local = localTable
        .map((tr) => {
          return tr.filter((td) => {
            return td.type === 'dropDown' || td.type === 'edit';
          });
        })
        .filter((arr) => {
          return arr.length !== 0;
        })
        .flat();
      const correct = correctTable
        .map((tr) => {
          return tr.filter((td) => {
            return td.type === 'dropDown' || td.type === 'edit';
          });
        })
        .filter((arr) => {
          return arr.length !== 0;
        })
        .flat();

      local.forEach((answer, index) => {
        if (
          answer.text.toLowerCase().trim() !==
          correct[index].text.toLowerCase().trim()
        ) {
          isError = true;
        }
      });
      return isError;
    }
  }, [correctTable, localTable]);

  // ф-ия срабатывает при отправке ответа
  const onSendAnswer = useCallback(() => {
    if (isEmptyAnswers()) {
      UniversalNotificationHandler(
        'Canceled',
        'Some options are missing',
        NotificationType.danger,
      );
      return;
    }

    setIsAnswerDone(true);

    UniversalNotificationHandler(
      !isCorrectTable() ? 'Correct' : 'Wrong',
      !isCorrectTable() ? 'Table solved correctly' : 'Table solved incorrectly',
      !isCorrectTable() ? NotificationType.success : NotificationType.danger,
    );
  }, [isCorrectTable, isEmptyAnswers]);

  if (!localTable) return <LoadingEditorBlock />;
  return (
    <BasicAdjustmentsContainer data={originBlock.data} disableAutoBlockControl>
      <div className="text-2xl p-2 sm:p-5 w-full">
        <table className="w-full border text-center">
          <tbody>
            {localTable?.map((tr, indexTr) => {
              return (
                <tr
                  key={indexTr}
                  className={classNames(
                    'border text-base',
                    indexTr === 0 && 'font-bold bg-gray-50',
                  )}
                >
                  {tr.map((td, indexTd) => {
                    return (
                      <td
                        key={td.id}
                        className={classNames(
                          'border',
                          indexTd === 0 && 'py-1',
                        )}
                      >
                        <div
                          className={classNames(
                            indexTr > 0 &&
                              'flex justify-between items-center gap-1',
                          )}
                        >
                          {td.type === 'dropDown' ? (
                            <select
                              name="select"
                              id="select"
                              className={classNames(
                                'py-1 bg-transparent px-2 text-center border hover:bg-blue-100 w-full',
                                td.text === 'Select' && 'bg-red-50',
                                isAnswerDone &&
                                  td.text ===
                                    correctTable[indexTr][indexTd].text &&
                                  'bg-green-50',
                                isAnswerDone &&
                                  td.text !==
                                    correctTable[indexTr][indexTd].text &&
                                  'bg-red-50',
                              )}
                              onChange={(e) =>
                                changeSelect(e, indexTr, indexTd)
                              }
                              value={localTable[indexTr][indexTd].text}
                              disabled={isAnswerDone}
                            >
                              {[
                                {term: 'Select', id: 'NotAnswer'},
                                ...originBlock.data.listTerms,
                              ].map((list) => (
                                <option
                                  value={list.term}
                                  key={list.id}
                                  className="w-fit"
                                >
                                  {list.term}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <span
                              className={classNames(
                                'px-2 py-1 w-full',
                                td.type === 'edit' &&
                                  'border border-solid border-blue-400',
                                td.type === 'edit' &&
                                  td.text.trim() === '' &&
                                  'bg-red-50',
                                isAnswerDone &&
                                  td.type === 'edit' &&
                                  td.text.toLowerCase().trim() ===
                                    correctTable[indexTr][indexTd].text
                                      .toLowerCase()
                                      .trim() &&
                                  'bg-green-50',
                                isAnswerDone &&
                                  td.type === 'edit' &&
                                  td.text.toLowerCase().trim() !==
                                    correctTable[indexTr][indexTd].text
                                      .toLowerCase()
                                      .trim() &&
                                  'bg-red-50',
                              )}
                              onBlur={(e) =>
                                OnTableUpdated(e, indexTr, indexTd)
                              }
                              contentEditable={
                                td.type === 'edit' && !isAnswerDone
                              }
                              suppressContentEditableWarning
                              placeholder="Enter your answer"
                            >
                              {td.text}
                            </span>
                          )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="text-center mt-4">
          {!isAnswerDone ? (
            <Button
              onClick={onSendAnswer}
              disabled={isEmptyAnswers() || isAnswerDone}
            >
              Answer
            </Button>
          ) : !isCorrectTable() ? (
            <Button className="bg-green-600">Correct</Button>
          ) : (
            <Button className="bg-red-600">Wrong</Button>
          )}
        </div>
      </div>
    </BasicAdjustmentsContainer>
  );
};

export default TableAnswerViewer;
