import React from 'react';
import {Link} from 'react-router-dom';
import paymentinfo from './logo_payment.png';
import StandartHeader from '../../components/repeatingMarkup/StandartHeader';
import StandartParagraph from '../../components/repeatingMarkup/StandartParagraph';
import './PaymentinfoPage.scoped.css';

export interface PaymentinfoPageProps {}

const PaymentinfoPage: React.FC<PaymentinfoPageProps> = () => {
  return (
    <section className="mb-5 mt-2 mx-auto p-3 max-w-screen-xl">
      <h2 className="text-center mx-auto mb-6 text-4xl font-medium">
        Описание процесса оплаты
      </h2>
      <StandartHeader>Контакты:</StandartHeader>
      <StandartParagraph>
        Фактический адрес: 121205, г.Москва Большой Бульвар д. 30 стр 1 эт 2 п
        225, рм 225-9 Электронная почта: online.mycfo@gmail.com Телефоны:
        8-909-696-88-98
      </StandartParagraph>
      <StandartHeader>Реквизиты:</StandartHeader>
      <StandartParagraph>
        ООО "XreadyLab", ИНН 9731053156 / ОГРН 1197746602822. Юридический адрес:
        121205, г.Москва Большой Бульвар д. 30 стр 1 эт 2 п 225, рм 225-9 Тел.:
        +7 (909) 696-88-98
      </StandartParagraph>
      <StandartHeader>Оплата банковской картой</StandartHeader>
      <StandartParagraph>
        Для выбора оплаты товара с помощью банковской карты на соответствующей
        странице необходимо нажать кнопку Оплатить курс. Оплата происходит через
        ПАО СБЕРБАНК с использованием банковских карт следующих платёжных
        систем:
      </StandartParagraph>
      <ul className="pl-4 mb-4 list-disc text-base">
        <li>VISA International</li>
        <li>Mastercard Worldwide</li>
        <li>МИР</li>
      </ul>
      <StandartParagraph>
        Возврат переведённых средств, производится на ваш банковский счёт в
        течение 5-30 рабочих дней (срок зависит от банка, который выдал вашу
        банковскую карту).
      </StandartParagraph>
      <StandartHeader>
        Описание процесса передачи данных/информация о SSL-шифровании
      </StandartHeader>
      <StandartParagraph>
        Для оплаты покупки Вы будете перенаправлены на платежный шлюз ПАО
        &quot;Сбербанк России&quot; для ввода реквизитов Вашей карты.
        Пожалуйста, приготовьте Вашу пластиковую карту заранее. Соединение с
        платежным шлюзом и передача информации осуществляется в защищенном
        режиме с использованием протокола шифрования SSL.
      </StandartParagraph>
      <StandartParagraph>
        В случае если Ваш банк поддерживает технологию безопасного проведения
        интернет-платежей Verified By Visa или MasterCard Secure Code для
        проведения платежа также может потребоваться ввод специального пароля.
        Способы и возможность получения паролей для совершения интернет-платежей
        Вы можете уточнить в банке, выпустившем карту.
      </StandartParagraph>
      <StandartParagraph>
        Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность
        сообщаемой персональной информации обеспечивается ПАО &quot;Сбербанк
        России&quot;. Введенная информация не будет предоставлена третьим лицам
        за исключением случаев, предусмотренных законодательством РФ. Проведение
        платежей по банковским картам осуществляется в строгом соответствии с
        требованиями платежных систем Visa Int. и MasterCard Europe Sprl.
      </StandartParagraph>
      <Link
        to="/policy"
        className="text-blue-500 hover:underline hover:text-blue-800 text-lg"
      >
        Политика в отношении обработки персональных данных
      </Link>
      <img
        src={paymentinfo}
        alt="Payment info"
        className="mx-auto w-full paymentinfoImage"
      />
    </section>
  );
};

export default PaymentinfoPage;
