import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {AiOutlineRollback} from 'react-icons/ai';
import {Link, useNavigate} from 'react-router-dom';
import {useGetAllCustomPages} from '../../../api/customPage/CustomPage';
import {useGetAllLanguages} from '../../../api/localization/Localization';
import AdminCustomPageCard from '../../../components/admin/adminCustomPage/adminCustomPageCard/AdminCustomPageCard';
import LoadingEditorBlock from '../../../components/editor/elements/common/LoadingBlock';
import FormButton from '../../../components/form/button/FormButton';
import Input from '../../../components/form/input/Input';
import './AdminCustomPages.scoped.css';

interface CustomPagesfilter {
  search: string;
}

const AdminCustomPages: React.FC = () => {
  const {
    register,
    getValues,
    formState: {errors},
  } = useForm<CustomPagesfilter>();

  const [searchText, setSearchText] = useState('');

  const [currentLanguageId, setCurrentLanguageId] = useState<string>();

  const languages = useGetAllLanguages({
    onSuccess: (data) => {
      setCurrentLanguageId(data[0].id);
    },
  });

  const allCustomPages = useGetAllCustomPages(
    currentLanguageId
      ? languages.data?.filter((lang) => lang.id === currentLanguageId)[0]
          .languageCode || 'en'
      : 'en',
  );

  const navigate = useNavigate();

  return (
    <>
      <section>
        <h1 className="my-10 font-bold text-2xl sm:text-4xl lg:text-6xl text-center uppercase text-indigo-900">
          Custom pages
        </h1>
        {languages.isSuccess ? (
          <div className="flex gap-2 mb-2">
            <Link to="/admin" className="bg-white w-fit">
              <FormButton>
                <div className="flex justify-between items-center gap-2">
                  <AiOutlineRollback /> Back
                </div>
              </FormButton>
            </Link>
            <div className="flex gap-2">
              {languages.data.map((el) => (
                <div className="bg-white" key={el.id}>
                  <FormButton
                    onClick={() => setCurrentLanguageId(el.id)}
                    selected={el.id == currentLanguageId}
                  >
                    {el.name}
                  </FormButton>
                </div>
              ))}
            </div>
            <Input<CustomPagesfilter>
              name="search"
              label="Search"
              className="flex-grow"
              onChange={() => setSearchText(getValues('search'))}
              registration={{register, errors}}
            />
            <FormButton
              className="flex-1 hover:text-black hover:font-semibold"
              highlight
              onClick={() => navigate('/admin/custom_pages/add')}
            >
              Add new Custom Page
            </FormButton>
          </div>
        ) : (
          <LoadingEditorBlock />
        )}
      </section>

      {!allCustomPages.isLoading ? (
        <section className="grid grid-cols-1 lg:grid-cols-2 max-w-screen-sm lg:max-w-screen-xl mx-auto gap-8 my-6">
          {allCustomPages.data &&
            allCustomPages.data.map((element) => (
              <AdminCustomPageCard
                data={element}
                key={element.id}
                search={searchText}
              />
            ))}
        </section>
      ) : (
        <LoadingEditorBlock />
      )}
    </>
  );
};

export default AdminCustomPages;
