import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import FormButton from '../../../../form/button/FormButton';
import Input from '../../../../form/input/Input';
import ListBox, {ListBoxOption} from '../../../../form/listbox/Listbox';
import Toggle from '../../../../form/switch/Toggle';
import BasicAdjustmentsEditor from '../../../common/BasicAdjustmentsEditor';
import HiddenSettings from '../../../common/HiddenSettings';
import {EditorElementProps} from '../../../types/EditorBlock';
import {useUpdateEditorBlock} from '../../../types/UseEditor';
import {AbstractFieldAnswerData} from './AbstractFieldAnswerTypes';

const TypeOption: ListBoxOption<string>[] = [
  {label: 'Number', value: 'number'},
  {label: 'Text', value: 'text'},
];

const AbstractFieldAnswerEditorBlock: React.FC<
  EditorElementProps<AbstractFieldAnswerData>
> = (originBlock) => {
  const setBlockMutation = useUpdateEditorBlock<AbstractFieldAnswerData>();

  const {
    register,
    getValues,
    setValue,
    watch,
    formState: {errors},
  } = useForm<AbstractFieldAnswerData>({
    defaultValues: {...originBlock.data},
  });

  useEffect(() => {
    const subscription = watch((updatedData) => {
      setBlockMutation.mutate({
        ...originBlock,
        data: {
          ...originBlock.data,
          ...(updatedData as AbstractFieldAnswerData),
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [originBlock, setBlockMutation, watch]);

  return (
    <div className="p-2 sm:p-5">
      <HiddenSettings>
        <div className="grid gap-2">
          <BasicAdjustmentsEditor
            getValues={getValues}
            setValue={setValue}
            registration={{register, errors}}
          />
          <FormButton
            onClick={() => {
              setBlockMutation.mutate({
                ...originBlock,
              });
            }}
          >
            Refresh live view
          </FormButton>
        </div>
      </HiddenSettings>

      <div className="p-2">
        <Input
          name="question.question"
          label="Question"
          className="w-full"
          registration={{register, errors}}
          type="text"
          options={{
            required: 'Required',
          }}
        />
        <div className="flex gap-2 mt-2">
          <Input
            name="correctAnswer.answer"
            label="Answer"
            className="flex-grow"
            registration={{register, errors}}
            type={originBlock.data.correctAnswer.type}
            options={{
              required: 'Required',
              valueAsNumber: originBlock.data.correctAnswer.type === 'number',
            }}
          />
          <ListBox
            name="correctAnswer.type"
            label="Type"
            className="w-32"
            options={TypeOption}
            setValue={setValue}
            getValues={getValues}
          />
          <Toggle
            className="mt-2"
            name="isFullWidthAnswer"
            label="Full length"
            setValue={setValue}
            getValues={getValues}
          />
        </div>
      </div>
    </div>
  );
};

export default AbstractFieldAnswerEditorBlock;
