import classNames from 'classnames';
import {useState} from 'react';
import {AiOutlineRollback} from 'react-icons/ai';
import {Link, useParams} from 'react-router-dom';
import {useGetFullCustomPageById} from '../../../api/customPage/CustomPage';
import {useGetAllLanguages} from '../../../api/localization/Localization';
import NarrowContent from '../../../components/commonStructure/NarrowContent';
import LoadingEditorBlock from '../../../components/editor/elements/common/LoadingBlock';
import ViewerAutoblock from '../../../components/editor/service/autoBlocks/ViewerAutoblock';
import FormButton from '../../../components/form/button/FormButton';

const CustomPageAdminPreview: React.FC = () => {
  const {customPageId} = useParams<{customPageId: string}>();

  const [currentLanguageId, setCurrentLanguageId] = useState<string>();

  const customPage = useGetFullCustomPageById(customPageId || '');

  const languages = useGetAllLanguages({
    onSuccess: (data) => {
      setCurrentLanguageId(data[0].id);
    },
  });

  if (!languages.isSuccess || !customPage.isSuccess)
    return <LoadingEditorBlock />;
  return (
    <>
      <div className="absolute top-20 left-5 z-40 flex flex-col gap-2">
        <div className="flex gap-2">
          <Link to="/admin/custom_pages" className="bg-white w-fit">
            <FormButton>
              <div className="flex justify-between items-center gap-2">
                <AiOutlineRollback /> Back
              </div>
            </FormButton>
          </Link>
          {languages.data.map((el) => (
            <div className="bg-white" key={el.id}>
              <FormButton
                onClick={() => setCurrentLanguageId(el.id)}
                selected={el.id == currentLanguageId}
              >
                {el.name}
              </FormButton>
            </div>
          ))}
        </div>
      </div>
      <NarrowContent className="py-7">
        {customPage.data.translations.map((el, index) => (
          <div
            className={classNames(
              el.languageId !== currentLanguageId && 'hidden',
            )}
            key={index}
          >
            <ViewerAutoblock blocks={[JSON.parse(el.block.rawData)]} />
          </div>
        ))}
      </NarrowContent>
    </>
  );
};

export default CustomPageAdminPreview;
