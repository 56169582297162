import {EditorBlockData} from '../../types/EditorBlock';
import {DefaultStyleAdjustments} from '../common/CommonTypes';

export enum ButtonEditorStyleType {
  FILLED,
  OUTLINE,
}

export interface EditorButtonData
  extends EditorBlockData,
    DefaultStyleAdjustments {
  label: string;
  href: string;
  openInNewTab: boolean;
  buttonStyle: ButtonEditorStyleType;
}
