import classNames from 'classnames';
import React from 'react';

interface ReviewVideoProps extends React.HTMLAttributes<HTMLDivElement> {
  video?: string;
  header: string;
  subheader: React.ReactNode;
  text?: string;
}

const ReviewVideo: React.FC<ReviewVideoProps> = ({
  video,
  header,
  subheader,
  text,
  className,
  children,
  ...others
}) => {
  return (
    <div
      className={classNames(
        className,
        'w-full h-full flex flex-col lg:flex-row justify-start lg:justify-between',
      )}
      {...others}
    >
      <div className="relative simulation-video w-full lg:w-1/2 h-auto">
        <iframe
          src={video}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          className="w-full h-full absolute"
        />
      </div>

      <div className={classNames('pl-0 lg:pl-4', !video && 'w-full')}>
        <div className="mt-3 font-bold text-lg lg:text-3xl uppercase text-indigo-900">
          {header}
        </div>
        <div className="text-xs lg:text-sm text-black font-light lg:font-normal my-2">
          {subheader}
        </div>
        {text && (
          <div className="mt-3 lg:mt-6 text-xs md:text-sm lg:text-xl font-light lg:font-normal text-black">
            <div className="mt-4 text-lg">{text}</div>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default ReviewVideo;
