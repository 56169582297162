import {useCheckTokenOnRequest} from '../../api/authorization/Authentification';
import Button from '../../components/form/button/Button';
import {useAuth} from '../../services/auth/AuthService';
import {UniversalNotificationHandler} from '../../services/globalNotification/universalNotificationHandler';

const AuthTest: React.FC = () => {
  const auth = useAuth();
  const checkToken = useCheckTokenOnRequest({
    onSuccess: () => {
      UniversalNotificationHandler('OK!');
    },
    onError: () => {},
  });

  return (
    <div>
      <Button onClick={() => auth.logout()}>Logout</Button>
      <Button
        onClick={() => {
          checkToken.mutate();
        }}
      >
        Check token
      </Button>
      <input />
    </div>
  );
};

export default AuthTest;
