import {EditorBlockData} from '../../types/EditorBlock';
import {DefaultStyleAdjustments} from '../common/CommonTypes';

export enum ImageType {
  Format169 = '16:9',
  Format43 = '4:3',
  FormatFull = 'Full size',
  OriginalSize = 'Original size',
}

export interface EditorImageData
  extends EditorBlockData,
    DefaultStyleAdjustments {
  imageId: string;
  quality: ImageQuality;
  sizeType: ImageType;
  imageFit: ImageFit;
}

export enum ImageQuality {
  HIGH,
  MID,
  LOW,
}

export enum ImageFit {
  Contain = 'Contain',
  Cover = 'Cover',
  Fill = 'Fill',
  ScaleDown = 'Scale down',
  None = 'None',
}
