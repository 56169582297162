/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import React, {useCallback, useEffect, useState} from 'react';
import parse from 'html-react-parser';
import {EditorTextAnswersData} from './TextAnswerTypes';
import './TextAnswerViewer.scoped.css';
import {ViewerElementProps} from '../../../types/EditorBlock';
import {
  NotificationType,
  UniversalNotificationHandler,
} from '../../../../../services/globalNotification/universalNotificationHandler';
import LoadingEditorBlock from '../../common/LoadingBlock';
import Button from '../../../../form/button/Button';

const TextAnswerViewer: React.FC<ViewerElementProps<EditorTextAnswersData>> = (
  originBlock,
) => {
  const [isAnswerDone, setIsAnswerDone] = useState(false);

  useEffect(() => {
    setIsAnswerDone(false);
    const selects: any = document.querySelectorAll(
      `#${originBlock.id.split('-').join('')} .selectNodes`,
    );
    const divs: any = document.querySelectorAll(
      `#${originBlock.id.split('-').join('')} .divEditNodes`,
    );
    originBlock.data.correctAnswers.selects.forEach((select, index) => {
      selects[index].disabled = false;
      selects[index].className =
        'selectNodes bg-white text-center border-2 border-blue-400 hover:bg-blue-100 m-1';
    });
    originBlock.data.correctAnswers.divs.forEach((div, index) => {
      divs[index].contentEditable = 'true';
      divs[index].className =
        'divEditNodes w-fit font-montserrat text-lg mb-1 border border-solid border-blue-400 inline-block py-1 px-2';
    });
  }, [originBlock.data]);

  // возвращает select в виде строки
  const getSelectNodeString = useCallback(
    (left?: string, right?: string) => {
      const leftText = left || '';
      const rightText = right || '';
      const options = [
        {term: 'Select', id: 'NotAnswer'},
        ...originBlock.data.listTerms,
      ]
        .map((term) => `<option value=${term.id}>${term.term}</option>`)
        .join(' ');
      const select = `<select className="selectNodes bg-white text-center border-2 border-blue-400 hover:bg-blue-100 m-1">${options}</select>`;
      return `${leftText}${select}${rightText}`;
    },
    [originBlock.data.listTerms],
  );

  // возвращает div(editable) в виде строки
  const getEditableDivNodeString = useCallback(
    (left?: string, right?: string) => {
      const leftText = left || '';
      const rightText = right || '';
      const div = `<div className="divEditNodes w-fit font-montserrat text-lg mb-1 border border-solid border-blue-400 inline-block py-1 px-2" suppressContentEditableWarning contentEditable>Answer</div>`;

      return `${leftText}${div}${rightText}`;
    },
    [],
  );

  // ф-ия возвращает true если есть хоть один неотвеченный (пустой) вопрос, если на все даны ответы, то false
  const isEmptyAnswers = useCallback(() => {
    let isEmpty = false;
    const arrSelect = Array.from(
      document.querySelectorAll(
        `#${originBlock.id.split('-').join('')} .selectNodes`,
      ),
    ).map((node: any) => node.value);
    const arrDiv = Array.from(
      document.querySelectorAll(
        `#${originBlock.id.split('-').join('')} .divEditNodes`,
      ),
    ).map((node: any) => node.innerText);
    arrSelect.forEach((select) => {
      if (select.trim() === '' || select.trim() === 'Select') {
        isEmpty = true;
      }
    });
    arrDiv.forEach((div) => {
      if (div.trim() === '' || div.trim() === '\n') {
        isEmpty = true;
      }
    });
    return isEmpty;
  }, []);

  // проверяем правильность ответов
  const isCorrectAnswers = useCallback(() => {
    let isError = false;
    const arrSelect = Array.from(
      document.querySelectorAll(
        `#${originBlock.id.split('-').join('')} .selectNodes`,
      ),
    ).map((node: any) => node.value);
    const arrDiv = Array.from(
      document.querySelectorAll(
        `#${originBlock.id.split('-').join('')} .divEditNodes`,
      ),
    ).map((node: any) => node.innerText);
    originBlock.data.correctAnswers.selects.forEach((select, index) => {
      if (
        select.trim().toLowerCase() !== arrSelect[index].trim().toLowerCase()
      ) {
        isError = true;
      }
    });
    originBlock.data.correctAnswers.divs.forEach((div, index) => {
      if (div.trim().toLowerCase() !== arrDiv[index].trim().toLowerCase()) {
        isError = true;
      }
    });
    return isError;
  }, [originBlock.data.correctAnswers]);

  // ф-ия срабатывает при отправке ответа
  const onSendAnswer = useCallback(() => {
    if (isEmptyAnswers()) {
      UniversalNotificationHandler(
        'Canceled',
        'Some options are missing',
        NotificationType.danger,
      );
      return;
    }
    setIsAnswerDone(true);
    const isError = isCorrectAnswers();
    // selects div сразу сохраняем, чтобы потом по ним пробежаться и поменять стили
    const selects: any = document.querySelectorAll(
      `#${originBlock.id.split('-').join('')} .selectNodes`,
    );
    const divs: any = document.querySelectorAll(
      `#${originBlock.id.split('-').join('')} .divEditNodes`,
    );
    if (isError) {
      const arrSelect = Array.from(selects).map((node: any) => node.value);
      const arrDiv = Array.from(divs).map((node: any) => node.innerText);
      originBlock.data.correctAnswers.selects.forEach((select, index) => {
        if (
          select.trim().toLowerCase() !== arrSelect[index].trim().toLowerCase()
        ) {
          selects[index].className += ' bg-red-100';
        } else {
          selects[index].className += ' bg-green-100';
        }
      });
      originBlock.data.correctAnswers.divs.forEach((div, index) => {
        if (div.trim().toLowerCase() !== arrDiv[index].trim().toLowerCase()) {
          divs[index].className += ' bg-red-100';
        } else {
          divs[index].className += ' bg-green-100';
        }
      });
    } else {
      originBlock.data.correctAnswers.selects.forEach((select, index) => {
        selects[index].className += ' bg-green-100';
      });
      originBlock.data.correctAnswers.divs.forEach((div, index) => {
        divs[index].className += ' bg-green-100';
      });
    }
    // потом делаем выкидные списки и поля для ввода неактивными
    originBlock.data.correctAnswers.selects.forEach((select, index) => {
      selects[index].disabled = true;
    });
    originBlock.data.correctAnswers.divs.forEach((div, index) => {
      divs[index].contentEditable = 'false';
    });
    UniversalNotificationHandler(
      !isError ? 'Correct' : 'Wrong',
      !isError
        ? 'Questions answered correctly'
        : 'Not all questions are answered correctly',
      !isError ? NotificationType.success : NotificationType.danger,
    );
  }, [originBlock.data.correctAnswers]);

  if (!originBlock) return <LoadingEditorBlock />;
  return (
    <div className="text-2xl p-2 sm:p-5 w-full max-w-7xl mx-auto">
      <div
        id={originBlock.id.split('-').join('')}
        className="w-fit px-2 py-1 mb-1 font-montserrat text-lg"
      >
        {parse(
          originBlock.data.content
            .split(' ')
            .map((text) => {
              // Далее проверка на любые другие символы вокруг ('_DD_')
              if (text.includes('_DD_')) {
                const leftRight = text.split('_DD_');
                if (leftRight[0].length > 0 && leftRight[1].length > 0)
                  return getSelectNodeString(leftRight[0], leftRight[1]);
                if (leftRight[0].length > 0 && leftRight[1].length === 0)
                  return getSelectNodeString(leftRight[0], '');
                if (leftRight[0].length === 0 && leftRight[1].length > 0)
                  return getSelectNodeString('', leftRight[1]);
                return getSelectNodeString();
              }
              // Далее проверка на любые другие символы вокруг ('_ED_')
              if (text.includes('_ED_')) {
                const leftRight = text.split('_ED_');
                if (leftRight[0].length > 0 && leftRight[1].length > 0)
                  return getEditableDivNodeString(leftRight[0], leftRight[1]);
                if (leftRight[0].length > 0 && leftRight[1].length === 0)
                  return getEditableDivNodeString(leftRight[0], '');
                if (leftRight[0].length === 0 && leftRight[1].length > 0)
                  return getEditableDivNodeString('', leftRight[1]);
                return getEditableDivNodeString();
              }
              return text;
            })
            .join(' '),
        )}
      </div>
      <div className="text-center mt-4">
        {!isAnswerDone ? (
          <Button onClick={onSendAnswer} disabled={isAnswerDone}>
            Answer
          </Button>
        ) : !isCorrectAnswers() ? (
          <Button className="bg-green-600">Correct</Button>
        ) : (
          <Button className="bg-red-600">Wrong</Button>
        )}
      </div>
    </div>
  );
};

export default TextAnswerViewer;
